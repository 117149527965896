/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import noimage from "../../common/images/no-img-product.png";
import Footer from "../Layout/Footer";
import { setMinutes, setHours, format } from "date-fns";

import {
  stripslashes,
  addressFormat,
  getCalculatedAmount,
  callImage,
  showLoader,
  hideLoader,
  showAlert,
  timeToConv12,
  tConvert 
} from "../Helpers/SettingHelper";

import {
DESTROY_BOOKING_CART_DETAIL,
GET_BOOKING_ORDER_DETAIL
} from "../../actions";
import { appId, deliveryId, CountryTxt, apiUrlNotify, smsCharge } from "../Helpers/Config";
import thnkyou_tick_img from "../../common/images/tick.png";
import productImg from "../../common/images/noimg-400x400.jpg";
import scootyImg from "../../common/images/scooty.png";
import cartMpImg from "../../common/images/cart-map.png";


var Parser = require("html-react-parser");

class Bookingthankyou extends Component {
  constructor(props) {
    super(props);
    cookie.save("orderTabs", 'bookings', { path: "/" }); 
  }

  componentWillMount() {
    let orderId =
      typeof this.props.match.params.orderId !== "undefined"
        ? this.props.match.params.orderId
        : "";
    var chkOrderid =
      typeof cookie.load("ChkOrderid") === "undefined"
        ? ""
        : cookie.load("ChkOrderid");

    if (orderId !== "" && chkOrderid === orderId) {
      this.props.getBookingOrderDetail(orderId);
      this.sendNotification();
    } else {
      showAlert("Error", "Invalid order detail.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/");
    }
  }

  componentDidMount() {
    this.props.destroyBookingCartDetail();
  }

  sendNotification() {
    axios.get(apiUrlNotify + "order_notify").then((res) => {});
  }
  
  getSlotdatetimeInfo(product) {
		var dateObj = new Date(product.item_slot_date);
		var dateStr = format(dateObj, "dd/MM/yyyy");
		var strTime = timeToConv12(product.item_slot_start_time);
		var endTime = timeToConv12(product.item_slot_end_time);
		if(product.item_booking_slottype === '1') {
			var slotdateTimestr = dateStr+' '+strTime;
		} else {
			var slotdateTimestr = dateStr+' '+strTime+' - '+endTime;
		}
		return slotdateTimestr;
  }

  loadItems(orderDetail) {
    if (Object.keys(orderDetail).length > 0) {
      var remarks =
        orderDetail.order_remarks !== "" ? (
          <div className="remark_notesec text-left">
            <h4>Remarks</h4>
            <p>{orderDetail.order_remarks}</p>
          </div>
        ) : (
          ""
        );
      var cartItems = orderDetail["items"];
      console.log(cartItems)
      return cartItems.map((item, index) => (
        <div key={index}>
          <div className="cart_row product-details">
            <div className="col-sm-cls cart_left">
            {/*<div className="cart_img">
                <img src={noimage} alt="" />
			</div>*/}
              <div className="cart_info">
                <h4>
                  {stripslashes(item.item_name)} X {item.item_qty}
                </h4>
                <span>{this.getSlotdatetimeInfo(item)}</span>
                {item.item_specification !== "" && (
                  <p className="help-block">
                    {stripslashes(item.item_specification)}
                  </p>
                )}
              </div>
            </div>
            <div className="col-sm-cls cart_right text-right">
              <div className="cart_price">
                <p>${parseFloat(item.item_total_amount).toFixed(2)}</p>
				<br></br>
				<span>{item.item_category_type == "hour_basis"
					  ? " Hour Basis" 
					  : " Catch Basis"}</span>
				{item.item_category_type == "catch_basis" && (
				  <span>({item.item_catch_count} {item.item_catch_count>1?'catches':'catch'})</span>
				 )}
				 {item.item_category_type == "hour_basis" && item.item_additional_hours > 0 && (
				  <span>(+ {item.item_additional_hours} {item.item_additional_hours>1?' hours':' hour'})</span>
				 )}   
              </div>
            </div>
          </div>
          {cartItems.length===index+1 && remarks} 
        </div>
      ));
    } else {
    }
  }


  render() {
    let orderArr = this.props.orderdetail;
    var orderDetail = [];

    if (Object.keys(orderArr).length > 0) {
      if (orderArr[0].status === "ok") {
        orderDetail = orderArr[0].result_set[0];
      }
    }

    return (
      <div className="thankyou-main-div">
        {/* Header start */}
        <Header />
        {/* Header End */}

        <div className="common-inner-blckdiv">
        </div>

        {/* container - start */}
        <div className="container">
          {/* innersection_wrap - start */}
          {Object.keys(orderDetail).length > 0 && (
            <div className="innersection_wrap tnk-you">
              <div className="mainacc_toptext tick">
                <img src={thnkyou_tick_img} alt="" />
                <h2>Thank You</h2>
                <p>Your order has been placed successfully</p>
              </div>

              {/* order-detail-maindiv - start */}
              <div className="thank-order-detaildiv">
                <div className="tnk-detail text-center">
                  <h2>YOUR BOOKING DETAILS</h2>
                  <div className="tnk-order">
                    <h3>Booking No - {orderDetail.order_local_no}</h3>
                    <p>
                      Booking placed at :{" "}
                      {Moment(orderDetail.order_created_on).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                      <br></br>Pay by : {orderDetail.order_method_name}
                    </p>
                    <p>Kindly collect your pass at Mini Mart before your fishing experience</p>
                  </div>
                </div>

                <div className="tnk-delivery">


                  {/*<div className="delivery_total delivery_total_number delivery_datetime_div">
                    <div className="delivery_total_left">
                      <h2>
                        Booking Date
                      </h2>
                      <h4 className="checkoutDate">
                        {Moment(orderDetail.order_date).format("DD-MM-YYYY")}
                      </h4>
                    </div>
                    <div className="delivery_total_left delivery_total_right">
                      <h2>
                        Booking time
                      </h2>
                      <h4 className="checkoutTime">
                        {tConvert(orderDetail.items[0].item_slot_start_time)} - {tConvert(orderDetail.items[0].item_slot_end_time)}
                      </h4>
                    </div>
				   </div>*/}
                  {/*<div className="delivery_total delivery_total_number delivery_datetime_div">
                    <div className="delivery_total_left">
                      <h2>
                        Booking Type
                      </h2>
                      <h4 className="checkoutDate">
                        {orderDetail.items[0].item_category_type == "hour_basis"
                            ? " Hour Basis" 
                            : " Catch Basis"}
                      </h4>
                    </div>
                    {orderDetail.items[0].item_category_type == "catch_basis" && (
                    <div className="delivery_total_left delivery_total_right">
                      <h2>
                        Catch Count
                      </h2>
                      <h4 className="checkoutTime">
                        {orderDetail.items[0].item_catch_count} {orderDetail.items[0].item_catch_count>1?'catches':'catch'}
                      </h4>
                    </div>
                    )}

                     {orderDetail.items[0].item_category_type == "hour_basis" && orderDetail.items[0].item_additional_hours > 0 && (
                     <div className="delivery_total_left delivery_total_right">
                      <h2>
                        Additional Hours
                      </h2>
                      <h4 className="checkoutTime">
                        (+ {orderDetail.items[0].item_additional_hours} {orderDetail.items[0].item_additional_hours>1?' hours':' hour'})
                      </h4>
                    </div>
                     )} 
                  </div>*/}

                  <div className="orderitem_body_div">
                    <div className="overall-parent">
                      <div className="order-details-with-clear">
                        <h5>Pond Name</h5>
                      </div>
                      {this.loadItems(orderDetail)}
                    </div>
                  </div>

                  <div className="cart_footer tnk_cart_footer">
                    <div className="cart_row">
                      <p className="text-uppercase">SUBTOTAL</p>

                      <span>${orderDetail.order_sub_total}</span>
                    </div>
                    {(orderDetail.order_sms_notification) > 0 && (
                      <div className="cart_row gst-row">
                        <p className="text-uppercase">
                          SMS Charge
                        </p>
                        <span>
                          $
                          {parseFloat(
                            smsCharge
                          ).toFixed(2)}
                        </span>
                      </div>
                    )}
                    <div className="cart_row grant-total-cls">
                      <p className="text-uppercase">Total</p>

                      <span>
                        <sup>$</sup>
                        {parseFloat(orderDetail.order_total_amount).toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <div className="tnk-chk-order">
                    <Link to={"/myorders"} className="button"> 
                    Check Your Booking Status
                    </Link>
                  </div>
                </div>
              </div>
              {/* order-detail-maindiv - end */}
            </div>
          )}
          {/* innersection_wrap - start */}
        </div>
        {/* container - end */}

        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  return {
    settingsArr: globalSettings,
    orderdetail: state.bookingorderdetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBookingOrderDetail: (orderId) => {
      dispatch({ type: GET_BOOKING_ORDER_DETAIL, orderId });
    },
    destroyBookingCartDetail: () => {
      dispatch({ type: DESTROY_BOOKING_CART_DETAIL });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Bookingthankyou);
