/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { stripslashes, showAlert } from "../Helpers/SettingHelper";
import { appId, deliveryId } from "../Helpers/Config";
var Parser = require("html-react-parser");
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import validator from "validator";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import { connect } from "react-redux";
import innerBanner from "../../common/images/inner-banner.jpg";
 import banner from "../../common/images/products-bc.jpg";
 import noimage from "../../common/images/noimg-470x240.jpg";

import {
  GET_PICKUP_OUTLETS,
  GET_CONTACTDATA,
  GET_CONTACTCONTENT,
  GET_GLOBAL_SETTINGS,
  GET_ALL_OUTLETS
} from "../../actions";

var Parser = require("html-react-parser");

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outlet_loaded: "no",
      outlets_result: [],
      outlets: [],
      outlet_id: "",
      fields: {
        name: "",
        mobile: "",
        subject: "",
        email: "",
        type: "",
        message: "",
      },
      pageTitle: "",
      pageDesc: "",
      status: "",
      windowHeight: 0,
    };
  }

  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleFormSubmit = () => {
    const formPayload = this.state.fields;

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      customer_first_name: formPayload.name,
      customer_emailaddress: formPayload.email,
      customer_phonenumber: formPayload.mobile,
      subject: formPayload.type,
      customer_message: formPayload.message,
    };

    this.props.getContactData(qs.stringify(postObject));
  };

  componentDidMount() {
    this.props.getGlobalSettings();
    /*this.props.getPickupOutlets();*/
    this.props.getAllOutlets(deliveryId);
    $("html, body").animate({ scrollTop: 0 }, 800);
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.contactdata !== this.props.contactdata) {
      if (nextProps.contactdata[0].status === "ok") {
        this.handleShowAlertFun("success", nextProps.contactdata[0].message);

        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        this.handleShowAlertFun("success", nextProps.contactdata[0].message);

        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    }
      if (nextProps.outlets !== this.props.outlets) {
      if (nextProps.outlets && nextProps.outlets[0].status == "ok") {
        $("#dvLoading").fadeOut(2000);
        this.setState({
          outlets: nextProps.outlets[0].result_set,
        });
      }
    }

    if (nextProps.globalsettings !== this.props.globalsettings) {
      if (
        nextProps.globalsettings &&
        nextProps.globalsettings[0].status == "ok"
      ) {
        this.setState({
          starttime: nextProps.globalsettings[0].result_set.client_start_time,
          endtime: nextProps.globalsettings[0].result_set.client_end_time,
        });
      }
    }
  }

  tConvert(time) {
    if (
      time !== "" &&
      typeof time !== undefined &&
      typeof time !== "undefined"
    ) {
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? " AM" : " PM";
        time[0] = +time[0] % 12 || 12;
      }
      return time.join("");
    }
  }

  allInOnepopup() {
    cookie.save("defaultAvilablityId", reservationId, { path: "/" });
    this.props.history.push("/reservation");
  }

  gotoContacus(outletId) {
    cookie.save("contusOutletId", outletId, { path: "/" });
    this.props.history.push("/contact-us");
  }

  callPhoneOptn(phoneTxt) {
    var resultTxt = "";
    console.log(phoneTxt, "phoneTxt");
    if (phoneTxt !== "" && phoneTxt !== null) {
      if (phoneTxt.indexOf("+65") !== -1) {
        resultTxt = "tel:" + phoneTxt;
      } else if (phoneTxt.indexOf("65") !== -1 && phoneTxt.length >= 10) {
        resultTxt = "tel:+" + phoneTxt;
      } else {
        resultTxt = "tel:+65" + phoneTxt;
      }
    } else {
      resultTxt = "javascript:void(0);";
    }

    return resultTxt;
  }

  timingCnt() {
    return <span> 24/7 Available</span>;
  }

  chooseOrder(){

      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });

  }

  sateValChangefn(outlvalue) {
    this.setState({ outlet_id: outlvalue });
  }

  getOutletDataold = () => {
    var outLetLst = this.state.outlets;
    var outletId = this.state.outlet_id;
    if (Object.keys(outLetLst).length > 0) {
      var listhtml = outLetLst.map((item, index) => (
        <div
          key={index}
          onClick={this.sateValChangefn.bind(this, item.outlet_id)}
          className={
            outletId === item.outlet_id ? "locate-lirow active" : "locate-lirow"
          }
        >
          <h4>{stripslashes(item.outlet_name)}</h4>
          <p>{item.outlet_address_line1}</p>
          <p>
            Phone: <span className="phone-show-dskp">{item.outlet_phone}</span>
            <span className="phone-show-mbl">
              {" "}
              <a href={this.callPhoneOptn(item.outlet_phone)}>
                {item.outlet_phone}
              </a>
            </span>
          </p>
        </div>
      ));
      return listhtml;
    } else {
      return "";
    }
  };

  getOutletData = (dataProp) => {
    if (dataProp) {
      return dataProp.map((item, index) => {
        if(item.outlet_id == '347'){
        return (
          <>
          <li key={index}>
            <div className="ourrest_row">
              <div className="ourrest_img">
                {item.outlet_image !== "" &&
                typeof item.outlet_image !== undefined &&
                typeof item.outlet_image !== "undefined" ? (
                  <img
                    src={mediaUrl + "outlet/" + item.outlet_image}
                    alt="Outlet Img"
                  />
                ) : (
                  <img className="media-object" src={noimage} />
                )}
              </div>
              <div className="ourrest_info">
                <h4>{stripslashes(item.outlet_name)}</h4>
                <p><i class="fa fa-fw  fa-map-marker"></i>
                  {item.outlet_address_line1}
                  {item.outlet_unit_number2 !== ""
                    ? "#" +
                      item.outlet_unit_number1 +
                      "-" +
                      item.outlet_unit_number2
                    : item.outlet_unit_number1}{" "}
                  Singapore - {item.outlet_postal_code}
                </p>
                <p>
                  {item.outlet_email !== "" && (
                    <span>
                      Email: {item.outlet_email} <br></br>
                    </span>
                  )}
                  {item.outlet_phone !== "" && ( <div>
                  <i class="fa fa-fw  fa-phone"></i>
                  {" "}
                  <span className="phone-show-dskp">{item.outlet_phone}</span>
                  <span className="phone-show-mbl">
                    {" "}
                    <a href={this.callPhoneOptn(item.outlet_phone)}>
                      {item.outlet_phone}
                    </a>
                  </span>{" "}
                  <br></br>
                  </div>)}
                  </p>
                  <p>
                  <i class="fa fa-fw  fa-clock-o"></i>{this.timingCnt(item.outlet_id)}
                </p>
                 <ul className="ourrest_infolinks">
                  <li className="media-links-b li-full-width">
                    <a
                      href="javascript:void(0)"
                      rel="nofollow"
                      onClick={this.chooseOrder.bind()}
                      className="readmore font-headings"
                    >
                      <i className="fa fa-thumbs-up"></i>Make A Order Now{" "}
                    </a>
                  </li>
                </ul></div>
            </div>
          </li>
          <li key={index}>
            <div className="ourrest_row">
              <div className="container">
              <div className="contactus_lhs">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.6772242120555!2d103.94922731426531!3d1.3703532618921468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da3dae4aec36b7%3A0xd49b80fab09648c0!2sD&#39;best%20Fishing%20(Main%20Pond)!5e0!3m2!1sen!2sin!4v1635137086588!5m2!1sen!2sin" width="600" height="600" allowfullscreen="" loading="lazy"></iframe>
              </div>
              </div>
            </div>
          </li>
          </>
        );
      }
      });
    }
  };


  contMapHtml = () => {
    var outletId = this.state.outlet_id;
    /* jankosoft 'Dempsey' => 190 */
    if (outletId === "218") {
      return (
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.784116066201!2d103.80677851426552!3d1.30458871208381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1a3cb9baec87%3A0x9afb0ed56701e15!2sMuthu&#39;s+Curry!5e0!3m2!1sen!2sin!4v1559310893750!5m2!1sen!2sin"
            width="600"
            height="450"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      );
    }
    /* jankosoft 'Race Course' => 192 */
    if (outletId === "216") {
      return (
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.775596869837!2d103.84998271832654!3d1.3099511611600971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19c71f708091%3A0xcb1c05d04cdfa80f!2sMuthu&#39;s+Curry!5e0!3m2!1sen!2sin!4v1559311174739!5m2!1sen!2sin"
            width="600"
            height="450"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      );
    }
    /* jankosoft 'Suntec' => 191 */
    if (outletId === "217") {
      return (
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7996915113254!2d103.85716761426546!3d1.294727262111923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19a8a51c9f51%3A0x882bc15b9ef17943!2sMuthu&#39;s+Curry!5e0!3m2!1sen!2sin!4v1559311243416!5m2!1sen!2sin"
            width="600"
            height="450"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="contactusmain-top-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
           <img src={banner} />
            <p>Contact Us</p>
          </div>
        </div>

        <div className="contact_wrap">
          <div className="contactus_wrapper">
            <div className="container">
              <Form
                fields={this.state.fields}
                onChange={this.fieldChange}
                onValid={this.handleFormSubmit}
                outletListData={this.getOutletDataold}
                contMapFun={this.contMapHtml}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>

      <div className="outletList-main-div">
        <div className="common-inner-blckdiv">
        </div>
        <div className="outlet-lst-page">
          <div className="container-one cms-content">
            <div className="container">
              <ul className="outletul">
                {this.getOutletData(this.state.outlets)}
              </ul>
            </div>
          </div>
        </div>
        <div className="hide_out">
        </div>
      </div>


        <Footer />

        <div id="dvLoading1234"></div>
      </div>
    );
  }
}

const phonenumberPattern = /^[0-9]{6,14}$/;

const isMobile = (mobile) =>
  mobile.match(phonenumberPattern) ? null : "please enter valid Phone number.";

const isEmpty = (value) => (value === "" ? "This field is required." : null);

const isEmail = (email) =>
  validator.isEmail(email) ? null : "This is not a valid email.";

function validationConfig(props) {
  const { name, mobile, email, message } = props.fields;

  return {
    fields: ["name", "mobile", "email", "message"],

    validations: {
      name: [[isEmpty, name]],
      mobile: [
        [isEmpty, mobile],
        [isMobile, mobile],
      ],
      email: [
        [isEmpty, email],
        [isEmail, email],
      ],
      message: [[isEmpty, message]],
    },
  };
}

class Form extends React.Component {
  state = {
    pageTitle: "",
    pageDesc: "",
  };

  render() {
    const {
      fields,
      onChange,
      onValid,
      onInvalid,
      $field,
      $validation,
    } = this.props;
    let errMsgFirstName, errMsgEmail, errMsgMobile, errMsgType, errMsgMessage;

    let contactcontent = "";

    if (this.props.contactContent != undefined) {
      contactcontent = Parser(
        this.props.contactContent.result_set[0].staticblocks_description
      );
    }

    if ($validation.name.error.reason !== undefined) {
      document.getElementsByClassName("name").className = "error";
      errMsgFirstName = $validation.name.show && (
        <span className="error">{$validation.name.error.reason}</span>
      );
    }

    if ($validation.mobile.error.reason !== undefined) {
      errMsgMobile = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }

    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }

    if ($validation.message.error.reason !== undefined) {
      errMsgMessage = $validation.message.show && (
        <span className="error">{$validation.message.error.reason}</span>
      );
    }

    return (
      <div className="white_bgbx">
        {/* <div className="locate-map">					
						<div className="locate-mapleft">
							<div className="locate-list">
								{this.props.outletListData()} 
							</div>
						</div>
						<div className="locate-mapright">
							<div id="locateGmap">
								{this.props.contMapFun()}
							</div>
						</div>
				</div> */}
        <div className="contact_form">
          <h3>DROP US A LINE</h3>
          <form className="form_sec clear">
            <div className="contact_col">
              <div className="form-group">
                <div className="focus-out">
                  <label>Name*</label>
                  <input
                    type="input"
                    className="form-control input-focus"
                    value={fields.name}
                    maxLength="80"
                    {...$field("name", (e) => onChange("name", e.target.value))}
                  />
                  {errMsgFirstName}
                </div>
              </div>
              <div className="form-group">
                <div className="focus-out">
                  <label>Contact Number*</label>
                  <input
                    type="text"
                    className="form-control input-focus"
                    value={fields.mobile}
                    maxLength="11"
                    {...$field("mobile", (e) =>
                      onChange("mobile", e.target.value)
                    )}
                  />
                  {errMsgMobile}
                </div>
              </div>
              <div className="form-group">
                <div className="focus-out">
                  <label>Email*</label>
                  <input
                    type="input"
                    className="form-control input-focus"
                    value={fields.email}
                    maxLength="85"
                    {...$field("email", (e) =>
                      onChange("email", e.target.value)
                    )}
                  />
                  {errMsgEmail}
                </div>
              </div>
            </div>
            {/*<div className="form-group">
								<div className="re_select">
									<select className="form-control select-gender" {...$field('type', (e) => onChange('type', e.target.value)) }>
										<option value=""> Please Select* </option>
										<option value="General Enquiry" id="general-enquiry"> General Enquiry </option>
										<option value="Order Enquiry" id="order-enquiry"> Order Enquiry </option>
										<option value="Others" id="others"> Others </option>
									</select>

									{errMsgType}

								</div>
							</div>*/}
            <div className="contact_col">
              <div className="form-group">
                <div className="focus-out">
                  <label>Message*</label>

                  <textarea
                    className="form-control input-focus"
                    id="feedback"
                    {...$field("message", (e) =>
                      onChange("message", e.target.value)
                    )}
                  ></textarea>
                  {errMsgMessage}
                </div>
              </div>
              <div className="btn_sec">
                <button
                  type="button"
                  className="button btn_black btn-lg btn-block"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.$submit(onValid, onInvalid);
                  }}
                >
                  Submit<div className="ripple-container"></div>
                </button>
                <br />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
Form = validated(validationConfig)(Form);

const mapStateToProps = (state) => {
  return {
    outlets: state.outlets,
    outlets: state.alloutlets,
    globalsettings: state.settings,
    contactdata: state.contactdata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    getContactData: (postObject) => {
      dispatch({ type: GET_CONTACTDATA, postObject });
    },
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};

Contact.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Contact)
);
