/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { connect } from "react-redux";
import update from "immutability-helper";
import Moment from "moment";
import OwlCarousel from 'react-owl-carousel2';
import "../../common/css/owl.carousel.css";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import MenuNavigation from "./MenuNavigation";
import ProductList from "./ProductList";
import ProductDetail from "./ProductDetail";
import OutletOptions from "../Layout/Outletoptions";
 import banner from "../../common/images/products-bc.jpg";
import {
  apiUrl,
  tagImageUrl,
  appId,
  cateringId,
  deliveryId,
} from "../Helpers/Config";
import { showLoader, hideLoader, showAlert } from "../Helpers/SettingHelper";
import {
  GET_GLOBAL_SETTINGS,
  GET_MENU_NAVIGATION,
  GET_STATIC_BLOCK,
  GET_ZONE_DETAIL,
} from "../../actions";
import gridimg from "../../common/images/grid.png";
import filterImg from "../../common/images/filter.svg";
import VirtualCard from "../../common/images/virtualcard.png";
import searchimg from "../../common/images/search.svg";
import closebl from "../../common/images/close-block.png";
import Dish from "../../common/images/dish.png";
import PromotionWhite from "../../common/images/promotion-white.png";
import DishWhite from "../../common/images/dish-white.png";
import Promotion from "../../common/images/promotion.png";
import ConvenienceStore from "../../common/images/convenience-store.png";
import StoreWhite from "../../common/images/store-white.png";
import FishingBaits from "../../common/images/fishing-baits.png";
import FishingBaitsWhite from "../../common/images/fishing-baits-white.png";
import Fisherman from "../../common/images/fisherman.png";
import FishermanWhite from "../../common/images/fisherman-white.png";
import ActivityLeft from "../../common/images/activity-left.jpg";
import ActivityRight from "../../common/images/activity-right.png";
import fourclmsImgone from "../../common/images/dish.svg";
import Slider from "react-slick";

const isEqual = require("react-fast-compare");
var Parser = require("html-react-parser");

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
      selectedCategoryName: "",
      selectedNavigation: "",
      selectedSlugType: "",
      searchSubCat: "",
      searchProVal: "",
      viewProductSlug: "",
      catNavIndex: 0,
      catslugType: "",
      staticblacks: [],
      catslugValue: "",
      cartTriggerFlg: "No",
      productload: "Yes",
      pricing_section: []
    };

    this.props.getSettings();
    var availbty = cookie.load("defaultAvilablityId");
    var outltIdTxt =
      typeof cookie.load("orderOutletId") === "undefined"
        ? ""
        : cookie.load("orderOutletId");
    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (availbty === deliveryId && outltIdTxt !== "" && zoneIdTxt !== "") {
      this.state["delivery_outlet_id"] = outltIdTxt;
      this.props.getZoneDetail(outltIdTxt, zoneIdTxt);
    }
    this.props.getMenuNavigationList();
    this.props.getStaticBlock();
  }

  componentWillReceiveProps(nextProps) {
    let slugType =
      typeof this.props.match.params.slugType !== "undefined"
        ? this.props.match.params.slugType
        : "";
    let slugValue =
      typeof this.props.match.params.slugValue !== "undefined"
        ? this.props.match.params.slugValue
        : "";
    let proValue =
      typeof this.props.match.params.proValue !== "undefined"
        ? this.props.match.params.proValue
        : "";
    let selectedNavigation = nextProps.selectedNavigation;
    let selectedSlugType = nextProps.selectedSlugType;
    var searchSubCat = "";
    if (!isEqual(this.props.match.params, nextProps.match.params)) {
      slugType =
        typeof nextProps.match.params.slugType !== "undefined"
          ? nextProps.match.params.slugType
          : "";
      slugValue =
        typeof nextProps.match.params.slugValue !== "undefined"
          ? nextProps.match.params.slugValue
          : "";
      proValue =
        typeof nextProps.match.params.proValue !== "undefined"
          ? nextProps.match.params.proValue
          : "";
    }

    if (slugValue !== "") {
      if (slugType !== "category" && slugType !== "subcategory") {
        searchSubCat = slugValue;
        slugValue = slugType;
        slugType = "category";
        /*$('.search_result').hide();*/
        var tmpVl = "";
        $("#productsearch").val(tmpVl);
        $("#clearSearch").hide();
        $(".hsearch_sec").removeClass("open");
        $(".hsearch_trigger").removeClass("active");
        setTimeout(function () {
          $(window).scrollTo($("." + proValue), 100);
        }, 2000);
      }
      selectedNavigation = slugValue;
    }

    if (slugType === "") {
      slugType = selectedSlugType;
    }

    if (
      selectedNavigation !== this.state.selectedNavigation ||
      this.state.selectedCategoryName !== nextProps.selectedCatry
    ) {
      if (slugType === "subcategory") {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_subcate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].subcategory_name
            : nextProps.selectedCatry;
      } else {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_cate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].category_name
            : nextProps.selectedCatry;
      }

      this.setState({
        selectedNavigation: selectedNavigation,
        catNavIndex: navIndex,
        catslugType: slugType,
        catslugValue: slugValue,
        selectedCategoryName: categoryNameTxt,
        searchSubCat: searchSubCat,
        searchProVal: proValue,
      });
    }


    if (nextProps.staticblack !== this.state.staticblacks) {
      var pricing_section = "";
      var activitiesandpricing = "";
      if (Object.keys(nextProps.staticblack).length > 0) {
        nextProps.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "pricing") {
            pricing_section = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "activitiesandpricing") {
            activitiesandpricing = data.staticblocks_description;
            return "";
          }
          
        });
      }
      pricing_section =
      pricing_section !== "" && pricing_section !== null
        ? Parser(pricing_section)
        : pricing_section;
      activitiesandpricing =
      activitiesandpricing !== "" && activitiesandpricing !== null
        ? Parser(activitiesandpricing)
        : activitiesandpricing;
      this.setState({
        staticblacks: nextProps.staticblack,
        pricing_section:pricing_section,
        activitiesandpricing:activitiesandpricing,
      });

    }    


  }

  choosePromo(pp_id,pp_card_id){
      var UserId =
        cookie.load("UserId") != "" && cookie.load("UserId") != undefined
          ? cookie.load("UserId")
          : "";
      if (UserId === "") {
        cookie.save("loginpopupTrigger", "Yes", { path: "/" });
        cookie.save("redirectPackages", "Yes", { path: "/" });
        this.props.history.push("/");
        return false;
      }
      showLoader('issue_vc'+pp_id,'class');
      var qs = require("qs");
      var postObject = {};
      postObject = {
        virtualcardid: pp_card_id,
        usertag: Math.floor(Math.random()*1E16),
        app_id: appId,
        pp_id: pp_id,
        customer_id: cookie.load("UserId"),
      };

      axios.post(
        apiUrl + "promotionpackages/virtualcardissue",
        qs.stringify(postObject)
      ).then((res) => {
          hideLoader('issue_vc'+pp_id,'class');
        if (res.data.status === "ok") {
          showAlert("Success", "Card Issued");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          setTimeout(function(){
          window.location.reload();
          },2000)
          return false;
        }else{
          showAlert("Error", res.data.result_set.returnstring);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          return false;
        }
      });
  }


  openStatusPopup(promotionpackages){
    console.log(promotionpackages)
    if(Object.keys(promotionpackages.virtual_cards).length > 0) {
    var cardDetails = promotionpackages.virtual_cards.map((loaddata, index) => {
          return (
            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx promo-card-whole">
                   <table className="table dbest-tabel">
                    <thead>
                     <tr>
                        <th scope="col">Virtual Card Id</th>
                        <th scope="col">Series No</th>
                        <th scope="col">Expiry Date</th>
                        <th scope="col">Issued Date</th>
                        <th scope="col">Card Status</th>
                        <th scope="col">Redeem Type</th>
                        <th scope="col">Total Quantity</th>
                        <th scope="col">Utilizable Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td data-label="Virtual Card Id">{loaddata.packages_vc_virtualcard_id}</td>
                        <td data-label="Series No">{loaddata.packages_vc_seriesno}</td>
                        <td data-label="Expiry Date">{loaddata.packages_vc_expirydate}</td>
                        <td data-label="Issued Date">{Moment(loaddata.packages_vc_created_on).format("DD-MM-YYYY hh:mm A")}</td>
                        <td data-label="Card Status">{loaddata.packages_vc_status}</td>
                        <td data-label="Redeem Type">{loaddata.packages_vc_redeemtype}</td>
                        <td data-label="Total Quantity">{loaddata.packages_vc_totalquantity}</td>
                        <td data-label="Utilizable Quantity">{loaddata.packages_vc_utilizablequantity}</td>
                      </tr>
                      <tr className="text-center dbest-self-outlet">
                      <td colspan="8"><p>To view more details &nbsp;
                      <a
                      className=""
                      target="_blank"
                      href={loaddata.packages_vc_virtualcardurl}>
                      Click here
                      </a></p>
                      </td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>);
        });
    }
              this.setState({cardDetails:cardDetails }, () => console.log(this.state.cardDetails));                    
      $.magnificPopup.open({
        items: {
          src: "#card-popup",
        },
        type: "inline",
      });


  }

  componentDidMount() {
    $(window).scroll(function () {
      var productlist_height = $(".productlist-main-div").offset();
      if (productlist_height !== undefined) {
        var mycustomscroll = $(".productlist-main-div").offset().top - 122,
          wind_toptxt = $(window).scrollTop();

        if (wind_toptxt > mycustomscroll) {
          $(".bakery_row").addClass("catogry_row_fixed");
        } else {
          $(".bakery_row").removeClass("catogry_row_fixed");
        }
      }
    });
    $(".product_search_result").hide();

    var UserId =
      cookie.load("UserId") != "" && cookie.load("UserId") != undefined
        ? cookie.load("UserId")
        : "0";
    axios.get(apiUrl+'promotionpackages/get_promo_packages?app_id='+appId+'&customer_id='+UserId).then(res => {
        if (res.data.status === "ok") {
          let promotionpackages = res.data.result_set;
          var promotionpackages_html = '';
          if(Object.keys(promotionpackages).length > 0) {
              var promotionpackages_html = promotionpackages.map((loaddata, index) => {
                    return (
                              <div className="">
                              <div className="col-lg-12 promo-package-main-div ">
                              <div className="col-lg-8 col-sm-12">
                              <div className="activity-inner package-inner">
                              <p>
                              {loaddata.pp_desc}</p>
                              </div>
                              </div>
                              <div className="col-lg-4 col-sm-12">
                              <div className="container-four">
                              <div className="our-promotions-section happening-section packages-promos-body">
                              {/*{loaddata.packages_vc_virtualcard_id == null && }*/}
                              <a className={`four-columns-btn four-columns-btns f-left issue_vc${loaddata.pp_id}`} href="javascript:void(0)">{loaddata.pp_title}</a>
                              {/*loaddata.virtual_cards.length > 0 &&
                              <a className="four-columns-btn four-columns-btns f-left" onClick={this.openStatusPopup.bind(
                              this, loaddata
                              )} >Card Details</a>
                              */}
                              </div>
                              </div>
                              </div>

                              </div>  
                              </div>
                    );
              });
              this.setState({promotionpackages_html:promotionpackages_html }, () => console.log(this.state.promotionpackages_html));                    
    }
    }
    });
    setTimeout(function(){
      $("html, body").animate(
        {
          scrollTop: $(".promo-package-main-head").offset().top,
        },
        500
      );
    },1000);
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "productlist" && value !== "") {
      this.setState({
        searchProResult: value,
        selectedProResult: value,
      });
    }
    if (field === "setFiltetTag" && value !== "") {
      this.setState({
        setFiltetTag: value,
      });
    }
  };

  productFlageChange = (field, value) => {
    this.setState(
      update(this.state, { productflage: { [field]: { $set: value } } })
    );
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  handleChange(section, event) {
    let filterTag = section.state.filterTag;
    if (event.target.checked === true) {
      filterTag.push(event.target.value);
    } else {
      var index = filterTag.indexOf(event.target.value);
      filterTag.splice(index, 1);
    }
    section.setState({ filterTag: filterTag, setFiltetTag: "Yes" });
  }

  chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");

    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (defaultAvilTy === cateringId && cartTotalItems > 0) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      } else if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    } else if (
      defaultAvilTy === availability &&
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
    ) {
      /*$.magnificPopup.close();
      this.props.history.push('/products');
      return false;*/
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === cateringId) {
      cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/");
      return false;
    } else if (availability === reservationId) {
      cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");
      return false;
      /*popupIdtxt = '#comingsoon-popup';*/
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  render() {

    var offerSlider = {
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: false,
      infinite: false,
      responsive: [
      {
      breakpoint: 1024,
      settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      },
      },
      {
      breakpoint: 767,
      settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      },
      },
      {
      breakpoint: 600,
      settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      },
      },
      {
      breakpoint: 480,
      settings: { 
      slidesToShow: 1,
      slidesToScroll: 1,
      },
      },
      ],
      };

    return (
      <div className="productpage-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
          showCatryName={this.state.selectedCategoryName}
        />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <img src={banner} />
         

          </div>
        </div>

         <OutletOptions />

        {/* Header End */}
        <section className="menu-nav-section promo-package-main-head">
          <div className="container">
            <h3>Activities & Pricing</h3>
            <p className="package-promo-para">{this.state.activitiesandpricing}</p>
            {/* <MenuNavigation
              {...this.props}
              productState={this.state}
              sateValChange={this.sateValChange}
            /> */}
          </div>
        </section>
        
        <section className="product-menu-listing">
        <div className="container">
        {this.state.promotionpackages_html}
        </div>
        </section>

        {this.state.pricing_section}


        {/* Footer section */}
        <Footer />
        {/* Card popup section */}
        <div id="card-popup"className="white-popup mfp-hide popup_sec login-popup packages-promos-popup"> 
        <div className="pop-whole">
            <div className="inside-popup-rhs">
            <div className="pop-whole-lhs-inner home-popup-head virtual-card-head">
              <img className="c-card-img" src={VirtualCard} />
                <h3>Virtual Card</h3>
                <p>Card details</p>
            </div>
            {this.state.cardDetails}
            </div>
          </div>
        </div>
        {/* Card popup section */}

      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";

  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        if (tempArr[0].result_set[0].menu_type == "main") {
          selectedCatry = tempArr[0].result_set[0].category_name;
          menu_slug = tempArr[0].result_set[0].pro_cate_slug;
          menu_type = "category";
        } else {
          selectedCatry = tempArr[0].result_set[0].subcategory_name;
          menu_slug = tempArr[0].result_set[0].pro_subcate_slug;
          menu_type = "subcategory";
        }
      }
    }
  }

  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    staticblack: blacksArr,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
    zonedetails: zonedetailArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getZoneDetail: (outletId, zoneId) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getMenuNavigationList: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Products);
