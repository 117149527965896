/* eslint-disable */

import React, { Component } from "react";
import { Link } from "react-router-dom";

import Slider from "react-slick";


class Menumobile extends React.Component {
  constructor(props) {
    super(props);
    
    
  }
 

  render() {

    var currenturl = window.location.href;
    var myaccount = "myaccount";
    var myorders = "myorders";
    var rewards = "rewards";
    var mypromotions = "mypromotions";
    var myvouchers = "myvouchers";

      let initialSlide = 0;

      if(currenturl.includes(myaccount) === true){
        initialSlide = 0;
      }
      if(currenturl.includes(myorders) === true){
        initialSlide = 1;
      }
      if(currenturl.includes(rewards) === true){
        initialSlide = 2;
      }
      if(currenturl.includes(mypromotions) === true){
        initialSlide = 3;
      }
      if(currenturl.includes(myvouchers) === true){
        initialSlide = 4;
      }

    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: initialSlide,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };


    



      return ( <div className="mbacc_mslidersec mbacc_mslider">
      <Slider {...settingsMyAcc}>
        <div  className={"mbacc_mslide "+(currenturl.includes(myaccount) === true?"active":"")}>
          <Link to="/myaccount" title="My Account">
            <span>Account Information</span>
          </Link>
        </div>
        <div  className={"mbacc_mslide "+(currenturl.includes(myorders) === true?"active":"")}>
          <Link to="/myorders" title="My Orders">
            <span>My Orders</span>
          </Link>
        </div>
        <div  className={"mbacc_mslide "+(currenturl.includes(rewards) === true?"active":"")}>
          <Link to="/rewards" title="My Rewards">
            <span>Rewards</span>
          </Link>
        </div>
        <div  className={"mbacc_mslide "+(currenturl.includes(mypromotions) === true?"active":"")}>
          <Link to="/mypromotions" title="My Promotions">
            <span>Promotions</span>
          </Link> 
        </div>
        <div  className={"mbacc_mslide "+(currenturl.includes(myvouchers) === true?"active":"")}>
          <Link to="/myvouchers" title="My Vouchers">
            <span>Vouchers</span>
          </Link> 
        </div>
        <div className="mbacc_mslide">
        <a href="#change-password-popup" className="open-popup-link" >Settings</a>
        </div>
        <div className="mbacc_mslide">
          <Link to="/logout" title="Logout">
            <span>Logout</span>
          </Link>
        </div>
      </Slider>
    </div>
      );
     
  }
}
 
export default Menumobile;
