/* eslint-disable */
import React, { Component } from 'react';
import { Link} from 'react-router-dom';
import Slider from "react-slick";
import { stripslashes, hideLoader } from "../Helpers/SettingHelper";
import Header from "../Layout/Header"
import Footer from "../Layout/Footer"
import axios from "axios";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GET_REQUESTPAGEDATA,GET_RES_ORDER_SUBMIT} from '../../actions';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cookie from 'react-cookies';
import moment from 'moment';
import {appId,apiUrl} from "../Helpers/Config";
import QrReader from 'react-qr-reader';
import tickImg from "../../common/images/tick.png";


class Scanbookingqrcode extends Component {

    constructor(props) {
      super(props);
			this.state = {order_id:''};
    }

	
	componentDidMount(){
    cookie.save("orderTabs", 'bookings', { path: "/" }); 
    this.setState({order_id:this.props.match.params.ordernumber});
	}

   handleScan = data => {
		
	    if ((data) != null) {

        var qs = require("qs");
        var postObject = {
          app_id: appId,
          order_id: this.state.order_id,
          table_number: data,
        };

        axios
        .post(apiUrl + "bookings/validate_order_table", qs.stringify(postObject))
        .then((response) => {
          console.log(response.data.status)
          if(response.data.status === 'ok'){
           cookie.save("orderTabs", 'bookings', { path: "/" });
            $.magnificPopup.open({
            items: {
            src: '#dinein-success-popup',
            },
            type: "inline",
            }) 
           window.location.href ="/myorders";
          }else{
            $.magnificPopup.open({
            items: {
            src: '#dinein-error-popup',
            },
            type: "inline",
            })
            return false;
          }
        });

	    }else {

      }
	}

  handleError = err => {

  }


  backtosite(){
    cookie.save("orderTabs", 'bookings', { path: "/" }); 
    window.location.href = '/';
  }


    render() {
		return (
        <>
        <div className="dinein-scantable">
          <div className="dinein-div">
          <a href="/myorders">&#60; </a><p>Scan QR code</p>
          </div>
          <div id="qr_code" className="dinein-scantable-inner">
            <QrReader
            delay={1000}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: '100%' }}
            />
          </div>
          {/*Booking Errorpopup for scan*/}
            <div id="dinein-error-popup" className="white-popup mfp-hide popup_sec warning_popup">
              <div className="custom_alert">
                <div className="custom_alertin">
                  <div className="alert_height">
                    <div className="alert_header">Information</div>
                    <div className="alert_body">
                      <p>Table number is not exists!</p>
                      <div className="alt_btns alt-btns">
                        <a href="/#" onClick={this.backtosite.bind(this)} className="button button-left popup-modal-dismiss disbl_href_action"
                        >Ok
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/*Booking Errorpopup for scan - End*/}
          {/*Booking success for scan*/}
            <div id="dinein-success-popup" className="white-popup mfp-hide popup_sec warning_popup">
              <div className="custom_alert">
                <div className="custom_alertin">
                  <div className="alert_height">
                    <div className="alert_header">Information</div>
                    <div className="alert_body">
                      <p>Table number is exists!</p>
                      <div className="alt_btns alt-btns">
                        <a href="/#" onClick={this.backtosite.bind(this)} className="button button-left popup-modal-dismiss disbl_href_action"
                        >Ok
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/*Booking Errorpopup for scan - End*/}
        </div>
        </>
        );
    }
}




export default (Scanbookingqrcode);

 
