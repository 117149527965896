/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";
import Slider from "react-slick";
import { Link, withRouter } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel2';
import "../../common/css/owl.carousel.css";
import {
  appId,
  apiUrl,
  deliveryId,
  pickupId,
  cateringId,
  reservationId,
  timThumpUrl,
  apiUrlV2 
} from "../Helpers/Config";

import {
  showLoader,
  hideLoader,
  validateEmailFun,
  stripslashes,
  showCustomAlert,
  showAlert,
  removeOrderDateTime,
  removePromoCkValue,  
} from "../Helpers/SettingHelper";
import {
  DESTROY_CART_DETAIL
} from "../../actions";
import ActivitiesAdvancedDatetimeSlot from "./ActivitiesAdvancedDatetimeSlot";

import takeawayrest from "../../common/images/takeawayrest.jpg"
import fourclmsImgone from "../../common/images/delivery-man.svg";
import fourclmsImgtwo from "../../common/images/bag.svg";
import fourclmsImgthree from "../../common/images/dish.svg";
import fourclmsImgfour from "../../common/images/calendar.svg";
import Fisherman from "../../common/images/fisherman.png";
import FishermanWhite from "../../common/images/fisherman-white.png";
import Dish from "../../common/images/dish.png";
import PromotionWhite from "../../common/images/promotion-white.png";
import DishWhite from "../../common/images/dish-white.png";
import Promotion from "../../common/images/promotion.png";
import ConvenienceStore from "../../common/images/convenience-store.png";
import StoreWhite from "../../common/images/store-white.png";
import FishingBaits from "../../common/images/fishing-baits.png";
import FishingBaitsWhite from "../../common/images/fishing-baits-white.png";


import mapmarkericon from "../../common/images/mapmarker.png";
import clkicon from "../../common/images/clock.png";
import directionicon from "../../common/images/compass.png";

import mainLogo from "../../common/images/home-logo.png";
import iconUnhappy from "../../common/images/sad-smiley.png";
import iconWin from "../../common/images/icon-win.svg";
import warningImg from "../../common/images/warning.svg";

import avicon from "../../common/images/av-icon.png";
import loupe from "../../common/images/search.png";
import loginav from "../../common/images/login-av.png";
import CartSideBar from "./CartSideBar";

import deliveryImg from "../../common/images/delivery-man.png";
import deliverywhiteImg from "../../common/images/delivery-man2.png";
import takeawayImg from "../../common/images/takeaway.png";
import dininImg from "../../common/images/rest.png";
import reservationImg from "../../common/images/calander.png";
var Parser = require("html-react-parser");
var dateFormat = require("dateformat");

import { setMinutes, setHours, format } from "date-fns";

class Outletoptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outlets_categories:[],
      outlets_categories_html: '',
      category_outlets_html: '',
      outlets_categories_popup_html:'',
      main_ponds_html:'',
      seleted_ord_date_text:'',
      sldordtime:'',
      activities_category_id:'',
	  hourcost_label: '',
      booking_slottype: ''
    };
  }

getCategories(outlet_category_id){
  if(typeof this.state.[outlet_category_id] == 'undefined'){
    axios.get(apiUrlV2 + 'outlets/getAllOutles?app_id='+appId+
                  "&outlet_category_id=" +outlet_category_id+
                  "&availability_id=" +deliveryId).then(res => {
              if (res.data.status === "ok" && Object.keys(res.data.result_set).length == 1) {

                axios.get(apiUrlV2 + 'products/getMenuNavigation?app_id='+appId+
                        "&outletId=" +res.data.result_set[0].outlet_id+
                        "&availability_id=" +deliveryId).then(res => {
                        var rows = res.data.result_set.map((loaddatacat, indexcat) => {
                             return (
                               <li class="cat_click" onClick={this.chooseAvailabilityFun.bind(
                              this,deliveryId, outlet_category_id,loaddatacat.pro_cate_slug
                              )} > <a href="javascript:void(0)">{loaddatacat.category_name}</a></li>
                              );
                        });
                        this.setState({[outlet_category_id]: rows});
                        if(typeof this.state.[outlet_category_id] != 'undefined'){
                          cookie.save("loaded", "Yes", { path: "/" });
                        }
                });
              }
    });
  }else{
    return false;
  }
}


getall(){
  if(cookie.load('loaded') != 'Yes'){
  axios.get(apiUrl + 'outlets/getOutletCategories?app_id='+appId).then(res => {
    if (res.data.status === "ok") {
      let outlets_categories = res.data.result_set;
      var outlets_categories_html = '';
      this.setState({outlets_categories:outlets_categories })
      if(Object.keys(outlets_categories).length > 0) {
          var outlets_categories_popup_html = outlets_categories.map((loaddata, index) => {
            var outlet_img = '';
            var outlet_img_white = '';
            if(loaddata.outlet_category_id != '6'){
                if(loaddata.outlet_category_slug === 'baits-equipment'){
                  outlet_img = FishingBaits;
                  outlet_img_white = FishingBaitsWhite;                   
                }else if(loaddata.outlet_category_slug === 'convenience-store'){
                  outlet_img = ConvenienceStore;
                  outlet_img_white = StoreWhite;
                }else if(loaddata.outlet_category_slug === 'food-beverages'){
                  outlet_img = Dish;
                  outlet_img_white = DishWhite;
                }
                return (
                  <li>
                  <a
                  href="/"
                  >
                  <div className="header-ordernow-single-img" onClick={this.chooseAvailabilityFun.bind(
                    this,deliveryId, loaddata.outlet_category_id,''
                    )} >
                  <div className="cat-img">
                  <img className={'order_type_img no_mou'+(index+1)} src={outlet_img} />
                  <img className={'order_type_img mou_active'+(index+1)} src={outlet_img_white} />
                  </div>
                  <div className="offer_txt">
                  <h3>{loaddata.outlet_category_title}</h3>
                  </div>
                  </div>
                  </a>
                  </li>
                );
            }
          });
          this.setState({outlets_categories_popup_html:outlets_categories_popup_html }, () => console.log(this.state.outlets_categories_popup_html));                    


          var outlets_categories_html = outlets_categories.map((loaddata, index) => {
            var outlet_img = '';
            var outlet_img_white = '';
            if(loaddata.outlet_category_id != '6'){
                if(loaddata.outlet_category_slug === 'baits-equipment'){
                  outlet_img = FishingBaits;
                  outlet_img_white = FishingBaitsWhite;                   
                }else if(loaddata.outlet_category_slug === 'convenience-store'){
                  outlet_img = ConvenienceStore;
                  outlet_img_white = StoreWhite;
                }else if(loaddata.outlet_category_slug === 'food-beverages'){
                  outlet_img = Dish;
                  outlet_img_white = DishWhite;
                }
                return (
                  <div className="four-columns">
                  <div className="cat-img">
                  <img className={'no_mou'+(index+1)} src={outlet_img} />
                  <img className={'mou_active'+(index+1)} src={outlet_img_white} />
                  </div>
                  <div className="offer_txt">
                  <h3>
                  {loaddata.outlet_category_title}
                  </h3>
                  <a className="four-columns-btn" href="javascript:void(0)" onClick={this.chooseAvailabilityFun.bind(
                    this,deliveryId, loaddata.outlet_category_id,''
                    )} data-effect="mfp-zoom-in" >
                  Order Now</a>
                  </div>
                  {typeof this.state.[loaddata.outlet_category_id] == 'undefined' && 
                  this.getCategories(loaddata.outlet_category_id)
                  }

                  {typeof this.state.[loaddata.outlet_category_id] != 'undefined' && this.state.[loaddata.outlet_category_id] != '' && (
                    <>
                  <div className="choose-option-hover">
                    <ul className={"list_cat_" + loaddata.outlet_category_id} >
                    {this.state.[loaddata.outlet_category_id]}
                    </ul>
                  </div>
                   <div className="hcategory_sec header">
                       <a href="javascript:void(0)" className="disbl_href_action">
                       <span className="hcategory_trigger_cat"></span></a>
                       <div className="hcategory_menu_cat">
                          <ul className="hcategory_menulist">
                             <li className="">
                                <ul className="hcategory_submenu">
                                  {this.state.[loaddata.outlet_category_id]}
                                </ul>
                             </li>
                          </ul>
                       </div>
                    </div>
                  </>
                  )}
                  </div>
                );
            }
          });
          this.setState({outlets_categories_html:outlets_categories_html }, () => console.log(this.state.outlets_categories_html));                    

        } else {
          this.setState({outlets_categories_html:'',outlets_categories_popup_html: '' }, () => console.log(this.state.outlets_categories_html));
        }
      } else if (res.data.status === "error") {
      }
      return false;
    });

  }
}

  componentDidMount() {
    this.loadMainponds();
  }


  listoutletcategories() {
      return this.state.outlets_categories_html;
  }

  listoutletcategoriespopup() {
      return this.state.outlets_categories_popup_html;
  }


  deliveryOutletDirect(outlet_id,postalcode,direct = '') {

        this.setState({ 'delivery_outlet_id': outlet_id }, () => 
        console.log(this.state.delivery_outlet_id));

        axios.get(
          apiUrlV2 +
            "outlets/findOutletZone?app_id=" +
            appId +
            "&availability_id=" +
            deliveryId +
            "&postal_code=" +
            postalcode +
            "&outlet_id=" +
            outlet_id
        ).then(res => {

          var deliveryInfo = [];
          /* Success response */
          if (res.data.status === "ok") {
            $.magnificPopup.close();
            cookie.save("outletchosen", deliveryId, { path: "/" });

            var orderHandled =
              stripslashes(res.data.result_set.outlet_name) +
              ", Crew will be seeing you in " +
              res.data.result_set.outlet_delivery_timing +
              " Minutes";

            deliveryInfo["orderZoneId"] = res.data.result_set.zone_id;
            deliveryInfo["orderOutletId"] = res.data.result_set.outlet_id;
            deliveryInfo["orderOutletName"] = stripslashes(
              res.data.result_set.outlet_name
            );
            deliveryInfo["orderPostalCode"] =
              res.data.result_set.postal_code_information.zip_code;
            deliveryInfo["orderTAT"] =
              res.data.result_set.outlet_delivery_timing;
            deliveryInfo["orderHandled"] = orderHandled;
            deliveryInfo["defaultAvilablityId"] = deliveryId;

            var unitNum = this.showUnitNum(
              res.data.result_set.outlet_unit_number1,
              res.data.result_set.outlet_unit_number2
            );

            var orderHandledText =
              res.data.result_set.outlet_address_line1 +
              " " +
              res.data.result_set.outlet_address_line2 +
              ", " +
              unitNum +
              " Singapore " +
              postalcode;

            deliveryInfo["orderHandledByText"] = orderHandledText;

            cookie.save("orderZoneId", deliveryInfo["orderZoneId"], {
              path: "/",
            });


          /*if((cookie.load("orderOutletId") != undefined && cookie.load("orderOutletId") != deliveryInfo["orderOutletId"]) || cookie.load("mainOutletId") == '347'){
              cookie.save("mainOutletId", '347', {
                path: "/",
              });
            }else{
              cookie.save("mainOutletId", deliveryInfo["orderOutletId"], {
                path: "/",
              });
            } */

              cookie.save("mainOutletId", '347', {
                path: "/",
              });
              cookie.save("mainZoneId", '455', {
                path: "/",
              });

              cookie.save("orderOutletNameCommon", deliveryInfo["orderOutletName"], {
              path: "/",
              });
              cookie.save("orderOutletName", deliveryInfo["orderOutletName"], {
              path: "/",
              });
              cookie.save("orderHandledByText", deliveryInfo["orderHandledByText"], {
              path: "/",
              });

              cookie.save("orderOutletId", deliveryInfo["orderOutletId"], {
                path: "/",
              });
              
              cookie.save("orderTAT", deliveryInfo["orderTAT"], { path: "/" });
              cookie.save("orderHandled", deliveryInfo["orderHandled"], {
                path: "/",
              });

              if(typeof cookie.load("orderUmberellaNo") == 'undefined' || cookie.load("orderUmberellaNo") == ''){
              cookie.save(
                "defaultAvilablityId",
                deliveryInfo["defaultAvilablityId"],
                { path: "/" }
              );
              }
              
              cookie.save("outletchosen", deliveryInfo["defaultAvilablityId"], {
                path: "/",
              });


           axios.get(apiUrlV2 + 'outlets/getAllOutles?app_id='+appId+
            "&outlet_id=347&availability_id=" +deliveryId).then(res => {

                  if (res.data.status === "ok") {
                    var unitNum = this.showUnitNum(
                      res.data.result_set[0].outlet_unit_number1,
                      res.data.result_set[0].outlet_unit_number2
                    );

                    var orderHandledText =
                      res.data.result_set[0].outlet_address_line1 +
                      ", " +
                      unitNum +
                      " Singapore " +
                      res.data.result_set[0].outlet_postal_code;

                    cookie.save("orderOutletNameCommon", stripslashes(
                      res.data.result_set[0].outlet_name
                    ), {
                    path: "/",
                    });

                    cookie.save("orderHandledByText", orderHandledText, {
                    path: "/",
                    });
                    if(direct == ''){
                      window.location.href = '/products';  
                    }else{
                      window.location.href = '/products/category/'+direct;  
                    }
                  }
              });


          
          }
        });
         
  }


  handleKeyPressDly = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.outletlist.filter(function (item) {
        return (
          item.outlet_address_line1.toLowerCase().indexOf(value) >= 0 ||
          item.outlet_postal_code.toLowerCase().indexOf(value) >= 0  ||
          stripslashes(item.outlet_name).toLowerCase().indexOf(value) >= 0
        );
      });

    if (matches && Object.keys(matches).length > 0) { 
     var row = matches.map((loaddata, index) => (
        <li key={index}>
          <a href="javascript:void()" onClick={this.deliveryOutletDirect.bind(this,loaddata.outlet_id,loaddata.outlet_postal_code)}>
            {stripslashes(loaddata.outlet_name)},{" "}
            {loaddata.outlet_address_line1},{" "}
            {this.showUnitNum(
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2
            )}
            Singapore {loaddata.outlet_postal_code}
          </a>
        </li>        
      ));
      this.setState({category_outlets_html:row })
    } else {
      this.setState({category_outlets_html: Parser('<li> <a>No Outlet found</a> </li>') }) 
    }

  };


  showUnitNum(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : "";
    unit2 = typeof unit2 !== "undefined" ? unit2 : "";

    if (unit1 !== "") {
      var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
    } else {
      var unitNo = unit2;
    }

    return unitNo !== "" ? "#" + unitNo + "," : "";
  }

  loadDeliveryOutletsList(outlet_category_id) {
    let row = '';
    axios.get(apiUrlV2 + 'outlets/getAllOutles?app_id='+appId+
            "&outlet_category_id=" +outlet_category_id+
            "&availability_id=" +deliveryId).then(res => {

    if (res.data.status === "ok" && Object.keys(res.data.result_set).length > 0) { 
      this.setState({outletlist: res.data.result_set});
      row = res.data.result_set.map((loaddata, index) => (
        <li key={index}>
          <a href="javascript:void()" onClick={this.deliveryOutletDirect.bind(this,loaddata.outlet_id,loaddata.outlet_postal_code)}>
            {stripslashes(loaddata.outlet_name)},{" "}
            {loaddata.outlet_address_line1},{" "}
            {this.showUnitNum(
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2
            )}
            Singapore {loaddata.outlet_postal_code}
          </a>
        </li>        
      ));
      this.setState({category_outlets_html:row })
    } else {
      this.setState({category_outlets_html:'<li> <a>No Outlet found</a> </li>' }) 
    }
  });
  }

  loadMainponds(outlet_category_id) {
    let row = '';
    if(!outlet_category_id){
      outlet_category_id = '';
    }
    axios.get(apiUrl + 'bookings/categories?app_id='+appId+
            "&category_id=" +outlet_category_id).then(res => {

    if (res.data.status === "ok" && Object.keys(res.data.result_set).length > 0) { 
      row = res.data.result_set.map((loaddata, index) => (
        <option value={loaddata.bcate_id}>{loaddata.bcate_name}</option>
      ));
      this.setState({main_ponds_html:row })
    } else {
      this.setState({main_ponds_html:'<li> <a>No Ponds found</a> </li>' }) 
    }
  });
  }

  handleChangePond(event) {

      this.setState({ getDateTimeFlg: "yes" });
      this.setState({activities_category_id:event.target.value})
      $('#error_span').hide();

  }

  destroyCart(clear = "No") {
    this.props.destroyCartDetail();
    this.deleteOrderCookie(clear);
  }

    deleteOrderCookie(clear = "Yes") {
    if (clear == "Yes") {
      cookie.remove("orderZoneId", { path: "/" });
      cookie.remove("orderOutletId", { path: "/" });
      cookie.remove("outletchosen", { path: "/" });
    }

    removeOrderDateTime();
    removePromoCkValue();

    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    cookie.remove("orderOutletName", { path: "/" });
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });
    cookie.remove("firstNavigation", { path: "/" });
    cookie.remove("orderPostalCode", { path: "/" });
    cookie.remove("orderDeliveryAddress", { path: "/" });

    /* Delivery avilablity */
    cookie.remove("orderDateTime", { path: "/" });
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" });
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
    cookie.remove("mainOutletId", { path: "/" });
    cookie.remove("orderOutletId", { path: "/" });
    
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  /* add to cart */
  addToBooking(event) {
      event.preventDefault();

      var customerId =
        cookie.load("UserId") != "" && cookie.load("UserId") != undefined
          ? cookie.load("UserId")
          : "";
      if (customerId === "") {
        $.magnificPopup.open({
        items: {
        src: '#login-popup'
        },
        type: 'inline'
        });
        cookie.save("redirectActivities", "Yes", { path: "/" });
        return false;
      }

      $('#error_span').hide();

      if (this.state.activities_category_id == '' || this.state.seleted_ord_date_text == '' || this.state.seleted_ord_time == '') {
          $('#error_span').show();
          return false;
      }   
      var seletedOrdDate = this.state.seleted_ord_date;
      var seletedOrdTime = this.state.seleted_ord_time;
      var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();
      var orderDateTime = new Date(OrderDate);
          orderDateTime.setHours(OrderHours);
          orderDateTime.setMinutes(OrderMunts);
          orderDateTime.setSeconds(OrderSecnd);

      cookie.save("seleted_ord_date", this.state.seleted_ord_date_text);
      cookie.save("seleted_ord_slot_str", this.state.seleted_ord_slot_str);
      cookie.save("seleted_ord_slot_end", this.state.seleted_ord_slot_end);
      cookie.save("seleted_ord_slotTxt", this.state.seleted_ord_slotTxt);
      cookie.save("seleted_ord_booking", 'yes');
	  
      // showLoader("booking_btn", "class");

      var postObject = {};
      postObject = {
        app_id: appId,
        category_id: this.state.activities_category_id,
        slot_date: this.state.seleted_ord_date_text,
        slot_start_time: this.state.seleted_ord_slot_str,
        slot_end_time: this.state.seleted_ord_slot_end,
        hourcost_label: this.state.hourcost_label,
        booking_slottype: this.state.booking_slottype,
        cart_price: cookie.load("total_booking_price"),
        additional_hours: cookie.load("additional_hours"),
        category_type: this.state.seleted_payment_basis,
        catch_count: cookie.load("total_catch_count") != "" && cookie.load("total_catch_count") != undefined
          ? cookie.load("total_catch_count")
          : "1",
        customer_id: customerId,
        holiday_type: this.state.seleted_holiday_type,
      };
      var qs = require("qs");
      
      /*axios.post(apiUrl + "bookings/addToCart", qs.stringify(postObject)).then((res) => {*/
	  
      axios.post(apiUrl + "bookings/insertcart", qs.stringify(postObject)).then((res) => {
        hideLoader("booking_btn", "class");
        if (res.data.status === "ok") {
          this.handleShowAlertFun(
            "success",
            "Great choice! Item added to your cart."
          );
		  this.props.history.push('/refpage/gotohome');
          /*window.location.href = '/booking';*/
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== ""
              ? res.data.message
              : "Sorry! Products can`t add your cart.";
          this.handleShowAlertFun("Error", errMsgtxt);
        }

        return false;
      });
  }


  setdateTimeFlg = (field, value) => {
    // console.log(field)
    // console.log(value)
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
		hourcost_label: ordTime,
        booking_slottype: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: tmSltArr["sldordtime"],
        seleted_ord_slot_end: ordTime,
		hourcost_label: ordTime,
        booking_slottype: ordTime,
      });
      var OrdDateTimeArr = Array();
      OrdDateTimeArr["OrdDate"] = tmSltArr["sldorddate"];
      OrdDateTimeArr["OrdTime"] = tmSltArr["sldordtime"];
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
		hourcost_label: ordTime,
        booking_slottype: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
	  
      this.setState({
        seleted_ord_date_text: tmSltArr["sldorddate"],
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: tmSltArr["ordSlotVal"],
        seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
        seleted_ord_slot_str: tmSltArr["ordSlotStr"],
        seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
        seleted_payment_basis: tmSltArr["payment_basis"],
        seleted_bps_one_hour_cost: tmSltArr["bps_one_hour_cost"],
        seleted_bps_two_hours_cost: tmSltArr["bps_two_hours_cost"],
        seleted_bps_three_hours_cost: tmSltArr["bps_three_hours_cost"],
        seleted_price: tmSltArr["price"],
        seleted_per_count: tmSltArr["per_count"],
        seleted_holiday_type: tmSltArr["holiday_type"],
        hourcost_label: tmSltArr["hourcost_label"],
        booking_slottype: tmSltArr["booking_slottype"],
      });
      var OrdDateTimeArr = Array();
      OrdDateTimeArr["OrdDate"] = tmSltArr["sldorddate"];
      OrdDateTimeArr["OrdTime"] = tmSltArr["sldordtime"];
    }
  };


  chooseAvailabilityFun(availability, outlet_category_id, direct = '', event) {
    
    event.preventDefault();
    axios.get(apiUrlV2 + 'outlets/getAllOutles?app_id='+appId+
            "&outlet_category_id=" +outlet_category_id+
            "&availability_id=" +availability).then(res => {
    if (res.data.status === "ok" && Object.keys(res.data.result_set).length == 1) {
      this.deliveryOutletDirect(res.data.result_set[0].outlet_id,res.data.result_set[0].outlet_postal_code,direct);
    }else if (res.data.status === "ok" && Object.keys(res.data.result_set).length > 1) {
        this.loadDeliveryOutletsList(outlet_category_id);      
        var defaultAvilTy = deliveryId;

        if (defaultAvilTy !== availability) {
        var cartTotalItems = cookie.load("cartTotalItems");
        cartTotalItems =
          cartTotalItems != "" && cartTotalItems != undefined
            ? parseInt(cartTotalItems)
            : 0;
        if (defaultAvilTy === cateringId && cartTotalItems > 0) {
          this.setState({ nextavail: availability });
          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          return false;
        } else if (
          cookie.load("orderOutletId") != "" &&
          cookie.load("orderOutletId") != undefined
        ) {
          this.setState({ nextavail: availability });
          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          return false;
        }
      } else if (
        defaultAvilTy === availability &&
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        /*$.magnificPopup.close();
        this.props.history.push('/products');
        return false;*/
      }

      var popupIdtxt = "";
      if (availability === deliveryId) {
        $(".delivery_outletpoup").find(".outlet_error").html("");
        popupIdtxt = "#delevery-popup";
      } else if (availability === pickupId) {
        popupIdtxt = "#takeaway-popup";
      } else if (availability === cateringId) {
        cookie.save("defaultAvilablityId", cateringId, { path: "/" });
        $.magnificPopup.close();
        this.props.history.push("/");
        return false;
      } else if (availability === reservationId) {
        cookie.save("defaultAvilablityId", reservationId, { path: "/" });
        $.magnificPopup.close();
        this.props.history.push("/reservation");
        return false;
        /*popupIdtxt = '#comingsoon-popup';*/
      }

      if (popupIdtxt !== "") {
        $.magnificPopup.open({
          items: {
            src: popupIdtxt,
          },
          type: "inline",
        });
      }
    }else{
      showAlert("Error", "No Outlet found!");
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
    }
  });
  }

  render() {
  $(document).find(".hcategory_trigger_cat").click(function () {
    $(this).toggleClass("active");
    $(this).parents('.hcategory_sec').find(".hcategory_menu_cat").toggleClass("open");
  });
 var settingsLikemore = {
  autoplay: false,
  margin: 20,
  loop: true,
  nav: true,
  dots: true,
  arrows:true,
  responsive: {
    0: {
      items: 1,
      dots: true,
    },
    480: {
      items: 2,
    },
    780: {
      items: 3,
    },
    1200: {
      items: 4,
    },
  },
};

    return (

      <section className="white-home">
    <div className="bann-container">
        <div className="home-top-four-section">
            <div className="four-columns">
                <div className="cat-img">
                    <img className="no_mou5" src={Promotion} />
                    <img className="mou_active5" src={PromotionWhite} />
                </div>
                <div className="offer_txt">
                    <h3>
                        Activities & Pricing
                    </h3>
                    <a className="four-columns-btn" href="/packages-promos" data-effect="mfp-zoom-in"> 
                        View</a>
                </div>
            </div>
            <div className="four-columns">
                <div className="cat-img">
                    <img className="no_mou1" src={Fisherman} />
                    <img className="mou_active1" src={FishermanWhite} />
                </div>
                <div className="offer_txt">
                    <h3>
                        Activities
                    </h3>
                    <a className="four-columns-btn open-popup-link activities" href="#activities"
                      data-effect="mfp-zoom-in">
                        Book Now</a>
                </div>
            </div>
            {this.listoutletcategories()}
            {this.getall()}
        </div>

    </div>

        {/* Order popup - start */}
        <div
          id="order-popup"
          className="white-popup mfp-hide popup_sec order_popup"
        >
          <div className="order-body">
            <h2>Order Now</h2>
            <p>Select your order type</p>
            <div className="order_delivery_row">
              <div className="order_delivery_col">
                <ul className="order_delivery_item">
                  <li> 
                    <a
                      href="/packages-promos"
                      //onClick={this.chooseAvailabilityFun.bind(this, pickupId)}
                      //className={this.checkActiveDivHd(pickupId)}
                    >
                      <div className="header-ordernow-single-img">
                      <div className="cat-img">
                      <img className="order_type_img no_mou5" src={Promotion} />
                        <img className="order_type_img mou_active5" src={PromotionWhite} />
                        </div>
                        <div className="offer_txt">
                        <h3>Activities & Pricing</h3>
                      </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="open-popup-link activities" href="#activities"
                        data-effect="mfp-zoom-in">
                      <div className="header-ordernow-single-img">
                      <div className="cat-img">
                        <img className="order_type_img no_mou1" src={Fisherman} />
                        <img className="order_type_img mou_active1" src={FishermanWhite} />
                        </div>
                        <div className="offer_txt">
                        <h3>Activities</h3>
                      </div>
                      </div>
                    </a>
                  </li>

                  {this.listoutletcategoriespopup()}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Order popup - end */}

            {/* Activities popup section */}
<div id="activities" className="white-popup mfp-hide popup_sec activities-popup-main">
    <div className="pop-whole">
        <div className="inside-popup-rhs">
            <div className="activities-popup home-popup-head activities-popup-head">
                <h3>
                    <img className="c-card-img" src={Fisherman} />
                    Activities
                </h3>
            </div>
            <div className="activities-popup-body acc-inform custom_select">
                <div className="activities-main-pond">
                    <select class="form-select" aria-label="Select Pond" onChange={this.handleChangePond.bind(this)}>
                        <option value="">Select Pond</option>
                        {this.state.main_ponds_html}
                    </select>
                </div>
                <span id="error_span">Please select a Pond and Date/Time.</span>
                <div className="form-group timeslot_info_div">
                    <div className="row">

                            <div>
                                <ActivitiesAdvancedDatetimeSlot
                                {...this.props} 
                                hdrState={this.state}
                                setdateTimeFlg={this.setdateTimeFlg}
                                />
                            </div>

                    </div>
                </div>
                <div className="activities-bottom-button">
                    <a className="four-columns-btn f-left booking_btn" onClick={this.addToBooking.bind(
                      this
                    )} href="#">Book</a>
                </div>
            </div>
        </div>
    </div>
</div>
    {/* Activities section */}

    <div id="delevery-popup"className="white-popup mfp-hide popup_sec login-popup"> 
        <div className="pop-whole">
            <div className="inside-popup-rhs">
            <div className="pop-whole-lhs-inner home-popup-head">
            <img src={fourclmsImgone}  style={{height:"104px"}} />
                <h3>Delivery</h3>
                <p>Let us Know your delivery Location</p>
            </div>
            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    <div className="form-group">
                      <div className="focus-out">
                        <label>Search Outlet</label>
                        <input
                          type="input"
                          className="form-control input-focus"
                          onKeyUp={this.handleKeyPressDly}
                        />
                        <div className="outlet_error"></div>
                      </div>
                    </div>
                  </div>

                  <div className="self_outlet">
                    <h2>Nearby Outlets</h2>
                    <ul className="self_outlet_inner">
                    {this.state.category_outlets_html}
                    </ul>
                    <a
                      className="button disbl_href_action"
                      id="delivery-continue-link"
                      href="/"
                     // onClick={this.selectDlyOutlet.bind(this)}
                    >
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
</section>
        
    );
  }
}

const mapStateTopProps = (state) => {

  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {  
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Outletoptions));
