/* eslint-disable */
import React, { Component } from "react";

import { Link } from "react-router-dom";

import Slider from "react-slick";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import banner from "../../common/images/products-bc.jpg";
import moment from "moment";

var Parser = require("html-react-parser");
var dateFormat = require("dateformat");
var base64 = require("base-64");

import { setMinutes, setHours, format } from "date-fns";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
appId,
apiUrl,
apiUrlV2,
deliveryId,
pickupId,
cateringId,
CountryTxt,
stripeReference,
stripeCurrency,
// stripelogo,
stripeCompany,
dineinId,
smsCharge,
stripeImage,
} from "../Helpers/Config";

import {
getReferenceID,
showPriceValue,
callImage,
showLoader,
hideLoader,
getCalculatedAmount,
showAlert,
showCustomAlert,
showCustomCenterAlert,
getOrderDateTime,
getPromoCkValue,
removePromoCkValue,
smoothScroll,
removeOrderDateTime,
addressFormat,
timeToConv12,
stripslashes
} from "../Helpers/SettingHelper";

import { GET_GLOBAL_SETTINGS, GET_REQUESTPAGEDATA, DESTROY_BOOKING_CART_DETAIL } from "../../actions";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import pageNotFnd from "../../common/images/404.png";
import cookie from "react-cookies";
import axios from "axios";
import StripeCheckout from "react-stripe-checkout";
import stripelogo from "../../common/images/stripe_logo.png";
import loadingImage from "../../common/images/loading_popup.gif";
import tickImage from "../../common/images/tick_popup.png";
import paymentImage from "../../common/images/payment.png";
import paymentoffdel from "../../common/images/offline-delivery.png";
import paymentpypllogo from "../../common/images/paypal-logo.png";
import paymentomise from "../../common/images/omise.png";
import paymentPayNowImage from "../../common/images/omise-paynow.png";
import hiHndImage from "../../common/images/hi.png";
import trashImg from "../../common/images/trash-bin.png";
import crossImg from "../../common/images/cross1.png";
import qrcode from "../../common/images/qrcode.png";
import qrcodeTwo from "../../common/images/qr-code.jpg";
var qs = require("qs");
var startTimePay;

class Booking extends Component {
  constructor(props) {
    super(props);
const start = moment();
const remainder = 15 - (start.minute() % 15);
const currentTime = moment(start).add(remainder, "minutes");

var CurrentDate = new Date();

var avilablityId =
  cookie.load("defaultAvilablityId") != "" &&
  cookie.load("defaultAvilablityId") != undefined
    ? cookie.load("defaultAvilablityId")
    : "";
var orderOutletId =
  cookie.load("orderOutletId") != "" &&
  cookie.load("orderOutletId") != undefined
    ? cookie.load("orderOutletId")
    : "";
var orderPostalCode =
  typeof cookie.load("orderPostalCode") === "undefined"
    ? ""
    : cookie.load("orderPostalCode");
var orderDeliveryAddress =
  typeof cookie.load("orderDeliveryAddress") === "undefined"
    ? ""
    : cookie.load("orderDeliveryAddress");
var unitNoOne =
  typeof cookie.load("unitNoOne") === "undefined"
    ? ""
    : cookie.load("unitNoOne");
var unitNoTwo =
  typeof cookie.load("unitNoTwo") === "undefined"
    ? ""
    : cookie.load("unitNoTwo");

var billingPostalCode =
  typeof cookie.load("billingPostalCode") === "undefined"
    ? ""
    : cookie.load("billingPostalCode");
var billingDeliveryAddress =
  typeof cookie.load("billingDeliveryAddress") === "undefined"
    ? ""
    : cookie.load("billingDeliveryAddress");
var billunitNoOne =
  typeof cookie.load("billunitNoOne") === "undefined"
    ? ""
    : cookie.load("billunitNoOne");
var billunitNoTwo =
  typeof cookie.load("billunitNoTwo") === "undefined"
    ? ""
    : cookie.load("billunitNoTwo");
cookie.save("mainOutletId", '347', {
                path: "/",
              });

this.state = {
  globalSettings: [],
  Maxdate: '',
  staticblacks: [],
  overAllcart: [],
  cartItems: [],
  cartDetails: [],
  cartStatus: "",
  cartTotalItmCount: 0,
  order_postcode: "",
  order_delivery_address: "",
  unitnumber1: "",
  unitnumber2: "",
  billing_postcode: "",
  billing_delivery_address: "",
  billunitnumber1: "",
  billunitnumber2: "",
  billing_addrs_sameas: "no",
  order_specil_note: "",
  getDateTimeFlg: "",
  seleted_ord_date: typeof cookie.load("seleted_ord_date") === "undefined"
    ? ""
    : cookie.load("seleted_ord_date"),
  seleted_ord_slot_str: typeof cookie.load("seleted_ord_slot_str") === "undefined"
    ? ""
    : cookie.load("seleted_ord_slot_str"),
  seleted_ord_slot_end: typeof cookie.load("seleted_ord_slot_end") === "undefined"
    ? ""
    : cookie.load("seleted_ord_slot_end"),
  seleted_ord_slotTxt: typeof cookie.load("seleted_ord_slotTxt") === "undefined"
    ? ""
    : cookie.load("seleted_ord_slotTxt"),
  activitycount_arr: [],
  promotion_count: 0,
  reward_point_count: 0,
  orderDateTmTxt: '',
  paymentmodevalue: "Stripe",
  validateimage: '',
  placingorderimage: '',
  completingpaymentimage: '',
  orderValidFail: 0,
  cod_payment_enable: 0,
  stripe_payment_enable: 0,
  postorder_triggered: "no",
  chk_tarms_contn: "No",
  termsAndConditions: "",
  seletedAvilablityId: '',
  seletedOutletId: cookie.load("mainOutletId"),
  order_tat_time: '',
  viewProductSlug: "",
  cust_birthdate_update: "no",
  cust_birthdate: "",
  cust_gender: "",
  omise_payment_enable: 0,
  omise_log_id: "",
  omisecodeerror: "",
  omiseyearerror: "",
  omisemontherror: "",
  omisecardrror: "",
  omisenameerror: "",
  holdername: "",
  cardNumber: "",
  expiration_month: "",
  expiration_year: "",
  security_code: "",
  cardImage: "",
  payment_ref_id: "",
  omise_tokken_response: "",
  omise_tokken_card_id: "",
  omise_tokken_id: "",
  delivery_self_collection: 0,
  umberella_number:'',
  isumbrellavalidate:'no',
  sms_notification: 0,
  email_notification: 0,
  cart_grand_total: 0,
  outletsubcharge:[],
  omisePayNowResponse: [],
  paypaymentType: "",
  bookingCartTriggerFlg: "No",
};


    this.props.getSettings();
  }

  componentDidMount() {
    this.getCartItems()


  const script = document.createElement("script");
  script.src = "https://cdn.omise.co/omise.js";
  script.async = true;
  document.body.appendChild(script);

  smoothScroll(100, 70);


  }

componentDidUpdate() {
this.checkOutAuthentication();
var modevalue = this.state.paymentmodevalue;

var cartDetailsArr = this.state.cartDetails;
var settingsArr = this.state.globalSettings;
if (
  cookie.load("UserMobile") != "" &&
  cookie.load("UserMobile") != undefined &&
  Object.keys(cartDetailsArr).length > 0 &&
  Object.keys(settingsArr).length > 0 &&
  this.state.postorder_triggered === "no"
) {
  this.setState(
    { postorder_triggered: "yes", getDateTimeFlg: "yes" },
    function () {
    }.bind(this)
  );
}

}

checkOutAuthentication() {


var settingsArr = this.state.globalSettings;

var UserId =
  cookie.load("UserId") != "" && cookie.load("UserId") != undefined
    ? cookie.load("UserId")
    : "";
if (UserId === "") {
  cookie.save("loginpopupTrigger", "Yes", { path: "/" });
  this.props.history.push("/");
  return false;
}

var UserMobile =
  cookie.load("UserMobile") != "" && cookie.load("UserMobile") != undefined
    ? cookie.load("UserMobile")
    : "";
if (UserId !== "" && UserMobile === "") {
  showAlert("Error", "Please update your mobile no.");
  $.magnificPopup.open({
    items: {
      src: ".alert_popup",
    },
    type: "inline",
  });
  this.props.history.push("/myaccount");
  return false;
}
}


componentWillReceiveProps(nextProps) {
$("body").removeClass("cart-items-open");
$(".hcartdd_trigger").removeClass("active");
$(".hcart_dropdown").removeClass("open");

if (this.state.globalSettings !== nextProps.settingsArr) {
  var tampStArr = nextProps.settingsArr;
  var paymentmode = "Stripe";
  var codPayment = 0;
  var stripePayment = 0;
  var omisePayment = 0;
  var omisePaynowPayment = 0;
  if (Object.keys(tampStArr).length > 0) {
    if (
      tampStArr.client_cod_enable == 1 &&
      tampStArr.client_cod_availability == 1
    ) {
      codPayment = 1;
      paymentmode = "Cash";
    }

    if (
      tampStArr.client_stripe_enable == 1 &&
      tampStArr.client_stripe_availability == 1
    ) {
      stripePayment = 1;
    }

    if (
      tampStArr.client_omise_enable == 1 &&
      tampStArr.client_omise_availability == 1
    ) {
      omisePayment = 1;
      if (paymentmode !== "Cash") {
        paymentmode = "Omise";
      }
    }

    if (
      tampStArr.client_enable_omise_paynow == 1 &&
      tampStArr.client_omisepaynow_availability == 1
    ) {
      omisePaynowPayment = 1;
      if (paymentmode !== "Cash" && paymentmode !== "Omise") {
        paymentmode = "OmisePayNow";
      }
    }

  }

  this.setState({
    globalSettings: nextProps.settingsArr,
    cod_payment_enable: codPayment,
    stripe_payment_enable: stripePayment,
    omise_payment_enable: omisePayment,
    omisepaynow_payment_enable: omisePaynowPayment,
    paymentmodevalue: paymentmode,
  });
}

if (this.state.activitycount_arr !== nextProps.activitycountArr) {
  this.setState({
    activitycount_arr: nextProps.activitycountArr,
  });
}


if (nextProps.staticblack !== this.state.staticblacks) {
  var termsConditions = "";
  if (Object.keys(nextProps.staticblack).length > 0) {
    nextProps.staticblack.map((data, index) => {
      if (data.staticblocks_slug === "terms-and-conditions") {
        termsConditions = data.staticblocks_description;
        return "";
      }
    });
  }
  termsConditions =
    termsConditions !== "" ? Parser(termsConditions) : termsConditions;
  this.setState({
    staticblacks: nextProps.staticblack,
    termsAndConditions: termsConditions,
  });

  setTimeout(function () {
    $(".checkout-terms-and-condition").mCustomScrollbar();
  }, 800);
}
}

payCash(event) {
event.preventDefault();
$.magnificPopup.open({
  items: {
    src: ".processing",
  },
  type: "inline",
  showCloseBtn: false,
  midClick: true,
  closeOnBgClick: false,
});

this.postOrder(1);
}


changeEmailChk() {
var isSame = this.state.email_notification;
if (isSame == 0) {
  this.setState({
    email_notification: 1,
  });
} else {
  this.setState(
    { email_notification: 0 },
  );
}
}

changeSMSChk() {
var isSame = this.state.sms_notification;
if (isSame == 0) {
  let smch = parseFloat(this.state.cartDetails.cart_grand_total)+parseFloat(smsCharge);
  let cartdetails = this.state.cartDetails;
  cartdetails['cart_grand_total'] = parseFloat(smch).toFixed(2);
  this.setState({sms_notification: 1,cartDetails:cartdetails,smsCharge:1});
} else {
  let smch = parseFloat(this.state.cartDetails.cart_grand_total)-parseFloat(smsCharge);
  let cartdetails = this.state.cartDetails;
  cartdetails['cart_grand_total'] = parseFloat(smch).toFixed(2);
  this.setState({sms_notification: 0,cartDetails:cartdetails,smsCharge:0});
}
}



/* show online payment mode loading */
onlinePaymentLoading() {

var grandTotal = parseFloat(this.state.cartDetails.cart_grand_total);

if (this.state.paymentmodevalue !== "Cash" && grandTotal > 0) {
  return (
    <div className="process_col">
      <div className="process_left">
        <img src={this.state.validateimage} />
      </div>
      <div className="process_right">
        <h5>Processing</h5>
        <p>Validating card information.</p>
      </div>
    </div>
  );
}
}
/**/

/* show online payment mode loading */
orderBCLoading() {
return (
  <div className="process_col">
    <div className="process_left">
      <img src={this.state.placingorderimage} />
    </div>
    <div className="process_right">
      <h5>Processing</h5>
      <p>Placing your order now.</p>
    </div>
  </div>
);
}
/**/

/* show online payment mode loading */
amountCaptureLoading() {

var grandTotal = parseFloat(this.state.cartDetails.cart_grand_total);

if (this.state.paymentmodevalue !== "Cash" && grandTotal > 0) {
  return (
    <div className="process_col">
      <div className="process_left">
        <img src={this.state.completingpaymentimage} />
      </div>
      <div className="process_right">
        <h5>Processing</h5>
        <p>Completing your online payment.</p>
      </div>
    </div>
  );
}
}
/**/



postOrder(paymentMode,validation = "No",captureID = "",payGetWayType = "",Paymentpop = "No") 
{
    if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
        cookie.save("loginpopupTrigger", "Yes", { path: "/" });
        this.props.history.push("/");
        return false;
    }


    if (
        paymentMode !== "initial"
    ) {
        var unitNoOne = this.state.unitnumber1;
        var unitNoTwo = this.state.unitnumber2;
        var billingAddrsSameas = this.state.billing_addrs_sameas;
        var billingPostcode = this.state.billing_postcode;
        var billingAddrss = this.state.billing_delivery_address;
        if (
            billingAddrsSameas === "no" &&
            (billingPostcode === "" || billingAddrss === "")
        ) {
            showAlert("Error", "Please enter default billing address.");
            $.magnificPopup.open({
                items: {
                    src: ".alert_popup",
                },
                    type: "inline",
            });
            return false;
        }
    }
    var products = {};
	    products = {cart_items: this.state.cartItems, outlet_id: cookie.load('mainOutletId')};
	
    /* if merge order date */
    var orderDate = "";
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_slot_str;
    if (seletedOrdDate !== "" && seletedOrdTime !== "") {
        orderDate = seletedOrdDate + " " + seletedOrdTime;
    }
    if (orderDate === "" && paymentMode !== "initial") {
        showAlert("Error", "Please select order date and time.");
        $.magnificPopup.open({
            items: {
                src: ".alert_popup",
            },
                type: "inline",
        });
        return false;
    }
    if (
        this.state.chk_tarms_contn !== "Yes" &&
        this.state.termsAndConditions !== "" &&
        paymentMode !== "initial"
    ) {
        $(".tarms_chkbox_div").addClass("err_tarms_chk");
        window.scrollTo(0, 1000);
        return false;
    }
    if (paymentMode === "initial") {
        paymentMode = 2;
    }

    /* insert customner details */
    if (validation !== "Yes") {
        var addressObject = {};
        var customerAddressId = 0;
        addressObject = {
            app_id: appId,
            customer_first_name: cookie.load("UserFname"),
            customer_last_name: cookie.load("UserLname"),
            customer_phone: cookie.load("UserMobile"),
            customer_email: cookie.load("UserEmail"),
            customer_address_line1: cookie.load("orderDeliveryAddress"),
            customer_postal_code: cookie.load("orderPostalCode"),
            customer_address_name: this.state.unitnumber1,
            customer_address_name2: this.state.unitnumber2,
            refrence: cookie.load("UserId"),
            customer_status: "A",
            customer_order_status: "order",
        };
    }

    var BillingAddress = this.state.billing_delivery_address;
    var BillingPostalCode = this.state.billing_postcode;
    var BillingUnitNo1 = this.state.billunitnumber1;
    var BillingUnitNo2 = this.state.billunitnumber2;
    if (this.state.billing_addrs_sameas === "yes") {
        BillingAddress = cookie.load("orderDeliveryAddress");
        BillingPostalCode = cookie.load("orderPostalCode");
        BillingUnitNo1 = this.state.unitnumber1;
        BillingUnitNo2 = this.state.unitnumber2;
    }
    var custBirthdateUpdate = "",
    custBirthdate = "",
    custGender = "";

    var postObject = {};

    postObject = {
        app_id: appId,
        sub_total: parseFloat(this.state.cartDetails.cart_sub_total).toFixed(2),
        grand_total: parseFloat(this.state.cartDetails.cart_grand_total).toFixed(2),
        order_status: 1,
        order_source: "Web",
        order_date: orderDate,
        products: JSON.stringify(products),
        order_remarks: this.state.order_specil_note,
        order_email_notification: this.state.email_notification,
        order_sms_notification: this.state.sms_notification,

        /* customer  Details */
        customer_id: cookie.load("UserId"),
        customer_address_id: customerAddressId,
        customer_fname: cookie.load("UserFname"),
        customer_lname: cookie.load("UserLname"),
        customer_mobile_no: cookie.load("UserMobile"),
        customer_email: cookie.load("UserEmail"),
        customer_address_line1: cookie.load("orderDeliveryAddress"),
        customer_address_line2: "",
        customer_postal_code: cookie.load("orderPostalCode"),
        customer_unit_no1: this.state.unitnumber1,
        customer_unit_no2: this.state.unitnumber2,
        customer_birthdate_update: custBirthdateUpdate,
        customer_birthdate: custBirthdate,
        customer_gender: custGender,
        billing_address_line1: BillingAddress,
        billing_postal_code: BillingPostalCode,
        billing_unit_no1: BillingUnitNo1,
        billing_unit_no2: BillingUnitNo2,

        /* Payment */
        payment_mode: paymentMode,
        payment_reference: stripeReference,
        stripe_envir: "test",
        payment_type:
          payGetWayType === "OmisePayNow" ? "Omise PayNow" : payGetWayType,
        payment_getway_details:
          payGetWayType === "OmisePayNow"
            ? { payment_type: "Omise PayNow", payment_status: "Success" }
            : "",
        order_payment_retrieved:
          payGetWayType === "OmisePayNow" && captureID !== "" ? "Yes" : "No",
        omise_log_id:
        payGetWayType === "OmisePayNow" && captureID !== ""
          ? this.state.omise_log_id
          : "",
        payment_type: payGetWayType,
        order_capture_token: captureID,
        order_payment_getway_type: "",
        order_payment_getway_status: "Yes",

        /* additional paras */
        delivery_charge: 0,
        additional_delivery: 0,
        order_tat_time:
        typeof cookie.load("orderTAT") === "undefined"
            ? ""
            : cookie.load("orderTAT"),
        tax_charge: 0,
        order_data_encode: "Yes",
        /* discount */


    };
    /* check  validation */

    if (validation === "Yes") {
        postObject["validation_only"] = "Yes";
    }
	
	postObject["multiple_booking"] = "Yes";
	
    // console.log(postObject);return false;
    axios.post(apiUrl + "bookings/submit_order", qs.stringify(postObject))
        .then((res) => {
            if (res.data.status === "ok") {
                console.log(validation)
                this.setState({ orderValidFail: 0 });
                console.log(payGetWayType)
                console.log(Paymentpop)
                if (validation !== "Yes") {
                    var localOrderNo = res.data.common.local_order_no;
                    this.setState({ placingorderimage: tickImage });
                    /* capture payemnt */
                    if (payGetWayType === "Stripe") {
                        this.captureAmount(
                            captureID,
                            res.data.common.order_primary_id,
                            localOrderNo
                        );
                    } else if (payGetWayType === "Omise") {
                        this.captureOmiseAmount(
                            captureID,
                            res.data.common.order_primary_id,
                            localOrderNo
                        );
                    } else if (payGetWayType === "OmisePayNow") {
                      this.showSuccessPage(
                        localOrderNo,
                        res.data.common.order_primary_id
                      );
                    } else {
                        this.showSuccessPage(
                            localOrderNo,
                            res.data.common.order_primary_id
                        );
                    }
                } else {
                    if (Paymentpop === "Yes") {
                      if (payGetWayType == "OmisePayNow") {
                        this.createOmiseSource();
                      } else {

                        var popupIdTxt =
                            payGetWayType === "Omise"
                            ? "#pay-omiseconf-popup"
                            : "#pay-conf-popup";
                        $.magnificPopup.open({
                            items: {
                                src: popupIdTxt,
                            },
                                type: "inline",
                            closeOnBgClick: false,
                        });
                      }
                    }
                    console.log("validation only");
                    return true;
                }
            } else if (res.data.status === "error") {
                // This is used for stripe Auth Capture
                this.setState({ orderValidFail: 1 });
                if (res.data.form_error !== undefined) {
                    showAlert("Error", res.data.form_error);
                } else {
                    showAlert("Error", res.data.message);
                }
                $.magnificPopup.open({
                    items: {
                        src: ".alert_popup",
                    },
                        type: "inline",
                });
            }
        })
        .catch((error) => {
            $.magnificPopup.close();
            this.paymentFail(
                "Error",
                "Error code: 1004 Oops! Unable to processing your order. Please try again."
            );
            $.magnificPopup.open({
                items: {
                    src: ".warining_popup",
                },
                    type: "inline",
            });
            return false;
        });
}

  createOmiseSource() {
    if (
      this.state.globalSettings.omise_paynow_public !== "" &&
      typeof this.state.globalSettings.omise_paynow_public !== undefined &&
      typeof this.state.globalSettings.omise_paynow_public !== "undefined"
    ) {
      var payNowID = base64.decode(
        this.state.globalSettings.omise_paynow_public
      );
      Omise.setPublicKey(payNowID);
      var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;

      showLoader("omisepaynow_btn", "Idtext");
      var payAmount =
        parseFloat(this.state.cartDetails.cart_grand_total).toFixed(2) * 100;
      var currentThis = this;
      Omise.createSource(
        "paynow",
        {
          amount: parseInt(payAmount),
          currency: "SGD",
        },
        function (statusCode, response) {
          if (response.id !== "" && response.id !== null) {
            currentThis.paynowCapture(response.id, response.amount);
          } else {
            hideLoader("omisepaynow_btn", "Idtext");
          }
          console.log(statusCode, response, "response");
        }
      );
    }
  }

  paynowCapture(sourceID, amount) {
    if (sourceID !== "") {
      var omisePostObject = {};
      var currentThis = this;
      omisePostObject = {
        app_id: appId,
        source_id: sourceID,
        customer_id: cookie.load("UserId"),
        outlet_id: cookie.load("orderOutletId"),
        amount: amount,
      };
      axios
        .post(apiUrl + "paymentv1/paynowCapture", qs.stringify(omisePostObject))
        .then((res) => {
          hideLoader("omisepaynow_btn", "Idtext");
          if (res.data.status === "ok") {
            this.setState(
              {
                omisePayNowResponse: res.data.result_set,
                omisepaynowAmount: amount / 100,
                omisePaynowTime: 600,
              },
              function () {
                $.magnificPopup.open({
                  items: {
                    src: "#pay-omisepaynowconf-popup",
                  },
                  type: "inline",
                  midClick: true,
                  closeOnBgClick: false,
                  callbacks: {
                    close: function () {
                      clearInterval(startTimePay);
                    },
                  },
                });
                this.omisePaynowTimeReduce();
                startTimePay = setInterval(function () {
                  var modTime = currentThis.state.omisePaynowTime % 3;
                  if (currentThis.state.omisePaynowTime > 1) {
                    if (modTime === 0) {
                      currentThis.checkOmisePayNowPamentStatus(startTimePay);
                    }
                    currentThis.omisePaynowTimeReduce();
                  } else {
                    clearInterval(startTimePay);
                    $.magnificPopup.close();
                    currentThis.paymentFail(
                      "Error",
                      "Error code: 1002 Oops! Unable to processing your order. Please try again."
                    );
                    $.magnificPopup.open({
                      items: {
                        src: ".warining_popup",
                      },
                      type: "inline",
                    });
                  }
                }, 1000);
              }
            );
          } else {
            var erromessage =
              res.data.message !== "" &&
              typeof res.data.message !== undefined &&
              typeof res.data.message !== "undefined"
                ? res.data.message
                : "Error code: 1001 Oops! Unable to processing your order. Please try again.";
            this.paymentFail("Error", erromessage);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  omisePaynowTimeReduce() {
    var omisePaynowTime = parseInt(this.state.omisePaynowTime) - 1;
    this.setState({ omisePaynowTime: omisePaynowTime });
  }

  checkOmisePayNowPamentStatus(startTimePay) {
    if (Object.keys(this.state.omisePayNowResponse).length > 0) {
      var statusObj = {};
      statusObj = {
        app_id: appId,
        customer_id: cookie.load("UserId"),
        outlet_id: cookie.load("orderOutletId"),
        capture_id: this.state.omisePayNowResponse.captureId,
      };
      axios
        .post(apiUrl + "paymentv1/checkPaynowStatus", qs.stringify(statusObj))
        .then((statusRes) => {
          if (statusRes.data.status === "ok") {
            if (statusRes.data.result_set.omise_paynow_status) {
              clearInterval(startTimePay);
              var captureID = statusRes.data.result_set.charge_id;

              this.setState(
                {
                  validateimage: tickImage,
                  payNowCaptureId: captureID,
                  omise_log_id: statusRes.data.result_set.omiseLogId,
                },
                function () {
                  this.postOrder(11, "", captureID, "OmisePayNow");
                }.bind(this)
              );
            } else {
            }
          } else {
            clearInterval(startTimePay);
            var erromessage =
              statusRes.data.message !== "" &&
              typeof statusRes.data.message !== undefined &&
              typeof statusRes.data.message !== "undefined"
                ? statusRes.data.message
                : "Error code: 1001 Oops! Unable to processing your order. Please try again.";
            this.paymentFail("Error", erromessage);
            $.magnificPopup.close();
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        });
    }
  }

pageReload = () => {
setTimeout(function () {
  window.location.reload();
}, 15000);
};

paymentFail(header, message) {
$(".warining_popup").remove();
$("body").append(
  '<div class="white-popup mfp-hide popup_sec warining_popup"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
    header +
    '</div><div class="alert_body"><p>' +
    message +
    '</p><div class="alt_btns"><a href="javascript:void(0);" onClick="' +
    this.pageReload() +
    '"class="button btn-sm btn_yellow popup-modal-dismiss">OK</a></div></div></div></div></div></div>'
);
}

/* Capture amount */
captureAmount(captureID, orderPrimaryId, localOrderNo) {
var cabtureObjects = {};
var captureParams = captureID + "~" + orderPrimaryId + "~" + localOrderNo;
cabtureObjects = {
  payment_reference: stripeReference,
  stripe_envir: this.state.globalSettings.stripe_envir,
  customer_id: cookie.load("UserId"),
  app_id: appId,
  token: captureID,
  order_id: orderPrimaryId,
};
axios
  .post(apiUrl + "payment/captureAmount", qs.stringify(cabtureObjects))
  .then((captureRes) => {
    if (captureRes.data.status === "ok") {
      this.setState({ completingpaymentimage: tickImage });

      this.showSuccessPage(localOrderNo, orderPrimaryId);
    } else if (captureRes.data.status === "error") {
      showStripePopup("Error", "Your order was not successful.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    } else {
      showStripePopup("Error", "Your order was not successful.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  })
  .catch((error) => {});
}

/* Omise Capture Payment */
captureOmiseAmount(captureID, orderPrimaryId, localOrderNo) {
var cabtureObjects = {};
cabtureObjects = {
  payment_reference: stripeReference,
  customer_id: cookie.load("UserId"),
  app_id: appId,
  token: captureID,
  order_id: orderPrimaryId,
  log_id: this.state.omise_log_id,
  outlet_id: cookie.load("orderOutletId"),
};
axios
  .post(
    apiUrl + "bookings/captureAmountOmise",
    qs.stringify(cabtureObjects)
  )
  .then((captureRes) => {
    if (captureRes.data.status === "ok") {
      this.setState({ completingpaymentimage: tickImage });

      this.setState(
        { stop_authentication: "yes" },
        function () {
          this.showSuccessPage(localOrderNo);
        }.bind(this)
      );
    } else if (captureRes.data.status === "pending") {
      this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
    } else if (captureRes.data.status === "error") {
      var magnfPopup = $.magnificPopup.instance;
      var omsMsgTxt =
        captureRes.data.message != ""
          ? captureRes.data.message
          : "Your order was not successful.";
      showAlert("Error", omsMsgTxt, magnfPopup);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    } else {
      /*var magnfPopup = $.magnificPopup.instance;
    showAlert('Error', 'Your order was not successful.', magnfPopup);
            $.magnificPopup.open({
                items: {
                    src: '.alert_popup'
                },
                type: 'inline'
            });*/

      /*Get Status of Charge ID*/
      this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
    }
  })
  .catch((error) => {
    this.captureOmiseAmount(captureID, orderPrimaryId, localOrderNo);
  });
}

retrieve_charge_details = (captureID, localOrderNo, orderPrimaryId) => {
var orderOutlet_Id = cookie.load("orderOutletId");
var postObject = {};
postObject = {
  charge_id: captureID,
  app_id: appId,
  order_primary_id: orderPrimaryId,
  outlet_id: orderOutlet_Id,
};

axios
  .post(
    apiUrl + "paymentv1/retrieve_charge_details",
    qs.stringify(postObject)
  )
  .then((res) => {
    if (
      res.data.status === "ok" &&
      res.data.payment_status === "successful"
    ) {
      this.setState(
        { stop_authentication: "yes" },
        function () {
          this.showSuccessPage(localOrderNo);
        }.bind(this)
      );
    } else if (
      res.data.status === "ok" &&
      res.data.payment_status === "pending"
    ) {
      this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
    } else {
      var magnfPopup = $.magnificPopup.instance;
      var msgTxt =
        res.data.message != ""
          ? res.data.message
          : "Your order was not successful.";
      showAlert("Error", msgTxt, magnfPopup);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  });
};

/* sucess page */
showSuccessPage(localOrderNo, orderPrimaryId) {
// this.props.destroyCartDetail();
this.deleteOrderCookie();
/*this.sendMailFun(orderPrimaryId);*/
cookie.save("ChkOrderid", localOrderNo, { path: "/" });
setTimeout(function(){
window.location.href = "bookingthankyou/" + localOrderNo;
},500);
}


/* this function used to  delete all cookie values */
deleteOrderCookie() {
removePromoCkValue();

cookie.remove("orderPaymentMode", { path: "/" });
cookie.remove("orderTableNo", { path: "/" });
cookie.remove("product_remarks", { path: "/" });
cookie.remove("orderOutletName", { path: "/" });
cookie.remove("orderOutletId", { path: "/" });
cookie.remove("carttotalitems", { path: "/" });
cookie.remove("cartsubtotal", { path: "/" });
cookie.remove("cartid", { path: "/" });

/* Delivery avilablity */
cookie.remove("deliveryDate", { path: "/" });
cookie.remove("deliveryTime", { path: "/" });
cookie.remove("unitNoOne", { path: "/" });
cookie.remove("unitNoTwo", { path: "/" });
cookie.remove("firstNavigation", { path: "/" });

cookie.remove("promotion_id", { path: "/" });
cookie.remove("promotion_applied", { path: "/" });
cookie.remove("promotion_code", { path: "/" });
cookie.remove("promotion_delivery_charge_applied", { path: "/" });
cookie.remove("promotion_amount", { path: "/" });
cookie.remove("promotion_category", { path: "/" });
cookie.remove("prmo_type", { path: "/" });

}

  choosePayment(paymentMode, paypaymentType) {
    if (paymentMode === "Cash") {
      this.setState({ paymentmodevalue: "Cash" });
    } else if (paymentMode === "Stripe") {
      this.setState({ paymentmodevalue: "Stripe" });
    } else if (paymentMode === "Omise") {
      this.setState({ paymentmodevalue: "Omise" });
    } else if (paymentMode === "OmisePayNow") {
      this.setState({ paymentmodevalue: "OmisePayNow" });
    } else {
      this.setState({ paymentmodevalue: "promotion" });
    }
    this.setState({ paypaymentType: paypaymentType });

    this.postOrder(2, "Yes");
  }

/* stripe description  */
stripeDescription() {
//userName
if (
  typeof cookie.load("UserLname") !== "undefined" &&
  cookie.load("UserLname") !== ""
) {
  return cookie.load("UserFname") + " " + cookie.load("UserLname");
} else {
  return cookie.load("UserLname");
}
}

/* post stripe account */
onToken = (token) => {
$.magnificPopup.open({
  items: {
    src: ".processing",
  },
  type: "inline",
  midClick: true,
  closeOnBgClick: false,
});




var payAmount = parseFloat(this.state.cartDetails.cart_grand_total).toFixed(2);

/*  load process html */
var postObject = {};
postObject = {
  app_id: appId,
  token: token.id,
  stripe_envir: this.state.globalSettings.stripe_envir,
  stripe_key: this.state.globalSettings.stripe_public_key,
  customer_id: cookie.load("UserId"),
  paid_amount: payAmount,
  outlet_name:
    typeof cookie.load("orderOutletName") === "undefined"
      ? cookie.load("orderOutletName")
      : "",
  payment_reference: stripeReference,
};

this.postOrder(2, "Yes"); // To validate the order

if (this.state.orderValidFail === 0) {
  axios
    .post(apiUrl + "payment/stripeTokenPay", qs.stringify(postObject))
    .then((res) => {
      if (res.data.status === "ok") {
        var captureID = res.data.result_set.payment_reference_1;
        this.setState({ validateimage: tickImage });
        this.postOrder(3, "", captureID, "Stripe");
      } else if (res.data.status === "error") {
        $.magnificPopup.close();
        this.paymentFail(
          "Error",
          "Error code: 1001 Oops! Something went wrong! Please try again."
        );
        $.magnificPopup.open({
          items: {
            src: ".warining_popup",
          },
          type: "inline",
        });
        return false;
      }
    })
    .catch((error) => {
      $.magnificPopup.close();
      this.paymentFail(
        "Error",
        "Error code: 1002 Oops! Unable to connect to server. Please try again."
      );
      $.magnificPopup.open({
        items: {
          src: ".warining_popup",
        },
        type: "inline",
      });
      return false;
    });
} else {
  $.magnificPopup.close();
  this.paymentFail(
    "Error",
    "Error code:1003 Oops! Something went wrong! Please try again."
  );
  $.magnificPopup.open({
    items: {
      src: ".warining_popup",
    },
    type: "inline",
  });

  return false;
}
};

orderPayValidaion(payGetWayType, event) {
event.preventDefault();
this.postOrder(2, "Yes", "", payGetWayType, "Yes");
}



/* Omise Payment Gateway Start */
handleChangeTxt = (item, event) => {
const re = /^[0-9 \b]+$/;
if (item == "cardNumber") {
  if (event.target.value === "" || re.test(event.target.value)) {
    var cardnumber = this.space(event.target.value);
    var cardnumberLenght = cardnumber.length;
    if (cardnumberLenght <= 19) {
      this.setState({ [item]: cardnumber });
      this.cardValidation(cardnumber);
    }
  }
} else if (
  item == "expiration_month" ||
  item == "expiration_year" ||
  item == "security_code"
) {
  if (event.target.value === "" || re.test(event.target.value)) {
    this.setState({ [item]: event.target.value });
  }
} else {
  this.setState({ [item]: event.target.value });
}

this.setState({
  omisenameerror: "",
  omisecardrror: "",
  omisemontherror: "",
  omiseyearerror: "",
  omisecodeerror: "",
});
};

space(el) {
var numbes = el.replace(/ /g, "");
return numbes.replace(/(\d{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
}

cardValidation(carnumber) {
var imagename = "";
if (carnumber != "") {
  var single = carnumber.substring(0, 1);
  var double = carnumber.substring(0, 2);

  if (single == 4) {
    imagename = "visa";
  } else if (double == 34 || double == 37) {
    imagename = "american";
  } else if (double >= 51 && double <= 55) {
    imagename = "master";
  } else if (double == 60 || double == 64 || double == 65) {
    imagename = "discover";
  }
}

this.setState({ cardImage: imagename });
}

getOmiseToken() {
var errorFlg = 0,
  omisenameerror = "",
  omisecardrror = "",
  omisemontherror = "",
  omiseyearerror = "",
  omisecodeerror = "";
if (this.state.holdername == "") {
  errorFlg++;
  omisenameerror = "omise-form-error";
}
if (this.state.cardNumber == "") {
  errorFlg++;
  omisecardrror = "omise-form-error";
}
if (this.state.expiration_month == "") {
  errorFlg++;
  omisemontherror = "omise-form-error";
}
if (this.state.expiration_year == "") {
  errorFlg++;
  omiseyearerror = "omise-form-error";
}
if (this.state.security_code == "") {
  errorFlg++;
  omisecodeerror = "omise-form-error";
}

this.setState({
  omisenameerror: omisenameerror,
  omisecardrror: omisecardrror,
  omisemontherror: omisemontherror,
  omiseyearerror: omiseyearerror,
  omisecodeerror: omisecodeerror,
});

if (errorFlg === 0) {
  showLoader("omise-pay-btn", "class");
  Omise.setPublicKey(this.state.globalSettings.omise_public_key);
  var current = this;
  var card = {
    name: this.state.holdername,
    number: this.state.cardNumber,
    expiration_month: this.state.expiration_month,
    expiration_year: this.state.expiration_year,
    security_code: this.state.security_code,
    livemode: false,
  };
  Omise.createToken("card", card, function (statusCode, response) {
    hideLoader("omise-pay-btn", "class");
    if (statusCode === 200) {
      if (
        response.object == "error" ||
        !response.card.security_code_check
      ) {
        var msgArr = Array();
        msgArr["message"] =
          response.object == "error"
            ? response.message
            : "Invalid card details.";
        current.showOmiselert(msgArr);
      } else {
        $.magnificPopup.close();
        /*current.onProcessOmiseToken(response);*/
        current.setState(
          {
            omise_tokken_response: response,
            omise_tokken_card_id: response.card.id,
            omise_tokken_id: response.id,
          },
          () => {
            current.onProcessOmiseToken(response);
          }
        );
      }
    } else {
      var msgArr = Array();
      msgArr["message"] =
        response.message !== ""
          ? response.message
          : "Sorry!. Error from omise.";
      current.showOmiselert(msgArr);
    }
  });
} else {
  return false;
}
}

showOmiselert(msgArr) {
var dataTimeoutId = $(".omise-error-info-div").attr("data-timeoutid");
clearTimeout(dataTimeoutId);
$(".omise-error-info-div").hide();
$(".omise-error-msg").html(msgArr["message"]);
$("#omise-error-info-div").fadeIn();
var timeoutid = setTimeout(function () {
  $(".omise-error-info-div").hide();
}, 6000);
$(".omise-error-info-div").attr("data-timeoutid", timeoutid);
}

/* post stripe account */
onProcessOmiseToken = (token) => {
$.magnificPopup.open({
  items: {
    src: ".processing",
  },
  type: "inline",
  showCloseBtn: false,
  midClick: true,
  closeOnBgClick: false,
});

var payAmount = parseFloat(this.state.cartDetails.cart_grand_total).toFixed(2);
var requestType =
  "Grainandco-" +
  cookie.load("orderOutletId") +
  "-" +
  cookie.load("UserId");

axios
  .get(apiUrl + "paymentv1/getPaymentReferenceId?app_id=" + appId)
  .then((topRes) => {
    if (topRes.data.status === "ok") {
      var paymentRef = topRes.data.payment_ref_id;
      this.setState({ payment_ref_id: paymentRef });

      /*  load process html */
      var postObject = {};
      postObject = {
        app_id: appId,
        token: token.id,
        customer_id: cookie.load("UserId"),
        outlet_id: cookie.load("orderOutletId"),
        paid_amount: payAmount,
        request_type: requestType,
        outlet_name:
          typeof cookie.load("orderOutletName") === "undefined"
            ? cookie.load("orderOutletName")
            : "",
      };

      this.postOrder(2, "Yes"); // To validate the order

      if (this.state.orderValidFail === 0) {
        axios
          .post(apiUrl + "paymentv1/authOmise", qs.stringify(postObject))
          .then((res) => {
            if (res.data.status === "ok") {
              var captureID = res.data.result_set.payment_reference_1;
              var omiseLogId = res.data.result_set.log_id;
              this.setState(
                { validateimage: tickImage, omise_log_id: omiseLogId },
                function () {
                  this.postOrder(3, "", captureID, "Omise");
                }.bind(this)
              );
            } else if (res.data.status === "error") {
              if (res.data.message == "token was already used") {
                this.omise_search_history();
              } else {
                $.magnificPopup.close();
                var errerTxt =
                  res.data.message !== ""
                    ? res.data.message
                    : "Error code: 1001 Oops! Something went wrong! Please try again.";
                this.paymentFail("Error", errerTxt);
                $.magnificPopup.open({
                  items: {
                    src: ".warining_popup",
                  },
                  type: "inline",
                });
                return false;
              }
            } else {
              var currents = this;
              setTimeout(
                function () {
                  currents.omise_search_history();
                }.bind(this),
                2000
              );
            }
          })
          .catch((error) => {

            var currentcatch = this;
            setTimeout(
              function () {
                currentcatch.omise_search_history();
              }.bind(this),
              2000
            );
          });
      } else {
        $.magnificPopup.close();
        this.paymentFail(
          "Error",
          "Error code:1003 Oops! Something went wrong! Please try again."
        );
        $.magnificPopup.open({
          items: {
            src: ".warining_popup",
          },
          type: "inline",
        });

        return false;
      }
    } else {
      $.magnificPopup.close();
      this.paymentFail(
        "Error",
        "Error code:1003 Oops! Something went wrong! Please try again."
      );
      $.magnificPopup.open({
        items: {
          src: ".warining_popup",
        },
        type: "inline",
      });

      return false;
    }
  })
  .catch((error) => {
    $.magnificPopup.close();
    this.paymentFail(
      "Error",
      "Error code: 1002 Oops! Unable to connect to server. Please try again."
    );
    $.magnificPopup.open({
      items: {
        src: ".warining_popup",
      },
      type: "inline",
    });
    return false;
  });
};

omise_search_history = () => {
var orderOutlet_Id = cookie.load("orderOutletId");
var postObject = {};
postObject = {
  app_id: appId,
  card_id: this.state.omise_tokken_card_id,
  token_id: this.state.omise_tokken_id,
  outlet_id: orderOutlet_Id,
};

axios
  .post(apiUrl + "paymentv1/omise_search_details", qs.stringify(postObject))
  .then((res) => {
    if (res.data.status === "ok") {
      var captureID = res.data.captureID;
      var omiseLogId = res.data.log_id;
      this.setState(
        { validateimage: tickImage, omise_log_id: omiseLogId },
        function () {
          this.postOrder(3, "", captureID, "Omise");
        }.bind(this)
      );
    } else if (res.data.status === "error") {
      /* Reset poup message -  start */
      this.onProcessOmiseToken(this.state.omise_tokken_response);
    } else {
      this.onProcessOmiseToken(this.state.omise_tokken_response);
    }
  });
};
clearCartItm(){
    this.props.destroyBookingCartDetail();
}

  getCartItems() {
    var cartdetails = '';
       /*axios.get(apiUrl + 'bookings/getCartDetails?app_id='+appId+'&customer_id='+cookie.load("UserId")).then(res => {*/
       axios.get(apiUrl + 'bookings/getbookingcartdata?app_id='+appId+'&customer_id='+cookie.load("UserId")).then(res => {
          hideLoader("checkout-right-body-section", "class");
          if (res.data.status === "ok") {
            var cartDetails = res.data.cart_data[0];
            var cartItems = res.data.cart_data;
			
			if((Object.keys(cartDetails).length > 0) && (cartDetails.cartitem_slot_date !== '') && (cartDetails.cartitem_slot_end_time !== '') && (cartDetails.cartitem_slot_start_time !== '')) {
				var strTime = timeToConv12(cartDetails.cartitem_slot_start_time);
				var endTime = timeToConv12(cartDetails.cartitem_slot_end_time);
				var seleted_ord_slotTxt = (cartDetails.cartitem_booking_slottype === '1') ? strTime : strTime+' - '+endTime;
				
				cookie.save('seleted_ord_date', cartDetails.cartitem_slot_date, { path: '/' });
				cookie.save('seleted_ord_slot_str', cartDetails.cartitem_slot_start_time, { path: '/' });
				cookie.save('seleted_ord_slot_end', cartDetails.cartitem_slot_end_time, { path: '/' });
				cookie.save('seleted_ord_slotTxt', seleted_ord_slotTxt, { path: '/' });
				
				this.setState({cartDetails: cartDetails,cartItems: cartItems, seleted_ord_date: cartDetails.cartitem_slot_date, seleted_ord_slot_str: cartDetails.cartitem_slot_start_time, seleted_ord_slot_end: cartDetails.cartitem_slot_end_time, seleted_ord_slotTxt: seleted_ord_slotTxt});
			} else {
				this.setState({cartDetails: cartDetails,cartItems: cartItems});
			}

          }else{
            showAlert("Error", "Sorry!. Your booking detail is empty.");
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
            this.props.history.push("/");
            return false;
          }
        });
  }


handleChangepostal = (event) => {
  cookie.save('order_postcode', event.target.value,{ path: '/' });        
  this.setState({ order_postcode: event.target.value }, () => 
      console.log(this.state.order_postcode));
  this.findOutletBasedZone(event.target.value);
  /* find Zone*/
}


findOutletBasedZone(order_postcode) {

  var postalcode = order_postcode;
  var availability = cookie.load('defaultAvilablityId');
  var outletIdTxt = cookie.load('mainOutletId');

  if (outletIdTxt === "") {
    $(".postal_error").html(
      '<span class="error">Go Back and Select your delivery outlet.</span>'
    );
    return false;
  }

  if (postalcode.length < 5) {
    $(".postal_error").html(
      '<span class="error">Please enter valid postal code.</span>'
    );
    return false;
  }

showLoader("delivery_submit_cls", "class");

axios
  .all([
    axios.get(
      apiUrlV2 +
        "outlets/findOutletZone?app_id=" +
        appId +
        "&availability_id=" +
        availability +
        "&postal_code=" +
        postalcode +
        "&outlet_id=" +
        outletIdTxt
    ),
    axios.get(
      apiUrlV2 +
        "settings/chkTimeslotIsAvaiable?app_id=" +
        appId +
        "&availability_id=" +
        availability +
        "&outletId=" +
        outletIdTxt
    ),
  ])
  .then(
    axios.spread((res, timeslt) => {
      console.log(res,'res')
      hideLoader("delivery_submit_cls", "class");

      var deliveryInfo = [];
      /* Success response */
      if (res.data.status === "ok") {
        $.magnificPopup.close();
        cookie.save("outletchosen", availability, { path: "/" });

        var orderDeliveryAddress =
          res.data.result_set.postal_code_information.zip_buno +
          " " +
          res.data.result_set.postal_code_information.zip_sname;
        var orderHandled =
          stripslashes(res.data.result_set.outlet_name) +
          ", Crew will be seeing you in " +
          res.data.result_set.outlet_delivery_timing +
          " Minutes";

        deliveryInfo["orderZoneId"] = res.data.result_set.zone_id;
        deliveryInfo["orderOutletId"] = res.data.result_set.outlet_id;
        deliveryInfo["orderOutletName"] = stripslashes(
          res.data.result_set.outlet_name
        );
        deliveryInfo["orderPostalCode"] =
          res.data.result_set.postal_code_information.zip_code;
        deliveryInfo["orderTAT"] =
          res.data.result_set.outlet_delivery_timing;
        deliveryInfo["orderDeliveryAddress"] = orderDeliveryAddress;
        deliveryInfo["orderHandled"] = orderHandled;
        deliveryInfo["defaultAvilablityId"] = availability;
        deliveryInfo["unitnumber1"] =  res.data.result_set.outlet_unit_number1;
        deliveryInfo["unitnumber2"] =  res.data.result_set.outlet_unit_number2;
        var unitNum = this.showUnitNum(
          res.data.result_set.outlet_unit_number1,
          res.data.result_set.outlet_unit_number2
        );

        var orderHandledText =
          res.data.result_set.outlet_address_line1 +
          " " +
          res.data.result_set.outlet_address_line2 +
          ", " +
          unitNum +
          " Singapore " +
          postalcode;

        deliveryInfo["orderHandledByText"] = orderHandledText;

        this.setState({
          deliveryInfo: deliveryInfo,
          seletedAvilablityId: availability,
          seletedOutletId: res.data.result_set.outlet_id,
          order_tat_time: res.data.result_set.outlet_delivery_timing,
          orderHandled: orderHandled,
          order_delivery_address:
            orderDeliveryAddress +
            " Singapore " +
            res.data.result_set.postal_code_information.zip_code,
            unitnumber1: res.data.result_set.outlet_unit_number1,
            unitnumber2: res.data.result_set.outlet_unit_number2
        });


      cookie.save("orderOutletId", res.data.result_set.outlet_id, {
        path: "/",
      });
      cookie.save(
        "orderOutletName",
        stripslashes(res.data.result_set.outlet_name),
        { path: "/" }
      );
      cookie.save(
        "orderPostalCode",
        res.data.result_set.postal_code_information.zip_code,
        { path: "/" }
      );
      cookie.save("orderTAT", res.data.result_set.outlet_delivery_timing, {
        path: "/",
      });
      cookie.save("orderDeliveryAddress", orderDeliveryAddress, {
        path: "/",
      });
      cookie.save("orderHandled", orderHandled, { path: "/" });
      cookie.save("defaultAvilablityId", availability, { path: "/" });

      var orderHandledText =
        res.data.result_set.outlet_address_line1 +
        " " +
        res.data.result_set.outlet_address_line2 +
        ", Singapore " +
        postalcode;
      cookie.save("orderHandledByText", orderHandledText, { path: "/" });
        }

      /* Error response */
      if (res.data.status === "error") {
        console.log(res.data.status,'errror')
        this.setState({ deliveryInfo: deliveryInfo });
          var mgsTxt =
            res.data.message !== ""
              ? res.data.message
              : "Please enter valid postal code.";
          $(".postal_error").html(
            '<span class="error">' + mgsTxt + "</span>"
          );
      }else{
          $(".postal_error").html("");
      }
    })
  );
}


changeBillingAddrChk() {
  var isSame = this.state.billing_addrs_sameas;
  if (isSame === "yes") {
    var tempvlu = "";
    this.setState({
      billing_postcode: tempvlu,
      billing_delivery_address: tempvlu,
      billunitnumber1: tempvlu,
      billunitnumber2: tempvlu,
      billing_addrs_sameas: "no",
    });
  } else {
    this.setState(
      { billing_addrs_sameas: "yes" },
      function () {
        this.setBillingAddrs();
      }.bind(this)
    );
  }
}

setBillingAddrs() {
  var isSame = this.state.billing_addrs_sameas;
  if (isSame === "yes") {
    var postalCode = this.state.order_postcode;
    var custAddress = this.state.order_delivery_address;
    var floorNo = this.state.unitnumber1;
    var unitNo = this.state.unitnumber2;
    this.setState({
      billing_postcode: postalCode,
      billing_delivery_address: custAddress,
      billunitnumber1: floorNo,
      billunitnumber2: unitNo,
    });
  }
}



CheckBillingAddrChk(type) {
  var isSame = this.state.billing_addrs_sameas;
  var chkBox = false;
  var diplayTxt = "block";
  if (isSame === "yes") {
    chkBox = true;
    diplayTxt = "none";
  }

  return type === "checkbox" ? chkBox : diplayTxt;
}


changePostalCode(isFrm, event) {
var postalCode = event.target.value;
var postCdLth = postalCode !== "" ? postalCode.length : 0;
var api = "https://ccpl.promobuddy.asia/api";
var appId ='FCACF7F4-AF83-4EFC-9445-83C270FD1AC2';
if (postalCode !== "" && parseInt(postCdLth) === 6) {

  var urlShringTxt =
    apiUrl +
    "settings/get_address?app_id=" +
    appId +
    "&zip_code=" +
    postalCode;

  axios.get(urlShringTxt).then((res) => {
    var custAddress = "";
    if (res.data.status === "ok") {
      var outltResulSet = res.data.result_set;
      if (Object.keys(outltResulSet).length > 0) {
        var outletAddress =
          outltResulSet.zip_buno != ""
            ? outltResulSet.zip_buno +
              ", " +
              outltResulSet.zip_sname
            : outltResulSet.zip_sname;

        custAddress = outletAddress + " " + CountryTxt;
      }
    }

    this.setState({
      billing_postcode: postalCode,
      billing_delivery_address: custAddress,
    });
  });
} else {
  var temparrs = "";
  this.setState({
    billing_postcode: postalCode,
    billing_delivery_address: temparrs,
  });
}
}


handleAddrChange(event) {
if (event.target.name === "unit_no1") {
  cookie.save("unitNoOne", event.target.value, { path: "/" });
  this.setState(
    { unitnumber1: event.target.value },
    function () {
      this.setBillingAddrs();
    }.bind(this)
  );
} else if (event.target.name === "unit_no2") {
  cookie.save("unitNoTwo", event.target.value, { path: "/" });
  this.setState(
    { unitnumber2: event.target.value },
    function () {
      this.setBillingAddrs();
    }.bind(this)
  );
} else if (event.target.name === "specil_note") {
  this.setState({ order_specil_note: event.target.value });
} else if (event.target.name === "reward_point") {
  this.setState({ reward_point_val: event.target.value });
} else if (event.target.name === "promo_code") {
  this.setState({ promo_code_val: event.target.value });
} else if (event.target.name === "bill_unit_no1") {
  this.setState({ billunitnumber1: event.target.value });
} else if (event.target.name === "bill_unit_no2") {
  this.setState({ billunitnumber2: event.target.value });
}
}

updateCartQty(itemArr, type) {
		
	var cartDetailId = itemArr.cart_id;
	var cartItemId = itemArr.cartitem_id;
	var cartQty = itemArr.cartitem_qty;
	var cartSubTotal = itemArr.cartitem_sub_total;
	var customer_id = cookie.load("UserId");
	
	if(type === 'decr') {
		cartQty = parseInt(cartQty) - 1;
		if(parseInt(cartQty) === 0) {
			this.removeCartItem(itemArr,'inputbox');
		} else {
			var item_total = parseInt(cartQty) * parseFloat(cartSubTotal);
			var postObject = {};
		    postObject = {
				app_id: appId,
				cart_detail_id: cartDetailId,
				cart_item_id: cartItemId,
				cart_item_qty: cartQty,
				item_total: item_total,
				customer_id: customer_id
		    };
			this.updateCartDetail(postObject);
		}
	} else {
		cartQty = parseInt(cartQty) + 1;
		var item_total = parseInt(cartQty) * parseFloat(cartSubTotal);
		var postObject = {};
		    postObject = {
				app_id: appId,
				cart_detail_id: cartDetailId,
				cart_item_id: cartItemId,
				cart_item_qty: cartQty,
				item_total: item_total,
				customer_id: customer_id
		    };
		this.updateCartDetail(postObject);
	}
	
}

loadCartItemList() {
	var cartItemsArr = this.state.cartItems;
	if (Object.keys(cartItemsArr).length > 0) {
	  return cartItemsArr.map((product, index) => (
				<div className="cart_row product-details">
					<div className="col-sm-cls cart_left">
					  <div className="cart_info text-left booking-cart-category-txt">
						<h4>{stripslashes(product.category_name)}</h4>
						<span>{this.getSlotdatetimeInfo(product)}</span>
					  </div>
					</div>
					<div className="col-sm-cls cart_right text-right">
					  <div className="qty_bx">
						<span className="qty_minus" onClick={this.updateCartQty.bind(this, product, 'decr')}>-</span>
						<input type="text" value={product.cartitem_qty} readOnly />
						<span className="qty_plus" onClick={this.updateCartQty.bind(this, product, 'incr')}>+</span>
					  </div>
					  <div className="cart_price booking_cart_price">
						<p className="booking_cart_priceinner">
						<span>${parseFloat(product.cartitem_grand_total).toFixed(2)}</span>
						<a
							href="/"
							onClick={this.removeCartItem.bind(this, product, 'link')}
							className="cart_remove"
						  >
							<img src={crossImg} alt="" />
						  </a>
						  </p>
						<br></br>
						<span>{product.cartitem_category_type == "hour_basis"
							  ? " Hour Basis" 
							  : " Catch Basis"}</span>
						{product.cartitem_category_type == "catch_basis" && (
						  <span>({product.cartitem_catch_count} {product.cartitem_catch_count>1?'catches':'catch'})</span>
						 )}
						 {product.cartitem_category_type == "hour_basis" && product.cartitem_additional_hours > 0 && (
						  <span>(+ {product.cartitem_additional_hours} {product.cartitem_additional_hours>1?' hours':' hour'})</span>
						 )}        
					  </div>
					</div>
			   </div>
	  ));
	}
}

updateCartDetail(postObject) {
	
	var qs = require("qs");
	showLoader("checkout-right-body-section", "class");
	axios.post(apiUrl + "bookings/updatecart", qs.stringify(postObject)).then((res) => {
		if(res.data.status === "ok") {
		  this.setState({bookingCartTriggerFlg: 'yes'});	
		  this.getCartItems();
		} else if (res.data.status === "error") {
		  hideLoader("checkout-right-body-section", "class");	
		  var errMsgtxt =
			res.data.message !== ""
			  ? res.data.message
			  : "Sorry! Products can`t add your cart.";
		  this.handleShowAlertFun("Error", errMsgtxt);
		}
		return false;
	});
	
}

removeCartItem(product, type, event) {
	if(type === 'link') {
		event.preventDefault();
	}
  
    var cartDetailId = product.cart_id;
    var cart_item_id = product.cartitem_id;
	var clear_cart = (Object.keys(this.state.cartItems).length > 1) ? 'no' : 'yes';
	var customer_id = cookie.load("UserId");
	
	if(cart_item_id !== '' && customer_id !== '') {
		var postObject = {};
		    postObject = {
				app_id: appId,
				cart_detail_id: cartDetailId,
				cart_item_id: cart_item_id,
				clear_cart: clear_cart,
				customer_id: customer_id
		    };
		var qs = require("qs");
		showLoader("checkout-right-body-section", "class");
		axios.post(apiUrl + "bookings/deletecart", qs.stringify(postObject)).then((res) => {
			if(res.data.status === "ok") {
			  this.setState({bookingCartTriggerFlg: 'yes'});	
			  this.getCartItems();
			} else if (res.data.status === "error") {
			  hideLoader("checkout-right-body-section", "class");	
			  var errMsgtxt =
				res.data.message !== ""
				  ? res.data.message
				  : "Sorry! Products can`t add your cart.";
			  this.handleShowAlertFun("Error", errMsgtxt);
			}

			return false;
		});
	
	}

}

handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

getSlotdatetimeInfo(product) {
	var dateObj = new Date(product.cartitem_slot_date);
	var dateStr = format(dateObj, "dd/MM/yyyy");
	var strTime = timeToConv12(product.cartitem_slot_start_time);
	var endTime = timeToConv12(product.cartitem_slot_end_time);
	if(product.cartitem_booking_slottype === '1') {
		var slotdateTimestr = dateStr+' '+strTime;
	} else {
		var slotdateTimestr = dateStr+' '+strTime+' - '+endTime;
	}
	return slotdateTimestr;
}

/* Validate Int Value */
validateIntval(e) {
const re = /[0-9]+/g;
if (!re.test(e.key)) {
  e.preventDefault();
}
}

showUnitNum(unit1, unit2) {
unit1 = typeof unit1 !== "undefined" ? unit1 : "";
unit2 = typeof unit2 !== "undefined" ? unit2 : "";

if (unit1 !== "") {
  var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
} else {
  var unitNo = unit2;
}

return unitNo !== "" ? "#" + unitNo + "," : "";
}


  sateValChange = (field, value) => {
	if (field === "bookingcartflg") {
      this.setState({ bookingCartTriggerFlg: value });
    }
  };
  
  render() {
var payAmount = parseFloat(this.state.cartDetails.cart_grand_total).toFixed(2);

      return (

        <div className="checkout-main-div">
           <Header
		      bookingCartTriggerFlg={this.state.bookingCartTriggerFlg}
              sateValChange={this.sateValChange}
              />
           <div className="common-inner-blckdiv mb-50">
              <div className="common-inner-banner booking-common-inner">
               <img src={banner} />
                 <p>
                    Checkout
                 </p>
              </div>
           </div>
          <div className="checkout-body">
            <div className="container">
              <div className="check_out_fourcommon">

                <div className="container-one">
                   <div className="checkoutpage_form_outer">
                    <form
                    id="checkoutpage_form"
                    className=" form_sec checkout-total"
                    method="post"
                    acceptCharset="utf-8"
                    >
                        <div className="cdd-details">
                            <div className="cdd-details-lhs fl">
                                <div className="text-center checkout-heading checkout-detail-detail">
                                    <div className="tab-menu-container">
                                        <ul>
                                            <li>
                                              <a href="javascript:void(0)" className="tab_item productItem-1 text-uppercase-leftone active" data-id="productItem-1"><span data-id="productItem-1" className="text-uppercase">
                                                   Booking Details
                                                    </span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                    <div className="booking prd_chosen_row compo_top_div product_tab_item tab_item_active" data-id="productItem-1">
                                        <div className="product_chosen_inner">
                                  
                                            {/*<div className="checkout-control-group-top">
                                                <label className="chk_hea">Location</label>
                                                <div className="form-group">
                                                    <div
                                                    className={
                                                    this.state.order_postcode != "" && typeof this.state.order_postcode != "undefined"
                                                    ? "focus-out focused"
                                                    : "focus-out"
                                                    }
                                                    >
                                                        <label>Postal Code</label>
                                                        <input
                                                        type="text"
                                                        name="order_postcode"
                                                        value={this.state.order_postcode}
                                                        className="form-control input-focus order_postcode"
                                                        onChange={this.handleChangepostal.bind(this)}
                                                        />
                                                    </div>
                                                    <div className="postal_error"></div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="focus-out controls-single">
                                                        <input
                                                        type="text"
                                                        placeholder="Address"
                                                        readOnly
                                                        className="form-control input-focus"
                                                        value={this.state.order_delivery_address}
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div className="form-group controls-three">
                                                    <div
                                                    className={
                                                    this.state.unitnumber1 != ""
                                                    ? "focus-out focused"
                                                    : "focus-out"
                                                    }
                                                    >
                                                        <label className="unit-num">Unit No 01</label>
                                                        <input
                                                        type="text"
                                                        id="unit_no1_id"
                                                        name="unit_no1"
                                                        value={this.state.unitnumber1!==""?this.state.unitnumber1:""}
                                                        className="form-control input-focus"
                                                        onChange={this.handleAddrChange.bind(this)}
                                                        />
                                                    </div>
                                                    <div
                                                    className={
                                                    this.state.unitnumber2 != ""
                                                    ? "focus-out focused"
                                                    : "focus-out"
                                                    }
                                                    >
                                                        <label className="unit-num">Unit No 02</label>
                                                        <input autocomplete="off"
                                                        type="text"
                                                        name="unit_no2"
                                                        value={this.state.unitnumber2!==""?this.state.unitnumber2:""}
                                                        className="form-control input-focus"
                                                        onChange={this.handleAddrChange.bind(this)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>*/}

                                            <div className="checkout-billing-address mt-20">
                                                <label className="chk_hea">
                                                    Booking Date & Time
                                                </label>
                                                <div className="form-group1234 mt-20">
                                                <h4>{moment(this.state.seleted_ord_date).format("DD/MM/YYYY")} &nbsp;
                                                    {this.state.seleted_ord_slotTxt}</h4>
                                                    <div className="ordrdatetime_error">
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div className="checkout-billing-address">
                                                <label className="chk_hea">
                                                    Billing Address{" "}
                                                    {/*<span>
                                                        Same As Delivery Location{" "}
                                                        <div className="custom_checkbox">
                                                            <input
                                                            type="checkbox"
                                                            name="bill_chk"
                                                            className="addon_list_single"
                                                            onChange={this.changeBillingAddrChk.bind(
                                                            this
                                                            )}
                                                            checked={this.CheckBillingAddrChk(
                                                            "checkbox"
                                                            )}
                                                            />
                                                            <span></span>
                                                        </div>
                                                    </span>*/}
                                                </label>
                                                <div
                                                className="check-billing"
                                                style={{
                                                display: this.CheckBillingAddrChk("display"),
                                                }}
                                                >
                                                    <div className="form-group">
                                                        <div
                                                        className={
                                                        this.state.billing_postcode != ""
                                                        ? "focus-out focused"
                                                        : "focus-out"
                                                        }
                                                        >
                                                            <label>Postal Code</label>
                                                            <input
                                                            type="text"
                                                            name="billing_postcode"
                                                            value={this.state.billing_postcode}
                                                            onChange={this.changePostalCode.bind(
                                                            this,
                                                            "bill"
                                                            )}
                                                            onKeyPress={(e) => this.validateIntval(e)}
                                                            className="form-control input-focus"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="focus-out controls-single">
                                                            <input
                                                            type="text"
                                                            placeholder="Address"
                                                            className="form-control input-focus"
                                                            readOnly
                                                            value={this.state.billing_delivery_address}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group controls-three">
                                                        <div
                                                        className={
                                                        this.state.billunitnumber1 != ""
                                                        ? "focus-out focused"
                                                        : "focus-out"
                                                        }
                                                        >
                                                            <label className="unit-num">Unit No 01</label>
                                                            <input
                                                            type="text"
                                                            name="bill_unit_no1"
                                                            value={this.state.billunitnumber1}
                                                            className="form-control input-focus"
                                                            onChange={this.handleAddrChange.bind(this)}
                                                            />
                                                        </div>
                                                        <div
                                                        className={
                                                        this.state.billunitnumber2 != ""
                                                        ? "focus-out focused"
                                                        : "focus-out"
                                                        }
                                                        >
                                                            <label className="unit-num">Unit No 02</label>
                                                            <input
                                                            type="text"
                                                            name="bill_unit_no2"
                                                            value={this.state.billunitnumber2}
                                                            className="form-control input-focus"
                                                            onChange={this.handleAddrChange.bind(this)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="checkout-billing-address hidden">
                                                <label className="chk_hea">
                                                    SMS Notification{" "}
                                                      <span>
                                                        SMS Notification{" "}
                                                        <div className="custom_checkbox">
                                                            <input
                                                            type="checkbox"
                                                            name="sms_notify"
                                                            className="addon_list_single"
                                                            onChange={this.changeSMSChk.bind(
                                                            this
                                                            )}
                                                            />
                                                            <span></span>
                                                        </div>
                                                    </span>
                                                </label>
                                                <p>For SMS , its charge 10 cents additionally</p>
                                            </div>   
                                          
                                            <div className="checkout-billing-address hidden">
                                                <label className="chk_hea">
                                                    Email Notification{" "}
                                                    <span>
                                                        Email Notification{" "}
                                                        <div className="custom_checkbox">
                                                            <input
                                                            type="checkbox"
                                                            name="email_notify"
                                                            className="addon_list_single"
                                                            onChange={this.changeEmailChk.bind(
                                                            this
                                                            )}
                                                            />
                                                            <span></span>
                                                        </div>
                                                    </span>
                                                </label>
                                            </div>  
                                            <div className="checkout-billing-address checkout-control-group-bottom">
                                                <label className="chk_hea">Special Message</label>
                                                <textarea
                                                className="mt-20"
                                                placeholder="Please enter your special message here..."
                                                name="specil_note"
                                                value={this.state.order_specil_note}
                                                onChange={this.handleAddrChange.bind(this)}
                                                id="orderRemarks"
                                                ></textarea>
                                                {this.state.termsAndConditions !== "" && (
                                                <div>
                                                    <div className="checkout-terms-and-condition">
                                                        {this.state.termsAndConditions}
                                                    </div>
                                                    <div className="custom_checkbox tarms_chkbox_div">
                                                        <input
                                                        type="checkbox"
                                                        name="tarms_chk"
                                                        onClick={this.changeTarmsContnChk.bind(this)}
                                                        defaultChecked={this.CheckTarmsContnChk()}
                                                        />
                                                        <span>
                                                            I have read and accept terms and conditions
                                                        </span>
                                                    </div>
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            {/* cdd-details-lhs - end */}
                            {/* cdd-details-rhs - start */}
                            <div className="cdd-details-rhs fl">
                                <div className="chekout_cart_bar">
                                    <div className="cart-header">
                                        <div className="text-center checkout-heading checkout-heading-right">
                                            <span className="text-uppercase">
                                                Booking Summary
                                            </span>
                                        </div>
                                    </div>
                                    {(Object.keys(this.state.cartDetails).length > 0) && <div className="checkout-right-body-section booking">
                                      <div className="cart_table">
                                        <div className="cart_body">
                                          <div className="overall-parent">
                                            <div className="order-details-with-clear">
                                              <h5>Pond Name</h5>
                                              <a
                                                href="/"
                                                onClick={this.clearCartItm.bind(this)}
                                                className="hclear_cart"
                                                title="CLEAR CART"
                                              >CLEAR CART
                                              </a>
                                            </div>
                                            <div className="product-details-parent">
											{this.loadCartItemList()}
                                            </div>
                                          </div>
                                          
                                        </div>

                                        <div className="cart_footer">
                                          <div className="cart_row">
                                            <p className="text-uppercase">SUBTOTAL</p>
                                            <span>${this.state.cartDetails.cart_sub_total}</span>
                                          </div>


                                          {this.state.smsCharge == 1 && (
                                          <div className="cart_row">
                                            <p className="text-uppercase">SMS Charge</p>
                                            <span>${smsCharge}</span>
                                          </div>
                                          )}
										  
                                          <div className="cart_row grant-total-cls">
                                            <p className="text-uppercase">Total</p>
                                            <span>${parseFloat(this.state.cartDetails.cart_grand_total).toFixed(2)}</span>
                                          </div>
                                        </div>      

                                      </div>
                                      
                                    </div>}
									
                                </div>
                            </div>
                            {/* cdd-details-rhs - end */}
                        </div>
                    </form>


                   </div>

            {/* chk-payment div - end */}
            <div className="chk-payment">
                <div className="chk-payment-row">
                    <div className="chk-payment-title">
                        <h3>There will be no refunds given once payment has been made</h3>
                        <h3>
                            Select Your Payment Method
                        </h3>
                        <p>
                            You cannot change order after payment
                        </p>
                    </div>
                    <div className="chk-payment-col">
                        <form action="#">
                            {this.state.paymentmodevalue !== "promotion" &&
                            parseFloat(payAmount) > 0 && (
                            <ul className="chk-payment-col-radio">
                                {this.state.cod_payment_enable === 1 && (
                                <li className="custom_checkbox">
                                    <input
                                    type="radio"
                                    defaultChecked={
                                    this.state.paymentmodevalue == "Cash"
                                    ? "checked"
                                    : ""
                                    }
                                    id="cash"
                                    onClick={this.choosePayment.bind(
                                    this,
                                    "Cash"
                                    )}
                                    name="radio-group"
                                    />
                                    <label htmlFor="cash"></label>
                                    <div className="radio_con">
                                        Cash On Delivery
                                    </div>
                                </li>
                                )}
                                  {this.state.omise_payment_enable === 1 && (
                                  <li className="custom_checkbox">
                                      <input
                                      type="radio"
                                      defaultChecked={
                                      this.state.paymentmodevalue == "Omise"
                                      ? "checked"
                                      : ""
                                      }
                                      id="omscard"
                                      onClick={this.choosePayment.bind(
                                      this,
                                      "Omise"
                                      )}
                                      name="radio-group"
                                      />
                                      <label htmlFor="omscard"></label>
                                      <div className="radio_con">
                                          <img src={paymentImage} />
                                      </div>
                                  </li>
                                  )}
                                  {this.state.omisepaynow_payment_enable === 1 && (
                                    <li className="custom_checkbox">
                                      <input
                                        type="radio"
                                        defaultChecked={
                                          this.state.paymentmodevalue ==
                                          "OmisePayNow"
                                            ? "checked"
                                            : ""
                                        }
                                        id="omspaynowcard"
                                        onClick={this.choosePayment.bind(
                                          this,
                                          "OmisePayNow",
                                          "paynow"
                                        )}
                                        name="radio-group"
                                      />
                                      <label htmlFor="omspaynowcard"></label>
                                      <div className="radio_con">
                                        <img src={paymentPayNowImage} />
                                      </div>
                                    </li>
                                  )}


                                {/*
                                  {this.state.cod_payment_enable === 1 && (
                                  <li className="custom_checkbox">
                                      <input
                                      type="radio"
                                      defaultChecked={
                                      this.state.paymentmodevalue == "Cash"
                                      ? "checked"
                                      : ""
                                      }
                                      href="otherpayment-popup"
                                      onClick={this.choosePayment.bind(
                                      this,
                                      "Cash"
                                      )}
                                      name="radio-group"
                                      />
                                      <label htmlFor="cash"></label>
                                      <div className="radio_con">
                                          <img src={paymentoffdel} />
                                      </div>
                                  </li>
                                  )}
                                  {this.state.cod_payment_enable === 1 && (
                                  <li className="custom_checkbox">
                                      <input
                                      type="radio"
                                      defaultChecked={
                                      this.state.paymentmodevalue == "Cash"
                                      ? "checked"
                                      : ""
                                      }
                                      id="cash"
                                      onClick={this.choosePayment.bind(
                                      this,
                                      "Cash"
                                      )}
                                      name="radio-group"
                                      />
                                      <label htmlFor="cash"></label>
                                      <div className="radio_con">
                                          <img src={paymentpypllogo} />
                                      </div>
                                  </li>
                                  )}
                                  {this.state.cod_payment_enable === 1 && (
                                  <li className="custom_checkbox">
                                      <input
                                      type="radio"
                                      defaultChecked={
                                      this.state.paymentmodevalue == "Cash"
                                      ? "checked"
                                      : ""
                                      }
                                      id="cash"
                                      onClick={this.choosePayment.bind(
                                      this,
                                      "Cash"
                                      )}
                                      name="radio-group"
                                      />
                                      <label htmlFor="cash"></label>
                                      <div className="radio_con">
                                          <img src={paymentomise} />
                                      </div>
                                  </li>
                                  )}
                                  {this.state.cod_payment_enable === 1 && (
                                  <li className="custom_checkbox">
                                      <input
                                      type="radio"
                                      defaultChecked={
                                      this.state.paymentmodevalue == "Cash"
                                      ? "checked"
                                      : ""
                                      }
                                      id="cash"
                                      onClick={this.choosePayment.bind(
                                      this,
                                      "Cash"
                                      )}
                                      name="radio-group"
                                      />
                                      <label htmlFor="cash"></label>
                                      <div className="radio_con">
                                          <img src={paymentomisepaynow} />
                                      </div>
                                  </li>
                                  )}
                                  {this.state.stripe_payment_enable === 1 && (
                                  <li className="custom_checkbox">
                                      <input
                                      type="radio"
                                      defaultChecked={
                                      this.state.paymentmodevalue == "Stripe"
                                      ? "checked"
                                      : ""
                                      }
                                      id="card"
                                      onClick={this.choosePayment.bind(
                                      this,
                                      "Stripe"
                                      )}
                                      name="radio-group"
                                      />
                                      <label htmlFor="card"></label>
                                      <div className="radio_con">
                                          <img src={paymentImage} />
                                      </div>
                                  </li>
                                  )}
                                */}
                            </ul>
                            )}
                            {this.state.paymentmodevalue === "promotion" &&
                            parseFloat(payAmount) === 0 &&
                            subTotalAmount > 0 && (
                            <ul className="chk-payment-col-radio promotion-ul-topcls">
                                <li className="custom_checkbox">
                                    <input
                                    type="radio"
                                    defaultChecked={
                                    this.state.paymentmodevalue == "promotion"
                                    ? "checked"
                                    : ""
                                    }
                                    id="promotion"
                                    name="radio-group"
                                    />
                                    <label htmlFor="promotion"></label>
                                    <div className="radio_con">
                                        Promotion
                                    </div>
                                </li>
                            </ul>
                            )}
                        </form>
                        <div
                        id="otherpayment-popup"
                        className="white-popup mfp-hide popup_sec login-popup">
                            <div className="pop-whole">
                                <div className="inside-dine-popup-rhs">
                                    <div className="pop-whole-lhs-inner home-popup-head">
                                        <h3>
                                            Payment Information
                                        </h3>
                                        <p>
                                            Send proof to Whatsapp via +659xxxxxxx
                                        </p>
                                    </div>
                                    <div className="text-center qrcode mb-10 ">
                                        <img src={qrcode}/>
                                    </div>
                                    <p>
                                        Send proof to Whatsapp via +659xxxxxxx
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="chk-payment-col">
                    </div>
                </div>
                {/* chk-payment div - end */}
                {/* chk-payment-btn div - end */}
                <div className="chk-payment-btn">
                    {this.state.paymentmodevalue !== "promotion" &&
                    parseFloat(payAmount) > 0 && (
                    <div className="chk-payment-btn-row">
                        {this.state.paymentmodevalue == "Cash" &&
                        this.state.cod_payment_enable === 1 && (
                        <a
                        href="/"
                        className="button"
                        title="Continue"
                        onClick={this.payCash.bind(this)}
                        >
                            Place Order
                        </a>
                        )}
                        {/*this.state.globalSettings.stripe_public_key && cookie.load('UserMobile') !='' && this.state.paymentmodevalue == 'Stripe' && this.state.stripe_payment_enable === 1 && 
                        <StripeCheckout name={stripeCompany} image={stripelogo} description={this.stripeDescription()} token={this.onToken} stripeKey={this.state.globalSettings.stripe_public_key} amount={(payAmount * 100)} email={cookie.load('UserEmail')} currency={stripeCurrency} >
                            <a href="javascript:;" className="button" title="Continue">Checkout</a>
                        </StripeCheckout>
                        */}
                        {this.state.globalSettings.stripe_public_key &&
                        cookie.load("UserMobile") != "" &&
                        this.state.paymentmodevalue == "Stripe" &&
                        this.state.stripe_payment_enable === 1 && (
                        <a
                        href="/"
                        onClick={this.orderPayValidaion.bind(
                        this,
                        "Stripe"
                        )}
                        className="button"
                        title="Continue"
                        >
                            Place Order
                        </a>
                        )}
                        {this.state.globalSettings.omise_public_key &&
                        cookie.load("UserMobile") != "" &&
                        this.state.paymentmodevalue == "Omise" &&
                        this.state.omise_payment_enable === 1 && (
                        <a
                        href="javascript:;"
                        onClick={this.orderPayValidaion.bind(
                        this,
                        "Omise"
                        )}
                        className="button"
                        title="Continue"
                        >
                            Place Order
                        </a>
                        )}
                    </div>
                    )}
                    {this.state.paymentmodevalue === "promotion" &&
                    parseFloat(payAmount) === 0 &&
                    subTotalAmount > 0 && (
                    <div className="chk-payment-btn-row">
                        <a
                        href="/"
                        className="button"
                        title="Continue"
                        onClick={this.postPromotionOrder.bind(this)}
                        >
                            Place Order
                        </a>
                    </div>
                    )}
                    {this.state.globalSettings.omise_public_key &&
                      cookie.load("UserMobile") != "" &&
                      this.state.paymentmodevalue == "OmisePayNow" &&
                      this.state.omise_payment_enable === 1 && (
                      <div className="chk-payment-btn-row">
                        <a
                          href={void 0}
                          onClick={this.orderPayValidaion.bind(
                            this,
                            "OmisePayNow"
                          )}
                          className="button"
                          id="omisepaynow_btn"
                          title="Continue"
                        >
                          Place Order
                        </a>
                      </div>
                      )}
                </div>
            </div>
            {/* chk-payment-btn div - end */}


                </div>

              </div>
            </div>
          </div>


          {/*Payment confirm popup Start*/}
          <div
          id="pay-conf-popup"
          className="white-popup mfp-hide popup_sec pay-conf-popup"
          >
          <div className="custom_alert">
              <div className="custom_alertin">
                  <div className="alert_height">
                      <div className="alert_header">
                          Alert!
                      </div>
                      <div className="alert_body">
                          <p>
                              Awesome ! You are all set
                          </p>
                          <div className="alt_btns">
                              {this.state.globalSettings.stripe_public_key &&
                              cookie.load("UserMobile") != "" &&
                              this.state.paymentmodevalue == "Stripe" &&
                              this.state.stripe_payment_enable === 1 && (
                              <StripeCheckout
                              name={stripeCompany}
                              image={stripelogo}
                              description={this.stripeDescription()}
                              token={this.onToken}
                              stripeKey={
                              this.state.globalSettings.stripe_public_key
                              }
                              amount={payAmount * 100}
                              email={cookie.load("UserEmail")}
                              currency={stripeCurrency}
                              >
                                  {" "}
                                  <a
                                  href="javascript:;"
                                  className="button"
                                  title="Proceed To Payment"
                                  >
                                      Proceed To Payment
                                  </a>
                              </StripeCheckout>
                              )}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
          {/*payment confirm popup - End*/}


          {/*Payment omise confirm popup Start*/}
          <div
          id="pay-omiseconf-popup"
          className="white-popup mfp-hide popup_sec pay-omiseconf-popup"
          >
          <div
          className="omise-error-info-div"
          id="omise-error-info-div"
          data-timeoutid=""
          style={{ display: "none" }}
          >
              <div className="container">
                  <span className="omise-error-icon">
                      <i
                      className="fa fa-exclamation-triangle"
                      aria-hidden="true"
                      >
                      </i>
                  </span>
                  <a
                  href="#"
                  className="omise_alert_close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={(evt) => {
                      evt.preventDefault();
                      }}
                      >
                      {" "}
                      <span aria-hidden="true">×</span>{" "}
                  </a>
                  <p className="omise-error-msg">
                      Something went wrong
                  </p>
              </div>
          </div>
          {this.state.globalSettings.omise_envir === "dev" && (
          <div className="omisepay-mode-option">
              Test Mode
          </div>
          )}
          <div className="custom_alert">
              <div className="custom_alertin">
                  <div className="alert_height">
                      <div className="omise_pay_header">
                          <span className="omise-pay-img">
                              <img className="makisan-logo-img" src={stripelogo} />
                          </span>
                          <span className="omise-pay-title">D-Best</span>
                      </div>
                      <div className="omise_pay_body">
                          <div className="omisepop_in">
                              <div className="form_sec">
                                  <div className={"uname-box " + this.state.omisenameerror}>
                                      <i className="fa fa-user" data-unicode="f007">
                                      </i>
                                      <input
                                      type="text"
                                      id="holdername"
                                      className="form-control"
                                      placeholder="Card Holder Name"
                                      value={this.state.holdername}
                                      onChange={this.handleChangeTxt.bind(
                                      this,
                                      "holdername"
                                      )}
                                      />
                                  </div>
                                  <div
                                  className={"card-numbr-div " + this.state.omisecardrror}
                                  >
                                      <i
                                      className="fa fa-credit-card"
                                      data-unicode="f09d"
                                      >
                                      </i>
                                      <input
                                      type="text"
                                      className={"form-control " + this.state.cardImage}
                                      placeholder="Card Number"
                                      value={this.state.cardNumber}
                                      id="omise_card_number"
                                      value={this.state.cardNumber}
                                      onChange={this.handleChangeTxt.bind(
                                      this,
                                      "cardNumber"
                                      )}
                                      />
                                      <span className="card"></span>
                                  </div>
                                  <div className="form_expire_row">
                                      <div className="expire_row_inner">
                                          <div
                                          className={
                                          "expire_left " + this.state.omisemontherror
                                          }
                                          >
                                              <i
                                              className="fa fa-calendar-o"
                                              data-unicode="f133"
                                              >
                                              </i>
                                              <input
                                              type="text"
                                              className="form-control"
                                              maxLength="2"
                                              id="expiration_month"
                                              placeholder="MM"
                                              value={this.state.expiration_month}
                                              onChange={this.handleChangeTxt.bind(
                                              this,
                                              "expiration_month"
                                              )}
                                              />
                                          </div>
                                          <div
                                          className={
                                          "expire_mdl " + this.state.omiseyearerror
                                          }
                                          >
                                              <input
                                              type="text"
                                              placeholder="YY"
                                              className="form-control"
                                              maxLength="4"
                                              id="expiration_year"
                                              value={this.state.expiration_year}
                                              onChange={this.handleChangeTxt.bind(
                                              this,
                                              "expiration_year"
                                              )}
                                              />
                                          </div>
                                          <div
                                          className={
                                          "expire_right " + this.state.omisecodeerror
                                          }
                                          >
                                              <i className="fa fa-lock" data-unicode="f023">
                                              </i>
                                              <input
                                              type="password"
                                              maxLength="3"
                                              className="form-control"
                                              id="security_code"
                                              placeholder="CVV"
                                              value={this.state.security_code}
                                              onChange={this.handleChangeTxt.bind(
                                              this,
                                              "security_code"
                                              )}
                                              />
                                          </div>
                                      </div>
                                  </div>
                                  <div className="alt_btns">
                                      {this.state.globalSettings.omise_public_key &&
                                      cookie.load("UserMobile") != "" &&
                                      this.state.paymentmodevalue == "Omise" &&
                                      this.state.omise_payment_enable === 1 && (
                                      <button
                                      onClick={this.getOmiseToken.bind(this)}
                                      className="button omise-pay-btn"
                                      >
                                          Pay {stripeCurrency}{" "}
                                          {parseFloat(payAmount).toFixed(2)}
                                      </button>
                                      )}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
          {/*payment omise confirm popup - End*/}

        {/*Payment omise Pay Now confirm popup Start*/}
        <div id="pay-omisepaynowconf-popup"className="white-popup mfp-hide popup_sec pay-omisepaynowconf-popup"> <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="omise_pay_header">
                  <span className="omise-pay-img">
                    <img className="makisan-logo-img" src={stripeImage} />
                  </span>
                          <span className="omise-pay-title">D-Best</span>
                </div>
                {Object.keys(this.state.omisePayNowResponse).length > 0 && (
                  <div className="omise_pay_body">
                    <div className="omisepop_in">
                      <div className="omisepop_in">
                        <p className="omise-pay-now-notes">
                          Scan the QR code to pay.
                        </p>
                        {this.state.omisePayNowResponse.download_uri !== "" && (
                          <img
                            src={this.state.omisePayNowResponse.download_uri}
                            id="paynow-qr-image"
                          />
                        )}
                        <p className="omise-paynow-amount-to-paid">
                          <span>
                            $ {this.state.omisepaynowAmount.toFixed("2")}
                          </span>
                        </p>
                        <p className="paynow-seconds">
                          <i className="fa fa-clock-o" aria-hidden="true"></i>
                          <span>&nbsp;{this.state.omisePaynowTime} sec</span>
                        </p>
                        <div className="paynow-notes">
                          <h3>Instructions:</h3>
                          <ul className="instructions">
                            <li>Use your bank app to scan QR Code and pay.</li>
                            <li>
                              You will be paying to Omise Payment SG (Authorised
                              payment processor) for
                            </li>
                            <li>
                              Once you make the payment, payment status of the
                              order will reflect as 'paid', please do not make
                              multiple payments with the same QR Code.
                            </li>
                            <li>
                              Page will automatically refresh after your
                              successful payment.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*payment omise Pay Now confirm popup - End*/}


        <div
        id="processing-popup"
        className="white-popup mfp-hide popup_sec processing"
        >
        <div className="pouup_in">
            <h3 className="title1 text-center">
                We Are Processing Your Order
            </h3>
            <div className="process_inner">
                {this.onlinePaymentLoading()}
                {this.orderBCLoading()}
                {this.amountCaptureLoading()}
            </div>
        </div>
        </div>
        <Footer />
       </div>



      );
      }
  
}

const mapStateToProps = (state) => {


  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
  if (state.settings[0].status === "ok") {
    globalSettings = state.settings[0].result_set;
  }
  }

  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
  if (state.staticblack[0].status === "ok") {
    blacksArr = state.staticblack[0].result_set;
  }
  }

  return {
    globalsettings: state.settings,
    settingsArr: globalSettings,
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    destroyBookingCartDetail: () => {
      dispatch({ type: DESTROY_BOOKING_CART_DETAIL });
    },
  };
};

Booking.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Booking));
