import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";

import Home from "./components/Home/Home";
import Products from "./components/Products/Products";
import Activities from "./components/Products/Activities";
import PackagesPromos from "./components/Products/PackagesPromos";
import Checkout from "./components/Checkout/Checkout";
import Thankyou from "./components/Checkout/Thankyou";
import Payment from "./components/Payment/Payment";
import Pages from "./components/Pages/Pages";
import Faq from "./components/Pages/Faq";
import ContactUs from "./components/Pages/ContactUs";
import Outlets from "./components/Pages/Outlets";
import Media from "./components/Pages/Media";
import Myaccount from "./components/Myaccount/Myaccount";
import Orders from "./components/Myaccount/Orders";
import Mypromotions from "./components/Myaccount/Mypromotions";
import Myvouchers from "./components/Myaccount/Myvouchers";
import Rewards from "./components/Myaccount/Rewards";
import Account from "./components/Account/Account";
import Scanqrcode from './components/Dineqrcode/Scanqrcode';
import Booktable from './components/Dineqrcode/Booktable';
import Resetpassword from "./components/Account/Resetpassword";
import Logout from "./components/Myaccount/Logout";
import Refpage from "./components/Layout/Refpage";
import Page404 from "./Page404";
import ProductDetail from "./components/Products/ProductDetail";
import Booking from "./components/Checkout/Booking";
import Bookingthankyou from "./components/Checkout/Bookingthankyou";
import Scanbookingqrcode from './components/Dineqrcode/Scanbookingqrcode';

const store = getStore();

render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          path={"/products/:slugType/:slugValue/:proValue"}
          component={ProductDetail}
        />
        <Route path={"/products/:slugType/:slugValue"} component={Products} />
        <Route path="/products" component={Products} />
        <Route path="/activities" component={Activities} />
        <Route path="/packages-promos" component={PackagesPromos} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/booking" component={Booking} />
        <Route path="/scanqrcode" component={Scanqrcode} />
        <Route path="/thankyou/:orderId" component={Thankyou} />
        <Route path="/bookingthankyou/:orderId" component={Bookingthankyou} />
        <Route path="/payment/:orderId/:paymentId" component={Payment} />
        <Route path="/faq" component={Faq} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/outlet" component={Outlets} />
        <Route path="/promotions-media" component={Media} />
        <Route path="/rewards" component={Rewards} />
        <Route path="/mypromotions" component={Mypromotions} />
        <Route path="/myvouchers" component={Myvouchers} />
        <Route path="/myorders" component={Orders} />
        <Route path="/myaccount" component={Myaccount} />
        <Route path="/scanqrcode" component={Scanqrcode} />
        <Route path="/scanbookingqrcode/:ordernumber" component={Scanbookingqrcode} />
        <Route path="/quickbook/:tablenumber" component={Booktable} />
        <Route path="/account/activation/:activationKey" component={Account} />
        <Route
          path="/account/resetpassword/:resetKey"
          component={Resetpassword}
        />
        <Route path="/logout" component={Logout} />
        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route path="/:page_slug" component={Pages} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root") 
);
