/* eslint-disable */
import { push } from 'react-router-redux';
import { takeEvery, call, put } from 'redux-saga/effects';
import { DESTROY_BOOKING_CART_DETAIL } from '../actions';
import { appId, apiUrl, apiUrlV2, deliveryId } from "../components/Helpers/Config";
import { getReferenceID } from "../components/Helpers/SettingHelper";
import Axios from 'axios';
import cookie from 'react-cookies';
var qs = require('qs');


export const watchDestroyBookingCartDetail = function* () {
  yield takeEvery(DESTROY_BOOKING_CART_DETAIL, workerDestroyBookingCartDetail);
}

function* workerDestroyBookingCartDetail() {
  try {
	  
	var postObject = {};
    postObject = { 'app_id': appId };

    if (typeof cookie.load('UserId') === 'undefined') {
      postObject['reference_id'] = getReferenceID();
    } else {
      postObject['customer_id'] = cookie.load('UserId');
    }
	
    const uri = apiUrl+'bookings/destroy';
    const result = yield call(Axios.post, uri, qs.stringify(postObject));
  } 
  catch {
    console.log('Get Cart Detail Failed');
  }
}
 
