/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { GET_STATIC_BLOCK } from "../../actions";

import footerLogo from "../../common/images/ft-logo.png";

import MappinIcon from "../../common/images/map-pin-icon.png";
import phoneIcon from "../../common/images/phone-icon.png";
import msgIcon from "../../common/images/msg-icon.png";
import FootLeft from "../../common/images/footleft.png";
import mainLogo from "../../common/images/home-logo.png";
import facebookIcon from "../../common/images/facebook-icon.png";
import instagramIcon from "../../common/images/instagram-icon.png";
import youtubeIcon from "../../common/images/youtube-icon.png";
import cookie from 'react-cookies';

var Parser = require("html-react-parser");
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { footerBlocks: "", footerlink: "" };
  }

  componentDidMount() {


    this.props.getStaticBlock();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });

    //trigger login in footer menu
    if(!cookie.load('UserId')){
      setTimeout(function(){
      $(document).find('.make_login').attr('href','#login-popup')
      $(document).find('.make_login').addClass('open-popup-link new_login_popup')
      },1000);
    }

    $(document).on('click','.new_login_popup',function(){
      $.magnificPopup.open({
      items: {
      src: '#login-popup'
      },
      type: 'inline'
      });
    });
    //trigger login in footer menu

  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
	  var footerPas = "";
	  var footerContent = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "footer-contents") {
            footerPas = data.staticblocks_description;
            return "";
          }
        });
      }
	 // footerContent = PropsData.staticblack.staticblocks_description !== "" ? Parser(PropsData.staticblack.staticblocks_description) : PropsData.staticblack.staticblocks_description;
	  footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: footerContent,
        footerlink: footerPas,
      });
    }
  }
  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();
 
    return (      
     
      <footer className="footer-main">
      <section className="foot-left">
      <div className="container">
        <img src={FootLeft}/>
      </div>
    </section>
 <section className="foot-bottom">
 <div className="container">
 <div className="footer-right"><img src={mainLogo} /></div>
 {this.state.footerlink}
 </div>
 <div className="copyright-section">
    <p>Copyright 2021 D-Best Recreation.  All rights reserved.</p>
 </div>
 </section>
 <div className="scrolltop" id="scrollbutton"> 
        <a href="#">
          <span>
          <i className="fa fa-angle-double-up ars"></i>
          <i className="fa fa-angle-up ars1"></i>
          <i className="fa fa-angle-up ars2"></i>
          </span>
        </a>
      </div>
</footer>

    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
