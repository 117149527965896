/* eslint-disable */
import React, { Component } from "react";

import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { stripslashes } from "../Helpers/SettingHelper";

import {
  appId,
  apiUrl,
  apiUrlV2,
  timThumpUrl,
  deliveryId,
  reservationId,
  mediaUrl,
} from "../Helpers/Config";

var Parser = require("html-react-parser");

import update from "immutability-helper";

import { validated } from "react-custom-validation";
import validator from "validator";

import axios from "axios";
import cookie from "react-cookies";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
 import banner from "../../common/images/products-bc.jpg";
import noimage from "../../common/images/noimg-470x240.jpg";
import innerBanner from "../../common/images/inner-banner.jpg";

import { GET_ALL_OUTLETS, GET_GLOBAL_SETTINGS } from "../../actions";

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outlets: [],
      globalsettings: [],
      starttime: "",
      endtime: "",
    };
  }

  componentDidMount() {
    this.props.getAllOutlets(deliveryId);
    this.props.getGlobalSettings();
    $("html, body").animate({ scrollTop: 0 }, 800);
    this.getGallery();
  }

  componentWillReceiveProps(nextProps) {

  }

  getGallery(){
      axios.get(apiUrl + 'cms/gallery?app_id='+appId
                    ).then(res => {
                if (res.data.status === "ok" && Object.keys(res.data.result_set).length > 0) {

                          var rows = res.data.result_set.map((loaddata, index) => {
                               return (
                                <li>
                                    <div className="media_row">
                                        <div className="media_img">
                                            <img src={res.data.common.image_source+"/"+loaddata.gallery_image} />
                                        </div>
                                        <div className="media_info">
                                            <h4>
                                                {loaddata.gallery_name}
                                            </h4>
                                        </div>
                                    </div>
                                </li>
                                );
                          });
                          this.setState({gallery_html: rows});
                }
      });
      axios.get(apiUrlV2 + 'mediapartners/mediapartners?app_id='+appId
                    ).then(res => {
                if (res.data.status === "ok" && Object.keys(res.data.result_set).length > 0) {

                          var rows = res.data.result_set.reverse().map((loaddata, index) => {
                               return (
                                <tr>
                                    <td data-label="Media Outlet">
                                        {loaddata.mp_title}
                                    </td>
                                    <td data-label="Date">
                                        {loaddata.mp_date}
                                    </td>
                                    <td data-label="Article Title">
                                        <a className="tbl-bg-none" target="_blank" href={loaddata.mp_url}>{loaddata.mp_name}</a>
                                    </td>
                                </tr>
                                );
                          });
                          this.setState({mediapartners_html: rows});
                }
      });
  }


  render() {
    return (
     <div className="outletList-main-div">
    {/* Header start */}
    <Header />
    {/* Header End */}
    <div className="common-inner-blckdiv">
        <div className="common-inner-banner">
            <img src={banner} />
            <p>
                Media
            </p>
        </div>
    </div>
    <div className="media-page media-partner">
        <div className="container-one cms-content">
            <div className="container">
                <div className="media-section">
                    <h2>
                        Our Media Partners
                    </h2>
                    <ul className="mediaul">
                        {this.state.gallery_html}                        
                    </ul>
                </div>
                <div className="accordion_sec">
                    <div className="container">
                        <div id="accordion" className="panel-group">
                            <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a href="#panelBodyOne" className="accordion-toggle" data-toggle="collapse" data-parent="#accordion">D'Best Recreation MEDIA COVERAGE</a>
                                    </h4>
                                </div>
                                <div id="panelBodyOne" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        <table className="table dbest-tabel">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        Media Outlet
                                                    </th>
                                                    <th scope="col">
                                                        Date
                                                    </th>
                                                    <th scope="col">
                                                        Article Title
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.mediapartners_html}                        
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    <div className="hide_out">
    </div>
    <div id="dvLoading1234">
    </div>
</div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    outlets: state.alloutlets,
    globalsettings: state.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};
Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
