/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setMinutes, setHours, getDay, format } from "date-fns";

import { appId, deliveryId, pickupId, apiUrlV2, apiUrl  } from "../Helpers/Config";
import { cnvrtStr,  showLoader,
  hideLoader, } from "../Helpers/SettingHelper";

var dateFormat = require("dateformat");
var Parser = require("html-react-parser");

class ActivitiesAdvancedDatetimeSlot extends Component {
  constructor(props) {
    super(props);

    var minDateTxt = new Date();
    var startDate = new Date();
    var startTime = setHours(setMinutes(new Date(), 0), 17);
  
  var curntDateObj = new Date();
  var curntHoursTxt = curntDateObj.getHours();
  var curnt12HrsFrm = (curntHoursTxt >= 12) ? 'PM' : 'AM';

    this.state = {
      showDatePk: "no",
      showTimePk: "no",
      timeSlotType: "2",
      bookingSlotType: "2",
      dateTimeErroTxt: "",
      timeErroTxt: "",
      timeSlotHtmlDrwn: "",
      minitsHtmlDrwn: "",
      slctTimeValue: "",
      slctMinitsValue: "",
      slctTimeFrmt: "AM",
      startDate: startDate,
      startTime: startTime,
      minDate: minDateTxt,
      maxDate: minDateTxt,
      holidays: [],
      weekdays: [],
      order_tatTm: 0,
      min_date_int: 0,
      max_date_int: 0,
      interval_time: 5,
      cut_off_time: 0,
      total_slots: 0,
      payment_basis: '',
      bps_one_hour_cost: '',
      bps_two_hours_cost: '',
      bps_three_hours_cost: '',
      price: 0,
      new_price: 0,
      per_count: 0,
      proqty_input: 1,
      hourqty_input: 0,
      resultSetdata: [],
      includeTimesList: [],
      timeSlotListArr: [],
      naxtdayslotdata: [],
      bookinghourscatchcostdata: [],
      hourbasiscostdata: [],
      hourbasiscostlist: '',
      hourcost_label: '',
      holiday_type_temp: '',
      curnt12HrsFrm: curnt12HrsFrm,
      selected12HrsFrm: curnt12HrsFrm,
    amhours_data: [],
      pmhours_data: [],
      amsecds_data: [],
      pmsecds_data: [],
      ordersSecnddata: [],
    };

    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
    this.isWeekday = this.isWeekday.bind(this);
  }

  componentWillReceiveProps(propsDtTm) {
    if (Object.keys(propsDtTm).length > 0) {
      var getDateTimeFlg = propsDtTm.hdrState.getDateTimeFlg;
      var sldAvilablityId = propsDtTm.hdrState.seletedAvilablityId;
      var sldOutletId = propsDtTm.hdrState.seletedOutletId;
      var orderTatTm = propsDtTm.hdrState.order_tat_time;
      this.setState({activities_category_id:propsDtTm.hdrState.activities_category_id})
      if (getDateTimeFlg === "yes") {
        this.setState(
          { dateTimeErroTxt: "" },
          function () {
            this.getAvailableDates();
          }.bind(this)
        );
        propsDtTm.setdateTimeFlg("tmflg", "ok");
      }
    }
  }

  getAvailableDates() {
    var dateTimeErroTxt = "";
    if (this.state.activities_category_id !== "") {
      showLoader("timeslot_info_div", "class");
        this.setState({proqty_input:1,hourqty_input:0})
        cookie.save("total_booking_price", 0, { path: "/" });
        cookie.save("total_catch_count", 1, { path: "/" });
        cookie.save("additional_hours", 0, { path: "/" });
        axios.get(apiUrl + 'bookings/getCategoryData?app_id='+appId+'&category_id='+this.state.activities_category_id).then(res => {
          var minDateInt = 0;
          var maxDateInt = 0;
          var cutOffTime = 0;
          var intervalTime = 0;
          var resultSetArr = [];
          var weekdays = [];
          var holidaysArr = [];
          var holidaysDates = [];
          var timeSlotListArr = [];
          var naxtdayslotdata = [];
          var bookinghourscatchcostdata = [];
          var hourbasiscostArr = [];
          var timeSlotType = "2";
          var bookingSlotType = "2";
          var payment_basis = '';
          var hourbasiscostlist = '';
          var hourcost_label = '';
          var bps_one_hour_cost = 0;
          var bps_two_hours_cost = 0;
          var bps_three_hours_cost = 0;
      var amhoursData = [];
      var pmhoursData = [];
      var amsecdsData = [];
      var pmsecdsData = [];
      var ordersSecnddata = [];

          /* Success response */
          if (res.data.status === "ok") {
            resultSetArr = res.data.result_set;
      weekdays = Array();
            minDateInt = 0;
            maxDateInt = 30;
      if (resultSetArr != null && typeof resultSetArr != 'undefined' && Object.keys(resultSetArr).length > 0) {
        bookingSlotType = resultSetArr.booking_slottype;
        naxtdayslotdata = (Object.keys(resultSetArr.naxtdayslot_data).length > 0) ? resultSetArr.naxtdayslot_data : [];
        amhoursData = (Object.keys(resultSetArr.amhours_data).length > 0) ? resultSetArr.amhours_data : [];
        pmhoursData = (Object.keys(resultSetArr.pmhours_data).length > 0) ? resultSetArr.pmhours_data : [];
        amsecdsData = (Object.keys(resultSetArr.amsecds_data).length > 0) ? resultSetArr.amsecds_data : [];
        pmsecdsData = (Object.keys(resultSetArr.pmsecds_data).length > 0) ? resultSetArr.pmsecds_data : [];
        bookinghourscatchcostdata = (Object.keys(resultSetArr.bookinghourscatchcost_data).length > 0) ? resultSetArr.bookinghourscatchcost_data : [];
        minDateInt = resultSetArr.minimum_date !== "" ? resultSetArr.minimum_date : 0;
        maxDateInt = resultSetArr.maximum_date !== "" ? resultSetArr.maximum_date : 30;
      }
            var timeslotData = res.data.timeslot_data;
            var holidaysListArr = Array();
            if (timeslotData != null && typeof timeslotData != 'undefined' && Object.keys(timeslotData).length > 0) {
              holidaysListArr = Array();
              var dateRstData = timeslotData.result_set;
              payment_basis = timeslotData.result_set.bps_payment_basis;
              if(payment_basis == 'hour_basis'){
              bps_one_hour_cost = timeslotData.result_set.bps_one_hour_cost;
              bps_two_hours_cost = timeslotData.result_set.bps_two_hours_cost;
              bps_three_hours_cost = timeslotData.result_set.bps_three_hours_cost;
              }
              if (Object.keys(dateRstData).length > 0) {
                timeSlotType = 2;
                timeSlotListArr = dateRstData.slot !== "" && dateRstData.slot !== null && dateRstData.slot !== "null" ? dateRstData.slot : [];
                intervalTime = 5;
                cutOffTime = 0;
              }
            }

            if (Object.keys(holidaysListArr).length > 0) {
              holidaysListArr.map(function (holiday, i) {
                holidaysArr.push(new Date(holiday));
                holidaysDates.push(holiday);
              });
            }
          } else {
            hideLoader("timeslot_info_div", "class");
            dateTimeErroTxt =
              "Sorry!. Time slot not available for selected outlet.<span>Please select any other outlet.</span>";
          }

          var ordDateTime = new Date();
    
          cookie.save("orderDateTime", ordDateTime, { path: "/" });

      var curntHoursTxt = ordDateTime.getHours();
        var curnt12HrsFrm = (curntHoursTxt >= 12) ? 'PM' : 'AM';

          this.setState(
            {
              resultSetdata: resultSetArr,
              bookingSlotType: bookingSlotType,
              timeSlotListArr: timeSlotListArr,
              naxtdayslotdata: naxtdayslotdata,
              amhours_data: amhoursData,
        pmhours_data: pmhoursData,
        amsecds_data: amsecdsData,
        pmsecds_data: pmsecdsData,
        ordersSecnddata: ordersSecnddata,
        curnt12HrsFrm: curnt12HrsFrm,
        selected12HrsFrm: curnt12HrsFrm,
              bookinghourscatchcostdata: bookinghourscatchcostdata,
              payment_basis: payment_basis,
              bps_one_hour_cost: bps_one_hour_cost,
              bps_two_hours_cost: bps_two_hours_cost,
              bps_three_hours_cost: bps_three_hours_cost,
              order_tatTm: 0,
              min_date_int: minDateInt,
              max_date_int: maxDateInt,
              interval_time: intervalTime,
              cut_off_time: cutOffTime,
              weekdays: weekdays,
              holidaysDates: holidaysDates,
              holidays: holidaysArr,
              timeSlotType: timeSlotType,
              dateTimeErroTxt: dateTimeErroTxt,
        hourbasiscostdata: hourbasiscostArr,
        hourbasiscostlist: hourbasiscostlist,
        hourcost_label: hourcost_label,
            },
            function () {
              this.setOrderDateFun();
            }.bind(this)
          );

          if(this.state.activities_category_id == 4){
            $(document).find('.additional_hours_text').html('<p>Please note the following are the 3 sessions for Main Pond:<br> a)7:00 am - 2:00 pm,  b) 2:30 pm - 8:30 pm ,  c) 9:00 pm - 6:00 am</p>'); 
            $(document).find('.additional_hours').hide();
          }else{
            $(document).find('.additional_hours_text').html('To extend hours, please add qty'); 
            $(document).find('.additional_hours').show();
          }

          if (dateTimeErroTxt !== "") {
            this.props.setdateTimeFlg("triggerErrorPopup", "yes");
          }
        });
    }else{
          dateTimeErroTxt =
              "Sorry!. Time slot not available for selected pond.<span>Please select any other pond.</span>";
          this.setState(
            {
              showDatePk: "no",
              dateTimeErroTxt: dateTimeErroTxt,
            },
          );

    }
  }

  setOrderDateFun() {
    var resultSetdata = this.state.resultSetdata;
    this.props.setdateTimeFlg("ordDate", "");
    if (Object.keys(resultSetdata).length > 0) {
      var current = new Date();
      var datetime = new Date(current.getTime());
      var currentDateValue = datetime.getDate();
      var minDateInt = this.state.min_date_int;
      minDateInt = minDateInt !== "" ? parseInt(minDateInt) : 0;
      var maxDateInt = this.state.max_date_int;
      maxDateInt = maxDateInt !== "" ? parseInt(maxDateInt) : 0;
      var cutOffTime = this.state.cut_off_time;
      cutOffTime =
        cutOffTime !== "" && parseInt(cutOffTime) !== 0
          ? parseInt(cutOffTime)
          : 24;
      var incr_minimum_days = minDateInt;
      var max_days = maxDateInt;
      var itsCurtDy = "no";
      var chkng_text = "yes";

      var weekDays_data = this.state.weekdays;
      var holiDays_data = this.state.holidaysDates;

      var str_datetime = new Date(
        new Date(datetime).setDate(currentDateValue + incr_minimum_days)
      );

      var crtutc = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
      /*var sltutc = str_datetime.toJSON().slice(0,10).replace(/-/g,'/');*/
      var sltutc = format(str_datetime, "yyyy/MM/dd");

      if (crtutc === sltutc) {
        var crtdtobj = new Date();
        var currentTimeNew = crtdtobj.getHours();
        if (parseInt(currentTimeNew) < parseInt(cutOffTime)) {
          itsCurtDy = "yes";
        } else {
          incr_minimum_days = 1;
          str_datetime = new Date(
            new Date(datetime).setDate(currentDateValue + incr_minimum_days)
          );
        }
      } else if (parseInt(incr_minimum_days) > 0) {
        var currentTimeLst = dateFormat(new Date(), "H");
        if (parseInt(currentTimeLst) >= parseInt(cutOffTime)) {
          incr_minimum_days = incr_minimum_days + 1;
          str_datetime = new Date(
            new Date(datetime).setDate(currentDateValue + incr_minimum_days)
          );
        }
      }

      var min_datetime_obj = new Date(
        new Date(datetime).setDate(currentDateValue + incr_minimum_days)
      );
      var max_datetime_obj = new Date(
        new Date(datetime).setDate(
          currentDateValue + incr_minimum_days + max_days
        )
      );

      var day_intt = str_datetime.getDay();
      if (weekDays_data.indexOf(day_intt) != -1) {
        minDateInt = parseInt(minDateInt) + 1;
        chkng_text = "no";
        this.setState(
          { min_date_int: minDateInt },
          function () {
            this.setOrderDateFun();
          }.bind(this)
        );
        return false;
      }

      var datefrmt_txt = format(str_datetime, "yyyy-MM-dd");
      if (holiDays_data.indexOf(datefrmt_txt) != -1) {
        minDateInt = parseInt(minDateInt) + 1;
        chkng_text = "no";
        this.setState(
          { min_date_int: minDateInt },
          function () {
            this.setOrderDateFun();
          }.bind(this)
        );
        return false;
      }

      /*var orderDateTime = cookie.load("orderDateTime") === undefined ? "" : cookie.load("orderDateTime");*/
    var orderDateTime = '';
      var secc_text = "no";
      if (orderDateTime !== "") {
        secc_text = "yes";
        var dateTxtArr = orderDateTime.split("T");
        var odrDatefrmtTxt = dateTxtArr[0];
        odrDatefrmtTxt = odrDatefrmtTxt.replace('"', "");
        var odrDatefrmtObj = new Date(odrDatefrmtTxt);
        orderDateTime = odrDatefrmtObj;
        var crtDatefrmtTxt = format(datetime, "yyyy-MM-dd");
        var crtDatefrmtObj = new Date(crtDatefrmtTxt);
        if (odrDatefrmtObj < crtDatefrmtObj) {
          secc_text = "no";
        }

        var day_intt = odrDatefrmtObj.getDay();
        if (weekDays_data.indexOf(day_intt) != -1) {
          secc_text = "no";
        }

        var datefrmt_txt = format(odrDatefrmtObj, "yyyy-MM-dd");
        if (holiDays_data.indexOf(datefrmt_txt) != -1) {
          secc_text = "no";
        }
      }

      if (secc_text == "yes") {
        str_datetime = orderDateTime;
      }

      this.setState(
        {
          showDatePk: "yes",
          startDate: str_datetime,
          minDate: min_datetime_obj,
          maxDate: max_datetime_obj,
        },
        function () {
      if(this.state.bookingSlotType === '2') {
        this.setOrderTimeSlotFun();
      } else {
        /*this.setOrderTimeFun();*/
        this.setOrderHoursSecdFun();
      }

        }.bind(this)
      );
    }else{
      this.setState(
        {
          showDatePk: "no",
        },
      );
    }
  }

  setOrderTimeSlotFun() {
    this.setState({proqty_input:1,hourqty_input:0})
    var timeSlotListObj = this.state.timeSlotListArr;
    var selectedDate = this.state.startDate;
    this.props.setdateTimeFlg("ordSlotDate", selectedDate);
    if (Object.keys(timeSlotListObj).length > 0 && selectedDate !== "") {
      var crtutc = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
      var sltutc = format(selectedDate, "yyyy/MM/dd");
      var dytext = format(selectedDate, "EEE");
      dytext = dytext.toLowerCase();
      axios.get(apiUrl + 'bookings/checkholiday_bydate?app_id='+appId+'&category_id='+this.state.activities_category_id+'&date='+sltutc).then(res => {
        hideLoader("timeslot_info_div", "class");
        var holiday_type = '';
        if (res.data.status === "ok") {
          dytext = res.data.result_set.hs_holiday_type.toLowerCase().replace(' ','_');
          holiday_type = dytext;
        }

      var timeSlotListArray = this.objToArray(timeSlotListObj);
      var timeSlotListData =
        Object.keys(timeSlotListArray).length > 0
          ? timeSlotListArray[dytext]
          : Array();
      var orderTatTm = this.state.order_tatTm;
      var payment_basis = this.state.payment_basis;
      var bps_one_hour_cost = this.state.bps_one_hour_cost;
      var bps_two_hours_cost = this.state.bps_two_hours_cost;
      var bps_three_hours_cost = this.state.bps_three_hours_cost;
      var currentTime = new Date();
      var timeSlotHtml = "";

      var startTime = "";
      var sldordtime = "";
      var ordSlotVal = "";
      var ordSlotLbl = "";
      var ordSlotStr = "";
      var ordSlotEnd = "";
      var showTimePk = "no";
      var price = 0;
      var new_price = 0;
      var per_count = 0;
      var timeErroTxt =
        "Sorry!. Time slot not available for selected Date.<span>Please select any other date.</span>";
      var intlTimeFlg = 0;
    var hourbasiscostArr = Array();
    var hourbasiscostlist = "";
    var hourcost_label = "";

      if (
        timeSlotListData !== "" &&
        timeSlotListData !== undefined &&
        Object.keys(timeSlotListData).length > 0
      ) {
        var timeslot_count = 0;
        timeSlotListData.map(function (item,key) {
          if (Object.keys(item).length > 0) {
            var slotTime1 =
              item.slot_time1 !== "" ? item.slot_time1.split(":") : Array();
            var slotTime2 =
              item.slot_time2 !== "" ? item.slot_time2.split(":") : Array();
            if (
              Object.keys(slotTime1).length > 0 &&
              Object.keys(slotTime2).length > 0
            ) {
              var startTimeVal = parseInt(slotTime1[0]);
              var startMinitVal = parseInt(slotTime1[1]);
              var strdatevalobj = new Date();
              strdatevalobj.setHours(startTimeVal);
              strdatevalobj.setMinutes(startMinitVal);

              var endTimeVal = parseInt(slotTime2[0]);
              var endMinitVal = parseInt(slotTime2[1]);
              var enddatevalobj = new Date();
              enddatevalobj.setHours(endTimeVal);
              enddatevalobj.setMinutes(endMinitVal);

              var slctTmlable =
                format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
              var slctTmValue =
                item.slot_time1 +
                " - " +
                item.slot_time2 +
                "/" +
                slctTmlable;

              var curDyHrMt = currentTime.getHours()+'.'+currentTime.getMinutes();
              var curDyHrMt  = currentTime.getHours()+'.'+currentTime.getMinutes();

              var tatValue = orderTatTm !== "" ? parseInt(orderTatTm) : 0;
              var dtnowobj = new Date();
              dtnowobj.setMinutes(dtnowobj.getMinutes() + tatValue);
              var curDyHrMt = dtnowobj.getHours() + "." + dtnowobj.getMinutes();

              var strDyHrMt = startTimeVal + "." + startMinitVal;

              if (crtutc === sltutc) {

                if (parseFloat(curDyHrMt) < parseFloat(strDyHrMt)) {
                  if (intlTimeFlg === 0) {
                    startTime = setHours(
                      setMinutes(new Date(), startMinitVal),
                      startTimeVal
                    );
                    sldordtime =
                      cnvrtStr(startTimeVal) + ":" + cnvrtStr(startMinitVal);
                    ordSlotVal = slctTmValue;
                    ordSlotLbl = slctTmlable;
                    ordSlotStr = item.slot_time1 + "";
                    ordSlotEnd = item.slot_time2 + "";
                    intlTimeFlg = 1;
                    showTimePk = "yes";
                    timeErroTxt = "";
                  }
                  var per_count_loop = 0;
                  var price_loop = 0;
                  if(timeslot_count == 0){
                    if(payment_basis == 'hour_basis'){
                      price = item.bps_setting_time_hour_cost;
                      new_price = item.bps_setting_time_hour_cost;
                    }else{
                      per_count = item.bps_setting_time_catch_count;
                      price = item.bps_setting_time_catch_cost;
                      new_price = item.bps_setting_time_catch_cost;
                    }
                  }
                  if(payment_basis == 'hour_basis'){
                    price_loop = item.bps_setting_time_hour_cost;
                  }else{
                    per_count_loop = item.bps_setting_time_catch_count;
                    price_loop = item.bps_setting_time_catch_cost;
                  }
          
          var timehourcostlist = '0~0~0';
          var hourcost_label_temp = '';

                  timeSlotHtml +=
                    "<option payment_basis='"+payment_basis+"' bps_one_hour_cost='"+bps_one_hour_cost+"' bps_two_hours_cost='"+bps_two_hours_cost+"' bps_three_hours_cost='"+bps_three_hours_cost+"' price='"+price_loop+"' per_count='"+per_count_loop+"' timehourcostlist='"+timehourcostlist+"' hourcost_label='"+hourcost_label_temp+"' value='" +
                    slctTmValue +
                    "'  >" +
                    slctTmlable +
                    "</option>";
                  timeslot_count++;
                }

              } else {
                if (intlTimeFlg === 0) {
                  startTime = setHours(
                    setMinutes(new Date(), startMinitVal),
                    startTimeVal
                  );
                  sldordtime =
                    cnvrtStr(startTimeVal) + ":" + cnvrtStr(startMinitVal);
                  ordSlotVal = slctTmValue;
                  ordSlotLbl = slctTmlable;
                  ordSlotStr = item.slot_time1 + "";
                  ordSlotEnd = item.slot_time2 + "";
                  intlTimeFlg = 1;
                  showTimePk = "yes";
                  timeErroTxt = "";
                }
                  var per_count_loop = 0;
                  var price_loop = 0;
                  if(key == 0){
                    if(payment_basis == 'hour_basis'){
                      price = item.bps_setting_time_hour_cost;
                      new_price = item.bps_setting_time_hour_cost;
                    }else{
                      per_count = item.bps_setting_time_catch_count;
                      price = item.bps_setting_time_catch_cost;
                      new_price = item.bps_setting_time_catch_cost;
                    }
                  }
                  if(payment_basis == 'hour_basis'){
                    price_loop = item.bps_setting_time_hour_cost;
                  }else{
                    per_count_loop = item.bps_setting_time_catch_count;
                    price_loop = item.bps_setting_time_catch_cost;
                  }
          
          var timehourcostlist = '0~0~0';
          var hourcost_label_temp = '';

                  timeSlotHtml +=
                    "<option payment_basis='"+payment_basis+"' bps_one_hour_cost='"+bps_one_hour_cost+"' bps_two_hours_cost='"+bps_two_hours_cost+"' bps_three_hours_cost='"+bps_three_hours_cost+"' price='"+price_loop+"' per_count='"+per_count_loop+"' timehourcostlist='"+timehourcostlist+"' hourcost_label='"+hourcost_label_temp+"' value='" +
                    slctTmValue +
                    "'  >" +
                    slctTmlable +
                    "</option>";
                  timeslot_count++;
              }
            }
          }
        });
        this.setState({total_slots: timeslot_count});

        var orderSlotVal =
          cookie.load("orderSlotVal") === undefined
            ? ""
            : cookie.load("orderSlotVal");
        if (orderSlotVal !== "") {
          var ordstdtimevlArr = orderSlotVal.split("/");
          var sltdtimevlArr = ordstdtimevlArr[0].split(" - ");
          var strtimevlArr = sltdtimevlArr[0].split(":");
          var startTimeValNew = parseInt(strtimevlArr[0]);
          var startMinitValNew = parseInt(strtimevlArr[1]);

          var curDyHrMt =
            currentTime.getHours() + "." + currentTime.getMinutes();
          var strDyHrMt = startTimeValNew + "." + startMinitValNew;

          if (crtutc === sltutc) {
            if (parseFloat(curDyHrMt) < parseFloat(strDyHrMt)) {
              startTime = setHours(
                setMinutes(new Date(), startMinitValNew),
                startTimeValNew
              );
              sldordtime =
                cnvrtStr(startTimeValNew) + ":" + cnvrtStr(startMinitValNew);
              ordSlotVal = orderSlotVal;
              ordSlotLbl = cookie.load("orderSlotTxt");
              ordSlotStr = cookie.load("orderSlotStrTime");
              ordSlotEnd = cookie.load("orderSlotEndTime");
            }
          } else {
            startTime = setHours(
              setMinutes(new Date(), startMinitValNew),
              startTimeValNew
            );
            sldordtime =
              cnvrtStr(startTimeValNew) + ":" + cnvrtStr(startMinitValNew);
            ordSlotVal = orderSlotVal;
            ordSlotLbl = cookie.load("orderSlotTxt");
            ordSlotStr = cookie.load("orderSlotStrTime");
            ordSlotEnd = cookie.load("orderSlotEndTime");
          }
        }
      }

      var timeSlotHtmlDrwn = timeSlotHtml !== "" ? Parser(timeSlotHtml) : "";
      if(timeSlotHtml == ""){
        this.setState({total_slots: 0});
      }
      var sldorddate = format(selectedDate, "yyyy-MM-dd");

      this.setState({
        showTimePk: showTimePk,
        timeSlotHtmlDrwn: timeSlotHtmlDrwn,
    hourbasiscostdata: hourbasiscostArr,
        hourbasiscostlist: hourbasiscostlist,
        hourcost_label: hourcost_label,
        startTime: startTime,
        slctTimeValue: ordSlotVal,
        timeErroTxt: timeErroTxt,
        payment_basis: payment_basis,
        bps_one_hour_cost: bps_one_hour_cost,
        bps_two_hours_cost: bps_two_hours_cost,
        bps_three_hours_cost: bps_three_hours_cost,
        price: price,
        new_price: price,
        per_count: per_count,
        holiday_type_temp: holiday_type,
      });
      cookie.save("total_booking_price", price, { path: "/" });

      var tmSltArr = Array();
      tmSltArr["startTime"] = startTime;
      tmSltArr["sldorddate"] = sldorddate;
      tmSltArr["sldordtime"] = sldordtime;
      tmSltArr["ordSlotVal"] = ordSlotVal;
      tmSltArr["ordSlotLbl"] = ordSlotLbl;
      tmSltArr["ordSlotStr"] = ordSlotStr;
      tmSltArr["ordSlotEnd"] = ordSlotEnd;
      tmSltArr["payment_basis"] = payment_basis;
      tmSltArr["bps_one_hour_cost"] = bps_one_hour_cost;
      tmSltArr["bps_two_hours_cost"] = bps_two_hours_cost;
      tmSltArr["bps_three_hours_cost"] = bps_three_hours_cost;
      tmSltArr["price"] = price;
      tmSltArr["per_count"] = per_count;
      tmSltArr["holiday_type"] = holiday_type;
    tmSltArr["hourbasiscostlist"] = hourbasiscostlist;
      tmSltArr["hourcost_label"] = hourcost_label;
      tmSltArr["booking_slottype"] = this.state.bookingSlotType;

      this.props.setdateTimeFlg("ordSlotTime", tmSltArr);
      });
    }

  }

  
  setOrderTimeFun() {
    this.setState({proqty_input:1,hourqty_input:0})
    var timeSlotListObj = this.state.naxtdayslotdata;
    var bookinghourscatchcostObj = this.state.bookinghourscatchcostdata;
    var selectedDate = this.state.startDate;
    this.props.setdateTimeFlg("ordSlotDate", selectedDate);
    if (Object.keys(timeSlotListObj).length > 0 && selectedDate !== "") {
      var crtutc = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
      var sltutc = format(selectedDate, "yyyy/MM/dd");
      var dytext = format(selectedDate, "EEE");
      dytext = dytext.toLowerCase();
      axios.get(apiUrl + 'bookings/checkholiday_bydate?app_id='+appId+'&category_id='+this.state.activities_category_id+'&date='+sltutc).then(res => {
        hideLoader("timeslot_info_div", "class");
        var holiday_type = '';
        if (res.data.status === "ok") {
          dytext = res.data.result_set.hs_holiday_type.toLowerCase().replace(' ','_');
          holiday_type = dytext;
        }

      var timeSlotListArray = this.objToArray(timeSlotListObj);
      var bookinghourscatchcostArr = this.objToArray(bookinghourscatchcostObj);
      var timeSlotListData = Object.keys(timeSlotListArray).length > 0 ? timeSlotListArray[dytext] : Array();
      var bkhourscatchcostData = Object.keys(bookinghourscatchcostArr).length > 0 ? bookinghourscatchcostArr[dytext] : Array();
      var orderTatTm = this.state.order_tatTm;
      var payment_basis = this.state.payment_basis;
      var bps_one_hour_cost = this.state.bps_one_hour_cost;
      var bps_two_hours_cost = this.state.bps_two_hours_cost;
      var bps_three_hours_cost = this.state.bps_three_hours_cost;
      var currentTime = new Date();
      var timeSlotHtml = "";

      var startTime = "";
      var sldordtime = "";
      var ordSlotVal = "";
      var ordSlotLbl = "";
      var ordSlotStr = "";
      var ordSlotEnd = "";
      var showTimePk = "no";
      var price = 0;
      var new_price = 0;
      var per_count = 0;
    var hourbasiscostArr = Array();
    var hourbasiscostlist = "";
    var hourcost_label = "";
      var timeErroTxt =
        "Sorry!. Time slot not available for selected Date.<span>Please select any other date.</span>";
      var intlTimeFlg = 0;

      if (
        timeSlotListData !== "" &&
        timeSlotListData !== undefined &&
        Object.keys(timeSlotListData).length > 0
      ) {
        var timeslot_count = 0;
        timeSlotListData.map(function (item,key) {
          if (item !== '') {
      var currentbkhourscatchDataArr = Object.keys(bkhourscatchcostData).length > 0 ? bkhourscatchcostData[item] : Array();
            var slotTime1 = item !== "" ? item.split(":") : Array();
            
            if (Object.keys(slotTime1).length > 0) {
        
              var startTimeVal = parseInt(slotTime1[0]);
              var startMinitVal = parseInt(slotTime1[1]);
              var strdatevalobj = new Date();
              strdatevalobj.setHours(startTimeVal);
              strdatevalobj.setMinutes(startMinitVal);

              var slctTmlable = format(strdatevalobj, "p");
              var slctTmValue = item;

              var curDyHrMt = currentTime.getHours()+'.'+currentTime.getMinutes();
              var curDyHrMt  = currentTime.getHours()+'.'+currentTime.getMinutes();

              var tatValue = orderTatTm !== "" ? parseInt(orderTatTm) : 0;
              var dtnowobj = new Date();
              dtnowobj.setMinutes(dtnowobj.getMinutes() + tatValue);
              var curDyHrMt = dtnowobj.getHours() + "." + dtnowobj.getMinutes();

              var strDyHrMt = startTimeVal + "." + startMinitVal;

              if (crtutc === sltutc) {

                if (parseFloat(curDyHrMt) < parseFloat(strDyHrMt)) {
                  if (intlTimeFlg === 0) {
                    startTime = setHours(
                      setMinutes(new Date(), startMinitVal),
                      startTimeVal
                    );
                    sldordtime =
                      cnvrtStr(startTimeVal) + ":" + cnvrtStr(startMinitVal);
                    ordSlotVal = slctTmValue;
                    ordSlotLbl = slctTmlable;
                    ordSlotStr = item;
                    ordSlotEnd = item;
                    intlTimeFlg = 1;
                    showTimePk = "yes";
                    timeErroTxt = "";
                  }
          
          var per_count_loop = 0;
                  var price_loop = 0;
          var hourbasiscostTempArr = [];
          var timehourcost = '';
          var timehourcostlist = '';
          var hourcost_label_temp = '';
          if (Object.keys(currentbkhourscatchDataArr).length > 0) {
            
            timehourcostlist = currentbkhourscatchDataArr.bps_setting_time_hour_cost+'~'+currentbkhourscatchDataArr.bps_setting_time_hour2_cost+'~'+currentbkhourscatchDataArr.bps_setting_time_hour3_cost;
            
            if(payment_basis == 'hour_basis'){
              var bkhourCost = currentbkhourscatchDataArr.bps_setting_time_hour_cost; 
                bkhourCost = (bkhourCost !== '') ? parseFloat(bkhourCost) : 0;
              if(bkhourCost > 0) {
                var tempArr = Array();
                tempArr['keytext'] = 'hour1';
                tempArr['lbltext'] = '1 Hour Charge';
                tempArr['costval'] = currentbkhourscatchDataArr.bps_setting_time_hour_cost;
                hourbasiscostTempArr.push(tempArr);
                if(timehourcost === '') {
                  timehourcost = currentbkhourscatchDataArr.bps_setting_time_hour_cost;
                  hourcost_label_temp = '1 Hour';
                }
                if(parseFloat(price_loop) === 0) {
                  price_loop = currentbkhourscatchDataArr.bps_setting_time_hour_cost;
                }
              }
              var bkhour2Cost = currentbkhourscatchDataArr.bps_setting_time_hour2_cost; 
                bkhour2Cost = (bkhour2Cost !== '') ? parseFloat(bkhour2Cost) : 0;
              if(bkhour2Cost > 0) {
                var tempArr = Array();
                tempArr['keytext'] = 'hour2';
                tempArr['lbltext'] = '2 Hour Charge';
                tempArr['costval'] = currentbkhourscatchDataArr.bps_setting_time_hour2_cost;
                hourbasiscostTempArr.push(tempArr);
                if(timehourcost === '') {
                  timehourcost = currentbkhourscatchDataArr.bps_setting_time_hour2_cost;
                  hourcost_label_temp = '2 Hour';
                }
                if(parseFloat(price_loop) === 0) {
                  price_loop = currentbkhourscatchDataArr.bps_setting_time_hour2_cost;
                }
              }
              var bkhour3Cost = currentbkhourscatchDataArr.bps_setting_time_hour3_cost; 
                bkhour3Cost = (bkhour3Cost !== '') ? parseFloat(bkhour3Cost) : 0;
              if(bkhour3Cost > 0) {
                var tempArr = Array();
                tempArr['keytext'] = 'hour3';
                tempArr['lbltext'] = '3 Hour Charge';
                tempArr['costval'] = currentbkhourscatchDataArr.bps_setting_time_hour3_cost;
                hourbasiscostTempArr.push(tempArr);
                if(timehourcost === '') {
                  timehourcost = currentbkhourscatchDataArr.bps_setting_time_hour3_cost;
                  hourcost_label_temp = '3 Hour';
                }
                if(parseFloat(price_loop) === 0) {
                  price_loop = currentbkhourscatchDataArr.bps_setting_time_hour3_cost;
                }
              }
              if(key == 0){
                price = timehourcost;
                new_price = timehourcost;
                hourbasiscostArr = hourbasiscostTempArr;
                hourbasiscostlist = timehourcostlist;
                hourcost_label = hourcost_label_temp;
              }
            } else {
              per_count_loop = currentbkhourscatchDataArr.bps_setting_time_catch_count;
              price_loop = currentbkhourscatchDataArr.bps_setting_time_catch_cost;
              if(key == 0){
                per_count = currentbkhourscatchDataArr.bps_setting_time_catch_count;
                price = currentbkhourscatchDataArr.bps_setting_time_catch_cost;
                new_price = currentbkhourscatchDataArr.bps_setting_time_catch_cost;
              }
            }
            
          }
                  
                  timeSlotHtml += "<option payment_basis='"+payment_basis+"' bps_one_hour_cost='"+bps_one_hour_cost+"' bps_two_hours_cost='"+bps_two_hours_cost+"' bps_three_hours_cost='"+bps_three_hours_cost+"' price='"+price_loop+"' per_count='"+per_count_loop+"' timehourcostlist='"+timehourcostlist+"' hourcost_label='"+hourcost_label_temp+"' value='" +
                    slctTmValue +
                    "'  >" +
                    slctTmlable +
                    "</option>";
                  timeslot_count++;
                }

              } else {
                if (intlTimeFlg === 0) {
                  startTime = setHours(
                    setMinutes(new Date(), startMinitVal),
                    startTimeVal
                  );
                  sldordtime =
                    cnvrtStr(startTimeVal) + ":" + cnvrtStr(startMinitVal);
                  ordSlotVal = slctTmValue;
                  ordSlotLbl = slctTmlable;
                  ordSlotStr = item;
                  ordSlotEnd = item;
                  intlTimeFlg = 1;
                  showTimePk = "yes";
                  timeErroTxt = "";
                }
        
          var per_count_loop = 0;
          var price_loop = 0;
          var hourbasiscostTempArr = [];
          var timehourcost = '';
          var timehourcostlist = '';
          var hourcost_label_temp = '';
          if (Object.keys(currentbkhourscatchDataArr).length > 0) {
            
            timehourcostlist = currentbkhourscatchDataArr.bps_setting_time_hour_cost+'~'+currentbkhourscatchDataArr.bps_setting_time_hour2_cost+'~'+currentbkhourscatchDataArr.bps_setting_time_hour3_cost;
            
            if(payment_basis == 'hour_basis'){
              var bkhourCost = currentbkhourscatchDataArr.bps_setting_time_hour_cost; 
                bkhourCost = (bkhourCost !== '') ? parseFloat(bkhourCost) : 0;
              if(bkhourCost > 0) {
                var tempArr = Array();
                tempArr['keytext'] = 'hour1';
                tempArr['lbltext'] = '1 Hour Charge';
                tempArr['costval'] = currentbkhourscatchDataArr.bps_setting_time_hour_cost;
                hourbasiscostTempArr.push(tempArr);
                if(timehourcost === '') {
                  timehourcost = currentbkhourscatchDataArr.bps_setting_time_hour_cost;
                  hourcost_label_temp = '1 Hour';
                }
                if(parseFloat(price_loop) === 0) {
                  price_loop = currentbkhourscatchDataArr.bps_setting_time_hour_cost;
                }
              }
              var bkhour2Cost = currentbkhourscatchDataArr.bps_setting_time_hour2_cost; 
                bkhour2Cost = (bkhour2Cost !== '') ? parseFloat(bkhour2Cost) : 0;
              if(bkhour2Cost > 0) {
                var tempArr = Array();
                tempArr['keytext'] = 'hour2';
                tempArr['lbltext'] = '2 Hour Charge';
                tempArr['costval'] = currentbkhourscatchDataArr.bps_setting_time_hour2_cost;
                hourbasiscostTempArr.push(tempArr);
                if(timehourcost === '') {
                  timehourcost = currentbkhourscatchDataArr.bps_setting_time_hour2_cost;
                  hourcost_label_temp = '2 Hour';
                }
                if(parseFloat(price_loop) === 0) {
                  price_loop = currentbkhourscatchDataArr.bps_setting_time_hour2_cost;
                }
              }
              var bkhour3Cost = currentbkhourscatchDataArr.bps_setting_time_hour3_cost; 
                bkhour3Cost = (bkhour3Cost !== '') ? parseFloat(bkhour3Cost) : 0;
              if(bkhour3Cost > 0) {
                var tempArr = Array();
                tempArr['keytext'] = 'hour3';
                tempArr['lbltext'] = '3 Hour Charge';
                tempArr['costval'] = currentbkhourscatchDataArr.bps_setting_time_hour3_cost;
                hourbasiscostTempArr.push(tempArr);
                if(timehourcost === '') {
                  timehourcost = currentbkhourscatchDataArr.bps_setting_time_hour3_cost;
                  hourcost_label_temp = '3 Hour';
                }
                if(parseFloat(price_loop) === 0) {
                  price_loop = currentbkhourscatchDataArr.bps_setting_time_hour3_cost;
                }
              }
              if(key == 0){
                price = timehourcost;
                new_price = timehourcost;
                hourbasiscostArr = hourbasiscostTempArr;
                hourbasiscostlist = timehourcostlist;
                hourcost_label = hourcost_label_temp;
              }
            } else {
              per_count_loop = currentbkhourscatchDataArr.bps_setting_time_catch_count;
              price_loop = currentbkhourscatchDataArr.bps_setting_time_catch_cost;
              if(key == 0){
                per_count = currentbkhourscatchDataArr.bps_setting_time_catch_count;
                price = currentbkhourscatchDataArr.bps_setting_time_catch_cost;
                new_price = currentbkhourscatchDataArr.bps_setting_time_catch_cost;
              }
            }
            
          }
                  
                  timeSlotHtml +=
                    "<option payment_basis='"+payment_basis+"' bps_one_hour_cost='"+bps_one_hour_cost+"' bps_two_hours_cost='"+bps_two_hours_cost+"' bps_three_hours_cost='"+bps_three_hours_cost+"' price='"+price_loop+"' per_count='"+per_count_loop+"' timehourcostlist='"+timehourcostlist+"' hourcost_label='"+hourcost_label_temp+"' value='" +
                    slctTmValue +
                    "'  >" +
                    slctTmlable +
                    "</option>";
                  timeslot_count++;
              }
            }
          }
        });
        this.setState({total_slots: timeslot_count});

        var orderSlotVal =
          cookie.load("orderSlotVal") === undefined
            ? ""
            : cookie.load("orderSlotVal");
        if (orderSlotVal !== "") {
          var ordstdtimevlArr = orderSlotVal.split("/");
          var sltdtimevlArr = ordstdtimevlArr[0].split(" - ");
          var strtimevlArr = sltdtimevlArr[0].split(":");
          var startTimeValNew = parseInt(strtimevlArr[0]);
          var startMinitValNew = parseInt(strtimevlArr[1]);

          var curDyHrMt =
            currentTime.getHours() + "." + currentTime.getMinutes();
          var strDyHrMt = startTimeValNew + "." + startMinitValNew;

          if (crtutc === sltutc) {
            if (parseFloat(curDyHrMt) < parseFloat(strDyHrMt)) {
              startTime = setHours(
                setMinutes(new Date(), startMinitValNew),
                startTimeValNew
              );
              sldordtime =
                cnvrtStr(startTimeValNew) + ":" + cnvrtStr(startMinitValNew);
              ordSlotVal = orderSlotVal;
              ordSlotLbl = cookie.load("orderSlotTxt");
              ordSlotStr = cookie.load("orderSlotStrTime");
              ordSlotEnd = cookie.load("orderSlotEndTime");
            }
          } else {
            startTime = setHours(
              setMinutes(new Date(), startMinitValNew),
              startTimeValNew
            );
            sldordtime =
              cnvrtStr(startTimeValNew) + ":" + cnvrtStr(startMinitValNew);
            ordSlotVal = orderSlotVal;
            ordSlotLbl = cookie.load("orderSlotTxt");
            ordSlotStr = cookie.load("orderSlotStrTime");
            ordSlotEnd = cookie.load("orderSlotEndTime");
          }
        }
      }

      var timeSlotHtmlDrwn = timeSlotHtml !== "" ? Parser(timeSlotHtml) : "";
      if(timeSlotHtml == ""){
        this.setState({total_slots: 0});
      }
      var sldorddate = format(selectedDate, "yyyy-MM-dd");

      this.setState({
        showTimePk: showTimePk,
        timeSlotHtmlDrwn: timeSlotHtmlDrwn,
        hourbasiscostdata: hourbasiscostArr,
        hourbasiscostlist: hourbasiscostlist,
        hourcost_label: hourcost_label,
        startTime: startTime,
        slctTimeValue: ordSlotVal,
        timeErroTxt: timeErroTxt,
        payment_basis: payment_basis,
        bps_one_hour_cost: bps_one_hour_cost,
        bps_two_hours_cost: bps_two_hours_cost,
        bps_three_hours_cost: bps_three_hours_cost,
        price: price,
        new_price: price,
        per_count: per_count,
      });
      cookie.save("total_booking_price", price, { path: "/" });

      var tmSltArr = Array();
      tmSltArr["startTime"] = startTime;
      tmSltArr["sldorddate"] = sldorddate;
      tmSltArr["sldordtime"] = sldordtime;
      tmSltArr["ordSlotVal"] = ordSlotVal;
      tmSltArr["ordSlotLbl"] = ordSlotLbl;
      tmSltArr["ordSlotStr"] = ordSlotStr;
      tmSltArr["ordSlotEnd"] = ordSlotEnd;
      tmSltArr["payment_basis"] = payment_basis;
      tmSltArr["bps_one_hour_cost"] = bps_one_hour_cost;
      tmSltArr["bps_two_hours_cost"] = bps_two_hours_cost;
      tmSltArr["bps_three_hours_cost"] = bps_three_hours_cost;
      tmSltArr["price"] = price;
      tmSltArr["per_count"] = per_count;
      tmSltArr["holiday_type"] = holiday_type;
      tmSltArr["hourbasiscostlist"] = hourbasiscostlist;
      tmSltArr["hourcost_label"] = hourcost_label;
    tmSltArr["booking_slottype"] = this.state.bookingSlotType;

      this.props.setdateTimeFlg("ordSlotTime", tmSltArr);
      });
    }

  }
  
  setOrderHoursSecdFun() {
    this.setState({proqty_input:1,hourqty_input:0});
  if(this.state.selected12HrsFrm === 'AM') {
    if(Object.keys(this.state.amhours_data).length > 0) {
      var ordersHoursArr = this.state.amhours_data;
      var ordersSecndArr = this.state.amsecds_data;
    } else {
      var ordersHoursArr = this.state.pmhours_data;
      var ordersSecndArr = this.state.pmsecds_data;
    }
  } else {
    var ordersHoursArr = this.state.pmhours_data;
    var ordersSecndArr = this.state.pmsecds_data;
  }
  
    var selectedDate = this.state.startDate;
    this.props.setdateTimeFlg("ordSlotDate", selectedDate);
    if (Object.keys(ordersHoursArr).length > 0 && selectedDate !== "") {
      var crtutc = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
      var sltutc = format(selectedDate, "yyyy/MM/dd");
      var dytext = format(selectedDate, "EEE");
      dytext = dytext.toLowerCase();
    showLoader("timeslot_info_div", "class");
      axios.get(apiUrl + 'bookings/checkholiday_bydate?app_id='+appId+'&category_id='+this.state.activities_category_id+'&date='+sltutc).then(res => {
        hideLoader("timeslot_info_div", "class");
        var holiday_type = '';
        if (res.data.status === "ok") {
          dytext = res.data.result_set.hs_holiday_type.toLowerCase().replace(' ','_');
          holiday_type = dytext;
        }

      var timeSlotListArray = this.objToArray(ordersHoursArr);
      var timeSlotListData = Object.keys(timeSlotListArray).length > 0 ? timeSlotListArray[dytext] : Array();
    
      var orderTatTm = this.state.order_tatTm;
      var payment_basis = this.state.payment_basis;
      var bps_one_hour_cost = this.state.bps_one_hour_cost;
      var bps_two_hours_cost = this.state.bps_two_hours_cost;
      var bps_three_hours_cost = this.state.bps_three_hours_cost;
      var currentTime = new Date();
      var timeSlotHtml = "";

      var startTime = "";
      var sldordtime = "";
      var ordSlotVal = "";
      var ordSlotLbl = "";
      var ordSlotStr = "";
      var ordSlotEnd = "";
      var showTimePk = "no";
      var price = 0;
      var new_price = 0;
      var per_count = 0;
    var hourbasiscostArr = Array();
    var hourbasiscostlist = "";
    var hourcost_label = "";
      var timeErroTxt = "Sorry!. Time slot not available for selected Date.<span>Please select any other date.</span>";
      var intlTimeFlg = 0;

      if(timeSlotListData !== "" && timeSlotListData !== undefined && Object.keys(timeSlotListData).length > 0) {
      
        var timeslot_count = 0;
        timeSlotListData.map(function (item,key) {
          if(item !== '') {
            var slotTime1 = item !== "" ? item : '';
            if (slotTime1 !== '') {
              var startTimeVal = parseInt(slotTime1);
              var strdatevalobj = new Date();
              strdatevalobj.setHours(startTimeVal);

              var slctTmlable = format(strdatevalobj, "p");
              var slctTmValue = item;
              var showSlctTmValue = (parseFloat(item)>12) ? parseFloat(item) - 12 : parseFloat(item);
            showSlctTmValue = cnvrtStr(showSlctTmValue);

              var curDyHrMt = currentTime.getHours();

              var strDyHrMt = startTimeVal;

              if (crtutc === sltutc) {

                if (parseFloat(curDyHrMt) < parseFloat(strDyHrMt)) {
                  if (intlTimeFlg === 0) {
                    startTime = setHours(setMinutes(new Date(), 0),startTimeVal);
                    sldordtime = cnvrtStr(startTimeVal) + ":" + cnvrtStr('0');
                    ordSlotVal = slctTmValue;
                    ordSlotLbl = slctTmlable;
                    ordSlotStr = item;
                    ordSlotEnd = item;
                    intlTimeFlg = 1;
                    showTimePk = "yes";
                    timeErroTxt = "";
                  }
          
                  timeSlotHtml += "<option value='"+slctTmValue+"'>"+showSlctTmValue+"</option>";
          
                  timeslot_count++;
                }

              } else {
                if (intlTimeFlg === 0) {
                  startTime = setHours(setMinutes(new Date(), 0), startTimeVal);
                  sldordtime = cnvrtStr(startTimeVal) + ":" + cnvrtStr('0');
                  ordSlotVal = slctTmValue;
                  ordSlotLbl = slctTmlable;
                  ordSlotStr = item;
                  ordSlotEnd = item;
                  intlTimeFlg = 1;
                  showTimePk = "yes";
                  timeErroTxt = "";
                }
        
                timeSlotHtml += "<option value='" +slctTmValue + "'  >" + showSlctTmValue + "</option>";
        
                timeslot_count++;
              }
            }
          }
        });
        this.setState({total_slots: timeslot_count});

        var orderSlotVal = cookie.load("orderSlotVal") === undefined ? "" : cookie.load("orderSlotVal");
        if (orderSlotVal !== "") {
          var ordstdtimevlArr = orderSlotVal.split("/");
          var sltdtimevlArr = ordstdtimevlArr[0].split(" - ");
          var strtimevlArr = sltdtimevlArr[0].split(":");
          var startTimeValNew = parseInt(strtimevlArr[0]);
          var startMinitValNew = parseInt(strtimevlArr[1]);

          var curDyHrMt =
            currentTime.getHours() + "." + currentTime.getMinutes();
          var strDyHrMt = startTimeValNew + "." + startMinitValNew;

          if (crtutc === sltutc) {
            if (parseFloat(curDyHrMt) < parseFloat(strDyHrMt)) {
              startTime = setHours(
                setMinutes(new Date(), startMinitValNew),
                startTimeValNew
              );
              sldordtime =
                cnvrtStr(startTimeValNew) + ":" + cnvrtStr(startMinitValNew);
              ordSlotVal = orderSlotVal;
              ordSlotLbl = cookie.load("orderSlotTxt");
              ordSlotStr = cookie.load("orderSlotStrTime");
              ordSlotEnd = cookie.load("orderSlotEndTime");
            }
          } else {
            startTime = setHours(
              setMinutes(new Date(), startMinitValNew),
              startTimeValNew
            );
            sldordtime =
              cnvrtStr(startTimeValNew) + ":" + cnvrtStr(startMinitValNew);
            ordSlotVal = orderSlotVal;
            ordSlotLbl = cookie.load("orderSlotTxt");
            ordSlotStr = cookie.load("orderSlotStrTime");
            ordSlotEnd = cookie.load("orderSlotEndTime");
          }
        }
      }

      var timeSlotHtmlDrwn = timeSlotHtml !== "" ? Parser(timeSlotHtml) : "";
      if(timeSlotHtml == ""){
        this.setState({total_slots: 0});
      }
      var sldorddate = format(selectedDate, "yyyy-MM-dd");

      if(this.state.activities_category_id == 8 && (sltutc == '2022/02/10' || sltutc == '2022/02/11' ||  sltutc == '2022/02/12' ||  sltutc == '2022/02/13')){
      var timeSlotHtml = "";
      var timeSlotHtmlDrwn = '';
      var showTimePk = "no";
      var hourbasiscostArr = '';
      var hourbasiscostlist = '';
      var price = 0;
      var per_count = 0;
      this.setState({total_slots: 0});
      var timeErroTxt = "Sorry!. Time slot not available for selected Date.<span>Please select any other date.</span>";
      }

      var minitsHtmlDrwn = '';
      var slctMinitsValue = '';

      this.setState({
        showTimePk: showTimePk,
        timeSlotHtmlDrwn: timeSlotHtmlDrwn,
        minitsHtmlDrwn: minitsHtmlDrwn,
        slctMinitsValue: slctMinitsValue,
        ordersSecnddata: ordersSecndArr,
        hourbasiscostdata: hourbasiscostArr,
        hourbasiscostlist: hourbasiscostlist,
        hourcost_label: hourcost_label,
        startTime: startTime,
        slctTimeValue: ordSlotVal,
        timeErroTxt: timeErroTxt,
        payment_basis: payment_basis,
        bps_one_hour_cost: bps_one_hour_cost,
        bps_two_hours_cost: bps_two_hours_cost,
        bps_three_hours_cost: bps_three_hours_cost,
        price: price,
        new_price: price,
        per_count: per_count,
      }, function () {
      
      cookie.save("total_booking_price", price, { path: "/" });

      var tmSltArr = Array();
      tmSltArr["startTime"] = startTime;
      tmSltArr["sldorddate"] = sldorddate;
      tmSltArr["sldordtime"] = sldordtime;
      tmSltArr["ordSlotVal"] = ordSlotVal;
      tmSltArr["ordSlotLbl"] = ordSlotLbl;
      tmSltArr["ordSlotStr"] = ordSlotStr;
      tmSltArr["ordSlotEnd"] = ordSlotEnd;
      tmSltArr["payment_basis"] = payment_basis;
      tmSltArr["bps_one_hour_cost"] = bps_one_hour_cost;
      tmSltArr["bps_two_hours_cost"] = bps_two_hours_cost;
      tmSltArr["bps_three_hours_cost"] = bps_three_hours_cost;
      tmSltArr["price"] = price;
      tmSltArr["per_count"] = per_count;
      tmSltArr["holiday_type"] = holiday_type;
      tmSltArr["hourbasiscostlist"] = hourbasiscostlist;
      tmSltArr["hourcost_label"] = hourcost_label;
      tmSltArr["booking_slottype"] = this.state.bookingSlotType;

      this.props.setdateTimeFlg("ordSlotTime", tmSltArr);
      if(ordSlotVal !== '' && timeSlotHtmlDrwn !== '') {
        this.setOrderMinitsFun();
      }
    }.bind(this));
      
      });
    }

  }
  
  setOrderMinitsFun() {
    this.setState({proqty_input:1,hourqty_input:0});
  
    var bookinghourscatchcostObj = this.state.bookinghourscatchcostdata;
    var selectedDate = this.state.startDate;
  var slctTimeValue = this.state.slctTimeValue;
  var ordersSecndArr = this.state.ordersSecnddata;
    this.props.setdateTimeFlg("ordSlotDate", selectedDate);
  
    if (Object.keys(ordersSecndArr).length > 0 && selectedDate !== "" && slctTimeValue !== "") {

       if(this.state.selected12HrsFrm === 'PM' && parseInt(slctTimeValue) < 12) {
      slctTimeValue = parseInt(slctTimeValue) + 12;
      /*slctTimeValue = cnvrtStr(slctTimeValue);*/
     }     
    
      var crtutc = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
      var sltutc = format(selectedDate, "yyyy/MM/dd");
      var dytext = format(selectedDate, "EEE");
      dytext = dytext.toLowerCase();
    var holiday_type = '';  
      if(this.state.holiday_type_temp !== '') {
        dytext = this.state.holiday_type_temp;
        holiday_type = this.state.holiday_type_temp;
      }
    var defaultMinitsArr = ["00"];
    
      var timeSlotListArray = this.objToArray(ordersSecndArr);
      var timeSlotListData = Object.keys(timeSlotListArray).length > 0 ? timeSlotListArray[dytext] : Array();
      var timeMinitsListArr = this.objToArray(timeSlotListData);
    var timeMinitsListData = Object.keys(timeMinitsListArr).length > 0 ? timeMinitsListArr[slctTimeValue] : defaultMinitsArr;
    var bookinghourscatchcostArr = this.objToArray(bookinghourscatchcostObj);
      var bkhourscatchcostData = Object.keys(bookinghourscatchcostArr).length > 0 ? bookinghourscatchcostArr[dytext] : Array();
    
      var orderTatTm = this.state.order_tatTm;
      var payment_basis = this.state.payment_basis;
      var bps_one_hour_cost = this.state.bps_one_hour_cost;
      var bps_two_hours_cost = this.state.bps_two_hours_cost;
      var bps_three_hours_cost = this.state.bps_three_hours_cost;
      var currentTime = new Date();
      var timeSlotHtml = "";

      var startTime = "";
      var sldordtime = "";
      var ordSlotVal = "";
      var ordSlotLbl = "";
      var ordSlotStr = "";
      var ordSlotEnd = "";
      var showTimePk = "no";
      var price = 0;
      var new_price = 0;
      var per_count = 0;
    var hourbasiscostArr = Array();
    var hourbasiscostlist = "";
    var hourcost_label = "";
      var timeErroTxt = "Sorry!. Time slot not available for selected Date.<span>Please select any other date.</span>";
      var intlTimeFlg = 0;
      var slctMinitsValue = '';

      if(timeMinitsListData !== "" && timeMinitsListData !== undefined && Object.keys(timeMinitsListData).length > 0) {
        var timeslot_count = 0;
        timeMinitsListData.map(function (item,key) {
          if (item !== '') {
        var hoursMintsVl = slctTimeValue+':'+item;
        var currentbkhourscatchDataArr = Object.keys(bkhourscatchcostData).length > 0 ? bkhourscatchcostData[hoursMintsVl] : Array();
       
              var slotTime1 = slctTimeValue;
              var startTimeVal = parseInt(slotTime1);
              var strdatevalobj = new Date();
          strdatevalobj.setHours(startTimeVal);

              var slctTmlable = format(strdatevalobj, "p");
              var slctTmValueNew = hoursMintsVl;
              var slctTmValue = item;
              var slctMntInt = parseInt(item);

              var curDyHrMt = currentTime.getHours();

              var strDyHrMt = startTimeVal;

              if (crtutc === sltutc) {

                if (parseFloat(curDyHrMt) < parseFloat(strDyHrMt)) {
                  if (intlTimeFlg === 0) {
                    startTime = setHours(setMinutes(new Date(), slctMntInt),startTimeVal);
                    sldordtime = cnvrtStr(startTimeVal) + ":" + item;
                    ordSlotVal = slctTimeValue;
                    ordSlotLbl = slctTmlable;
                    ordSlotStr = hoursMintsVl;
                    ordSlotEnd = hoursMintsVl;
                    intlTimeFlg = 1;
                    showTimePk = "yes";
                    timeErroTxt = "";
                    slctMinitsValue = item;
                  }
          
          var per_count_loop = 0;
                  var price_loop = 0;
          var hourbasiscostTempArr = [];
          var timehourcost = '';
          var timehourcostlist = '';
          var hourcost_label_temp = '';
          if (Object.keys(currentbkhourscatchDataArr).length > 0) {
            
            timehourcostlist = currentbkhourscatchDataArr.bps_setting_time_hour_cost+'~'+currentbkhourscatchDataArr.bps_setting_time_hour2_cost+'~'+currentbkhourscatchDataArr.bps_setting_time_hour3_cost;
            
            if(payment_basis == 'hour_basis'){
              var bkhourCost = currentbkhourscatchDataArr.bps_setting_time_hour_cost; 
                bkhourCost = (bkhourCost !== '') ? parseFloat(bkhourCost) : 0;
              if(bkhourCost > 0) {
                var tempArr = Array();
                tempArr['keytext'] = 'hour1';
                tempArr['lbltext'] = '1 Hour Charge';
                tempArr['costval'] = currentbkhourscatchDataArr.bps_setting_time_hour_cost;
                hourbasiscostTempArr.push(tempArr);
                if(timehourcost === '') {
                  timehourcost = currentbkhourscatchDataArr.bps_setting_time_hour_cost;
                  hourcost_label_temp = '1 Hour';
                }
                if(parseFloat(price_loop) === 0) {
                  price_loop = currentbkhourscatchDataArr.bps_setting_time_hour_cost;
                }
              }
              var bkhour2Cost = currentbkhourscatchDataArr.bps_setting_time_hour2_cost; 
                bkhour2Cost = (bkhour2Cost !== '') ? parseFloat(bkhour2Cost) : 0;
              if(bkhour2Cost > 0) {
                var tempArr = Array();
                tempArr['keytext'] = 'hour2';
                tempArr['lbltext'] = '2 Hour Charge';
                tempArr['costval'] = currentbkhourscatchDataArr.bps_setting_time_hour2_cost;
                hourbasiscostTempArr.push(tempArr);
                if(timehourcost === '') {
                  timehourcost = currentbkhourscatchDataArr.bps_setting_time_hour2_cost;
                  hourcost_label_temp = '2 Hour';
                }
                if(parseFloat(price_loop) === 0) {
                  price_loop = currentbkhourscatchDataArr.bps_setting_time_hour2_cost;
                }
              }
              var bkhour3Cost = currentbkhourscatchDataArr.bps_setting_time_hour3_cost; 
                bkhour3Cost = (bkhour3Cost !== '') ? parseFloat(bkhour3Cost) : 0;
              if(bkhour3Cost > 0) {
                var tempArr = Array();
                tempArr['keytext'] = 'hour3';
                tempArr['lbltext'] = '3 Hour Charge';
                tempArr['costval'] = currentbkhourscatchDataArr.bps_setting_time_hour3_cost;
                hourbasiscostTempArr.push(tempArr);
                if(timehourcost === '') {
                  timehourcost = currentbkhourscatchDataArr.bps_setting_time_hour3_cost;
                  hourcost_label_temp = '3 Hour';
                }
                if(parseFloat(price_loop) === 0) {
                  price_loop = currentbkhourscatchDataArr.bps_setting_time_hour3_cost;
                }
              }
              if(key == 0){
                price = timehourcost;
                new_price = timehourcost;
                hourbasiscostArr = hourbasiscostTempArr;
                hourbasiscostlist = timehourcostlist;
                hourcost_label = hourcost_label_temp;
              }
            } else {
              per_count_loop = currentbkhourscatchDataArr.bps_setting_time_catch_count;
              price_loop = currentbkhourscatchDataArr.bps_setting_time_catch_cost;
              if(key == 0){
                per_count = currentbkhourscatchDataArr.bps_setting_time_catch_count;
                price = currentbkhourscatchDataArr.bps_setting_time_catch_cost;
                new_price = currentbkhourscatchDataArr.bps_setting_time_catch_cost;
              }
            }
            
          }
                  
                  timeSlotHtml += "<option payment_basis='"+payment_basis+"' bps_one_hour_cost='"+bps_one_hour_cost+"' bps_two_hours_cost='"+bps_two_hours_cost+"' bps_three_hours_cost='"+bps_three_hours_cost+"' price='"+price_loop+"' per_count='"+per_count_loop+"' timehourcostlist='"+timehourcostlist+"' hourcost_label='"+hourcost_label_temp+"' value='" +
                    slctTmValue +
                    "'  >" +
                    slctTmValue +
                    "</option>";
                  timeslot_count++;
                }

              } else {
                if (intlTimeFlg === 0) {
                  startTime = setHours(setMinutes(new Date(), slctMntInt), startTimeVal);
                  sldordtime = cnvrtStr(startTimeVal) + ":" + item;
                  ordSlotVal = slctTimeValue;
                  ordSlotLbl = slctTmlable;
                  ordSlotStr = hoursMintsVl;
                  ordSlotEnd = hoursMintsVl;
                  intlTimeFlg = 1;
                  showTimePk = "yes";
                  timeErroTxt = "";
          slctMinitsValue = item;
                }
        
          var per_count_loop = 0;
          var price_loop = 0;
          var hourbasiscostTempArr = [];
          var timehourcost = '';
          var timehourcostlist = '';
          var hourcost_label_temp = '';
          if (Object.keys(currentbkhourscatchDataArr).length > 0) {
            
            timehourcostlist = currentbkhourscatchDataArr.bps_setting_time_hour_cost+'~'+currentbkhourscatchDataArr.bps_setting_time_hour2_cost+'~'+currentbkhourscatchDataArr.bps_setting_time_hour3_cost;
            
            if(payment_basis == 'hour_basis'){
              var bkhourCost = currentbkhourscatchDataArr.bps_setting_time_hour_cost; 
                bkhourCost = (bkhourCost !== '') ? parseFloat(bkhourCost) : 0;
              if(bkhourCost > 0) {
                var tempArr = Array();
                tempArr['keytext'] = 'hour1';
                tempArr['lbltext'] = '1 Hour Charge';
                tempArr['costval'] = currentbkhourscatchDataArr.bps_setting_time_hour_cost;
                hourbasiscostTempArr.push(tempArr);
                if(timehourcost === '') {
                  timehourcost = currentbkhourscatchDataArr.bps_setting_time_hour_cost;
                  hourcost_label_temp = '1 Hour';
                }
                if(parseFloat(price_loop) === 0) {
                  price_loop = currentbkhourscatchDataArr.bps_setting_time_hour_cost;
                }
              }
              var bkhour2Cost = currentbkhourscatchDataArr.bps_setting_time_hour2_cost; 
                bkhour2Cost = (bkhour2Cost !== '') ? parseFloat(bkhour2Cost) : 0;
              if(bkhour2Cost > 0) {
                var tempArr = Array();
                tempArr['keytext'] = 'hour2';
                tempArr['lbltext'] = '2 Hour Charge';
                tempArr['costval'] = currentbkhourscatchDataArr.bps_setting_time_hour2_cost;
                hourbasiscostTempArr.push(tempArr);
                if(timehourcost === '') {
                  timehourcost = currentbkhourscatchDataArr.bps_setting_time_hour2_cost;
                  hourcost_label_temp = '2 Hour';
                }
                if(parseFloat(price_loop) === 0) {
                  price_loop = currentbkhourscatchDataArr.bps_setting_time_hour2_cost;
                }
              }
              var bkhour3Cost = currentbkhourscatchDataArr.bps_setting_time_hour3_cost; 
                bkhour3Cost = (bkhour3Cost !== '') ? parseFloat(bkhour3Cost) : 0;
              if(bkhour3Cost > 0) {
                var tempArr = Array();
                tempArr['keytext'] = 'hour3';
                tempArr['lbltext'] = '3 Hour Charge';
                tempArr['costval'] = currentbkhourscatchDataArr.bps_setting_time_hour3_cost;
                hourbasiscostTempArr.push(tempArr);
                if(timehourcost === '') {
                  timehourcost = currentbkhourscatchDataArr.bps_setting_time_hour3_cost;
                  hourcost_label_temp = '3 Hour';
                }
                if(parseFloat(price_loop) === 0) {
                  price_loop = currentbkhourscatchDataArr.bps_setting_time_hour3_cost;
                }
              }
              if(key == 0){
                price = timehourcost;
                new_price = timehourcost;
                hourbasiscostArr = hourbasiscostTempArr;
                hourbasiscostlist = timehourcostlist;
                hourcost_label = hourcost_label_temp;
              }
            } else {
              per_count_loop = currentbkhourscatchDataArr.bps_setting_time_catch_count;
              price_loop = currentbkhourscatchDataArr.bps_setting_time_catch_cost;
              if(key == 0){
                per_count = currentbkhourscatchDataArr.bps_setting_time_catch_count;
                price = currentbkhourscatchDataArr.bps_setting_time_catch_cost;
                new_price = currentbkhourscatchDataArr.bps_setting_time_catch_cost;
              }
            }
            
          }

                  timeSlotHtml +=
                    "<option payment_basis='"+payment_basis+"' bps_one_hour_cost='"+bps_one_hour_cost+"' bps_two_hours_cost='"+bps_two_hours_cost+"' bps_three_hours_cost='"+bps_three_hours_cost+"' price='"+price_loop+"' per_count='"+per_count_loop+"' timehourcostlist='"+timehourcostlist+"' hourcost_label='"+hourcost_label_temp+"' value='" +
                    slctTmValue +
                    "'  >" +
                    slctTmValue +
                    "</option>";
                  timeslot_count++;
              }
            
          }
        });
        /*this.setState({total_slots: timeslot_count});*/

        var orderSlotVal = cookie.load("orderSlotVal") === undefined ? "" : cookie.load("orderSlotVal");
        if (orderSlotVal !== "") {
          var ordstdtimevlArr = orderSlotVal.split("/");
          var sltdtimevlArr = ordstdtimevlArr[0].split(" - ");
          var strtimevlArr = sltdtimevlArr[0].split(":");
          var startTimeValNew = parseInt(strtimevlArr[0]);
          var startMinitValNew = parseInt(strtimevlArr[1]);

          var curDyHrMt =
            currentTime.getHours() + "." + currentTime.getMinutes();
          var strDyHrMt = startTimeValNew + "." + startMinitValNew;

          if (crtutc === sltutc) {
            if (parseFloat(curDyHrMt) < parseFloat(strDyHrMt)) {
              startTime = setHours(
                setMinutes(new Date(), startMinitValNew),
                startTimeValNew
              );
              sldordtime =
                cnvrtStr(startTimeValNew) + ":" + cnvrtStr(startMinitValNew);
              ordSlotVal = orderSlotVal;
              ordSlotLbl = cookie.load("orderSlotTxt");
              ordSlotStr = cookie.load("orderSlotStrTime");
              ordSlotEnd = cookie.load("orderSlotEndTime");
            }
          } else {
            startTime = setHours(
              setMinutes(new Date(), startMinitValNew),
              startTimeValNew
            );
            sldordtime =
              cnvrtStr(startTimeValNew) + ":" + cnvrtStr(startMinitValNew);
            ordSlotVal = orderSlotVal;
            ordSlotLbl = cookie.load("orderSlotTxt");
            ordSlotStr = cookie.load("orderSlotStrTime");
            ordSlotEnd = cookie.load("orderSlotEndTime");
          }
        }
      }

      var minitsHtmlDrwn = timeSlotHtml !== "" ? Parser(timeSlotHtml) : "";
      var sldorddate = format(selectedDate, "yyyy-MM-dd");

      if(this.state.activities_category_id == 8 && (sltutc == '2022/02/10' || sltutc == '2022/02/11' ||  sltutc == '2022/02/12' ||  sltutc == '2022/02/13')){
      var timeSlotHtml = "";
      var minitsHtmlDrwn = '';
      var showTimePk = "no";
      var hourbasiscostArr = '';
      var hourbasiscostlist = '';
      var price = 0;
      var per_count = 0;
      var timeErroTxt = "Sorry!. Time slot not available for selected Date.<span>Please select any other date.</span>";
      }

      this.setState({
        minitsHtmlDrwn: minitsHtmlDrwn,
        slctMinitsValue: slctMinitsValue,
        hourbasiscostdata: hourbasiscostArr,
        hourbasiscostlist: hourbasiscostlist,
        hourcost_label: hourcost_label,
        startTime: startTime,
        slctTimeValue: ordSlotVal,
        timeErroTxt: timeErroTxt,
        payment_basis: payment_basis,
        bps_one_hour_cost: bps_one_hour_cost,
        bps_two_hours_cost: bps_two_hours_cost,
        bps_three_hours_cost: bps_three_hours_cost,
        price: price,
        new_price: price,
        per_count: per_count,
      }, function () {
      
      cookie.save("total_booking_price", price, { path: "/" });

      var tmSltArr = Array();
      tmSltArr["startTime"] = startTime;
      tmSltArr["sldorddate"] = sldorddate;
      tmSltArr["sldordtime"] = sldordtime;
      tmSltArr["ordSlotVal"] = ordSlotVal;
      tmSltArr["ordSlotLbl"] = ordSlotLbl;
      tmSltArr["ordSlotStr"] = ordSlotStr;
      tmSltArr["ordSlotEnd"] = ordSlotEnd;
      tmSltArr["payment_basis"] = payment_basis;
      tmSltArr["bps_one_hour_cost"] = bps_one_hour_cost;
      tmSltArr["bps_two_hours_cost"] = bps_two_hours_cost;
      tmSltArr["bps_three_hours_cost"] = bps_three_hours_cost;
      tmSltArr["price"] = price;
      tmSltArr["per_count"] = per_count;
      tmSltArr["holiday_type"] = holiday_type;
      tmSltArr["hourbasiscostlist"] = hourbasiscostlist;
      tmSltArr["hourcost_label"] = hourcost_label;
      tmSltArr["booking_slottype"] = this.state.bookingSlotType;

      this.props.setdateTimeFlg("ordSlotTime", tmSltArr);
      
    }.bind(this));
      
      
    }

  }
  
  bookQtyAction(actionFlg) {
    var proqtyInput = $(document).find(".booking_catch")
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $(document).find(".booking_catch").find(".proqty_input").val(proqtyInput);
    var totpri = parseFloat(this.state.price) + ((parseFloat(proqtyInput)- parseFloat(1))*parseFloat(this.state.per_count));
    this.setState({new_price:totpri,proqty_input:proqtyInput})
    cookie.save("total_booking_price", totpri, { path: "/" });
    cookie.save("total_catch_count", proqtyInput, { path: "/" });
  }
  hourQtyAction(actionFlg) {
    var proqtyInput = $(document).find(".booking_catch")
      .find(".hourqty_input")
      .val();
      var max_hours = 1;
      if(this.state.activities_category_id == 7){
        max_hours = 0
      }
      if(this.state.activities_category_id == 8){
        max_hours = 1
      }
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 0 ? proqtyInput - 1 : proqtyInput;
    } else if(proqtyInput <= max_hours) {
      proqtyInput = proqtyInput + 1;
    }
    proqtyInput = Math.abs(proqtyInput);

    var additional_hours_price = 0;
    if(proqtyInput == 1){
      var additional_hours_price = this.state.bps_one_hour_cost;
    }
    if(proqtyInput == 2){
      var additional_hours_price = this.state.bps_two_hours_cost;
    }
    if(proqtyInput == 3){
      var additional_hours_price = this.state.bps_three_hours_cost;
    }
    if(additional_hours_price>0 || proqtyInput == 0){
    $(document).find(".booking_catch").find(".hourqty_input").val(proqtyInput);
    var totpri = parseFloat(this.state.price) + (parseFloat(additional_hours_price));
    this.setState({new_price:totpri,hourqty_input:proqtyInput})
    cookie.save("total_booking_price", totpri, { path: "/" });
    cookie.save("additional_hours", proqtyInput, { path: "/" });
    }
  }


  filterDaysData(is_currentday, currentlot_data, dytext) {
    var orderTatTm = this.state.order_tatTm;
    var resultSetdata = this.state.resultSetdata;
    var days_arr_data = currentlot_data;
    var crtslt_str_arr = this.objToArray(resultSetdata.currentslot_str_data);
    var crtslt_end_arr = this.objToArray(resultSetdata.currentslot_end_data);
    var its_spl_dayarr = this.objToArray(resultSetdata.its_spl_data);

    var currentday_data =
      Object.keys(days_arr_data).length > 0 ? days_arr_data[dytext] : Array();
    var currentslot_strdata =
      Object.keys(crtslt_str_arr).length > 0 ? crtslt_str_arr[dytext] : Array();
    var currentslot_enddata =
      Object.keys(crtslt_end_arr).length > 0 ? crtslt_end_arr[dytext] : Array();
    var its_spl_datatxt =
      Object.keys(its_spl_dayarr).length > 0 ? its_spl_dayarr[dytext] : "";

    if (is_currentday == "yes") {
      var new_updated_arr = Array();
      var crt_d = new Date();
      var crt_hr_mt = crt_d.getTime();
      if (typeof currentday_data !== "undefined") {
        for (var i = 0; i < currentday_data.length; i++) {
          var splits_arr = currentday_data[i].split(":");
          var strTimeVal = parseInt(splits_arr[0]);
          var strMinitVal = parseInt(splits_arr[1]);
          var crntdatevalobj = new Date();
          crntdatevalobj.setHours(strTimeVal);
          crntdatevalobj.setMinutes(strMinitVal);

          var str_hr_mt = crntdatevalobj.getTime();
          var end_time_val = this.getEndtimeTxt(
            str_hr_mt,
            currentslot_strdata,
            currentslot_enddata
          );

          if (str_hr_mt > crt_hr_mt) {
            var ingrendMinits = parseInt(strMinitVal) + parseInt(orderTatTm);
            crntdatevalobj.setMinutes(ingrendMinits);
            var fnl_hr_mt = crntdatevalobj.getTime();
            if (fnl_hr_mt <= end_time_val) {
              var gt_hrtxt =
                parseInt(crntdatevalobj.getHours()) < 10
                  ? "0" + parseInt(crntdatevalobj.getHours())
                  : crntdatevalobj.getHours();
              var gt_mttxt =
                parseInt(crntdatevalobj.getMinutes()) < 10
                  ? "0" + parseInt(crntdatevalobj.getMinutes())
                  : crntdatevalobj.getMinutes();
              var timemin_txt = gt_hrtxt + ":" + gt_mttxt;
              new_updated_arr.push(timemin_txt);
            }
          }
        }
      }
      currentday_data = new_updated_arr;
    }

    if (typeof currentday_data === "undefined") {
      currentday_data = Array();
    }

    return currentday_data;
  }

  getEndtimeTxt(str_hr_mt, currentslot_strdata, currentslot_enddata) {
    var end_time_str = 0;

    for (var j = 0; j < currentslot_strdata.length; j++) {
      var splits_arr = currentslot_strdata[j].split(":");
      var strTimeVal = parseInt(splits_arr[0]);
      var strMinitVal = parseInt(splits_arr[1]);
      var strdatevalobj = new Date();
      strdatevalobj.setHours(strTimeVal);
      strdatevalobj.setMinutes(strMinitVal);
      var strhr_mt = strdatevalobj.getTime();

      var splits_arr2 = currentslot_enddata[j].split(":");
      var endTimeVal = parseInt(splits_arr2[0]);
      var endMinitVal = parseInt(splits_arr2[1]);
      var enddatevalobj = new Date();
      enddatevalobj.setHours(endTimeVal);
      enddatevalobj.setMinutes(endMinitVal);
      var endhr_mt = enddatevalobj.getTime();

      if (str_hr_mt >= strhr_mt && str_hr_mt <= endhr_mt) {
        end_time_str = endhr_mt;
      }
    }

    return end_time_str;
  }

  objToArray(objVal) {
    var arrayVal = Array();
    if (objVal !== undefined) {
      if (Object.keys(objVal).length > 0) {
        Object.keys(objVal).map(function (key) {
          arrayVal[key] = objVal[key];
        });
      }
    }
    return arrayVal;
  }

  handleChangeDate(datevalue) {
    this.setState(
      { startDate: datevalue },
      function () {
          if(this.state.bookingSlotType === '2') {
        this.setOrderTimeSlotFun();
      } else {
        /*this.setOrderTimeFun();*/
        this.setOrderHoursSecdFun();
      }

      }.bind(this)
    );
  }

  handleChangeTime(timevalue) {
    this.setState({ startTime: timevalue });
    var selectedDate = this.state.startDate;
    var tmDatatArr = Array();
    tmDatatArr["startTime"] = timevalue;
    tmDatatArr["sldorddate"] = format(selectedDate, "yyyy-MM-dd");
    tmDatatArr["sldordtime"] =
      cnvrtStr(timevalue.getHours()) + ":" + cnvrtStr(timevalue.getMinutes());

    this.props.setdateTimeFlg("ordTime", tmDatatArr);
  }

  ordTimeFrmtChange(event) {
    var ordstdtimevl = event.target.value;
    this.setState({selected12HrsFrm:ordstdtimevl, proqty_input:1, hourqty_input:0}, function () { this.setOrderHoursSecdFun(); }.bind(this));
  }
  
  ordTimeSlotChange(event) {
    var ordstdtimevl = event.target.value;
    var ordstdtimevlArr = ordstdtimevl.split("/");
    var sltdtimevlArr = ordstdtimevlArr[0].split(" - ");
    var strtimevlArr = sltdtimevlArr[0].split(":");
    var startTimeVal = parseInt(strtimevlArr[0]);
    var startMinitVal = parseInt(strtimevlArr[1]);
    var startTime = setHours(
      setMinutes(new Date(), startMinitVal),
      startTimeVal
    );
    var sldordtime = cnvrtStr(startTimeVal) + ":" + cnvrtStr(startMinitVal);

    var selectedDate = this.state.startDate;
  
  var hourbasiscostTempArr = [];
  var hourcost_label = '';
  var timehourcostlist = '';
  var payment_basis = event.target.options[event.target.selectedIndex].getAttribute('payment_basis');
  if(payment_basis == 'hour_basis'){
    hourcost_label = event.target.options[event.target.selectedIndex].getAttribute('hourcost_label');
      timehourcostlist = event.target.options[event.target.selectedIndex].getAttribute('timehourcostlist');
    var timehourcostlistArr = timehourcostlist.split("~");  
  var bkhourCost = timehourcostlistArr[0]; 
      bkhourCost = (bkhourCost !== '') ? parseFloat(bkhourCost) : 0;
    if(bkhourCost > 0) {
      var tempArr = Array();
      tempArr['keytext'] = 'hour1';
      tempArr['lbltext'] = '1 Hour Charge';
      tempArr['costval'] = timehourcostlistArr[0];
      hourbasiscostTempArr.push(tempArr);
    }
    var bkhour2Cost = timehourcostlistArr[1]; 
      bkhour2Cost = (bkhour2Cost !== '') ? parseFloat(bkhour2Cost) : 0;
    if(bkhour2Cost > 0) {
      var tempArr = Array();
      tempArr['keytext'] = 'hour2';
      tempArr['lbltext'] = '2 Hour Charge';
      tempArr['costval'] = timehourcostlistArr[1];
      hourbasiscostTempArr.push(tempArr);
    }
    var bkhour3Cost = timehourcostlistArr[2];
      bkhour3Cost = (bkhour3Cost !== '') ? parseFloat(bkhour3Cost) : 0;
    if(bkhour3Cost > 0) {
      var tempArr = Array();
      tempArr['keytext'] = 'hour3';
      tempArr['lbltext'] = '3 Hour Charge';
      tempArr['costval'] = timehourcostlistArr[2];
      hourbasiscostTempArr.push(tempArr);
    }
  }
  
    var tmSltArr = Array();
    tmSltArr["startTime"] = startTime;
    tmSltArr["sldorddate"] = format(selectedDate, "yyyy-MM-dd");
    tmSltArr["sldordtime"] = sldordtime;
    tmSltArr["ordSlotVal"] = ordstdtimevl;
    tmSltArr["ordSlotLbl"] = ordstdtimevlArr[1];
  if(this.state.bookingSlotType === '2') {
    tmSltArr["ordSlotStr"] = sltdtimevlArr[0];
    tmSltArr["ordSlotEnd"] = sltdtimevlArr[1];
  } else {
    tmSltArr["ordSlotStr"] = sltdtimevlArr[0];
    tmSltArr["ordSlotEnd"] = sltdtimevlArr[0];  
  }
    tmSltArr["timehourcostlist"] = timehourcostlist;
    tmSltArr["hourcost_label"] = hourcost_label;
    tmSltArr["payment_basis"] = event.target.options[event.target.selectedIndex].getAttribute('payment_basis');
    tmSltArr["bps_one_hour_cost"] = event.target.options[event.target.selectedIndex].getAttribute('bps_one_hour_cost');
    tmSltArr["bps_two_hours_cost"] = event.target.options[event.target.selectedIndex].getAttribute('bps_two_hours_cost');
    tmSltArr["bps_three_hours_cost"] = event.target.options[event.target.selectedIndex].getAttribute('bps_three_hours_cost');
    tmSltArr["price"] = event.target.options[event.target.selectedIndex].getAttribute('price');
    tmSltArr["per_count"] = event.target.options[event.target.selectedIndex].getAttribute('per_count');
  tmSltArr["booking_slottype"] = this.state.bookingSlotType;

    this.setState({ startTime: startTime, slctTimeValue: ordstdtimevl,payment_basis: tmSltArr["payment_basis"],bps_one_hour_cost: tmSltArr["bps_one_hour_cost"],bps_two_hours_cost: tmSltArr["bps_two_hours_cost"],bps_three_hours_cost: tmSltArr["bps_three_hours_cost"],
        new_price: tmSltArr["price"],
        price: tmSltArr["price"],
        per_count: tmSltArr["per_count"], hourbasiscostdata: hourbasiscostTempArr, hourbasiscostlist: timehourcostlist, hourcost_label: hourcost_label });
    cookie.save("total_booking_price", tmSltArr["price"], { path: "/" });
    this.setState({proqty_input:1,hourqty_input:0})
    this.props.setdateTimeFlg("ordSlotTime", tmSltArr);
  }
  
  ordHoursChange(event) {
    var ordstdtimevl = event.target.value;
    var startTimeVal = parseInt(ordstdtimevl);
    var startMinitVal = 0;
    var startTime = setHours(setMinutes(new Date(), startMinitVal),startTimeVal);
    
    this.setState({ startTime: startTime, slctTimeValue: ordstdtimevl, proqty_input:1, hourqty_input:0}, function () { this.setOrderMinitsFun(); }.bind(this));
  }
  
  ordMinitsChange(event) {
  var ordstdtimevl = event.target.value;
  var slctTimeValueSt = this.state.slctTimeValue
    var startTimeVal = parseInt(slctTimeValueSt);
    var startMinitVal = parseInt(ordstdtimevl);
    var startTime = setHours(setMinutes(new Date(), startMinitVal),startTimeVal);
    var sldordtime = cnvrtStr(startTimeVal) + ":" + cnvrtStr(startMinitVal);

    var selectedDate = this.state.startDate;
  
  var hourbasiscostTempArr = [];
  var hourcost_label = '';
  var timehourcostlist = '';
  var payment_basis = event.target.options[event.target.selectedIndex].getAttribute('payment_basis');
  if(payment_basis == 'hour_basis'){
    hourcost_label = event.target.options[event.target.selectedIndex].getAttribute('hourcost_label');
      timehourcostlist = event.target.options[event.target.selectedIndex].getAttribute('timehourcostlist');
    var timehourcostlistArr = timehourcostlist.split("~");  
  var bkhourCost = timehourcostlistArr[0]; 
      bkhourCost = (bkhourCost !== '') ? parseFloat(bkhourCost) : 0;
    if(bkhourCost > 0) {
      var tempArr = Array();
      tempArr['keytext'] = 'hour1';
      tempArr['lbltext'] = '1 Hour Charge';
      tempArr['costval'] = timehourcostlistArr[0];
      hourbasiscostTempArr.push(tempArr);
    }
    var bkhour2Cost = timehourcostlistArr[1]; 
      bkhour2Cost = (bkhour2Cost !== '') ? parseFloat(bkhour2Cost) : 0;
    if(bkhour2Cost > 0) {
      var tempArr = Array();
      tempArr['keytext'] = 'hour2';
      tempArr['lbltext'] = '2 Hour Charge';
      tempArr['costval'] = timehourcostlistArr[1];
      hourbasiscostTempArr.push(tempArr);
    }
    var bkhour3Cost = timehourcostlistArr[2];
      bkhour3Cost = (bkhour3Cost !== '') ? parseFloat(bkhour3Cost) : 0;
    if(bkhour3Cost > 0) {
      var tempArr = Array();
      tempArr['keytext'] = 'hour3';
      tempArr['lbltext'] = '3 Hour Charge';
      tempArr['costval'] = timehourcostlistArr[2];
      hourbasiscostTempArr.push(tempArr);
    }
  }
  
    var tmSltArr = Array();
    tmSltArr["startTime"] = startTime;
    tmSltArr["sldorddate"] = format(selectedDate, "yyyy-MM-dd");
    tmSltArr["sldordtime"] = sldordtime;
    tmSltArr["ordSlotVal"] = ordstdtimevl;
    tmSltArr["ordSlotLbl"] = sldordtime;
  tmSltArr["ordSlotStr"] = sldordtime;
  tmSltArr["ordSlotEnd"] = sldordtime;  
    tmSltArr["timehourcostlist"] = timehourcostlist;
    tmSltArr["hourcost_label"] = hourcost_label;
    tmSltArr["payment_basis"] = event.target.options[event.target.selectedIndex].getAttribute('payment_basis');
    tmSltArr["bps_one_hour_cost"] = event.target.options[event.target.selectedIndex].getAttribute('bps_one_hour_cost');
    tmSltArr["bps_two_hours_cost"] = event.target.options[event.target.selectedIndex].getAttribute('bps_two_hours_cost');
    tmSltArr["bps_three_hours_cost"] = event.target.options[event.target.selectedIndex].getAttribute('bps_three_hours_cost');
    tmSltArr["price"] = event.target.options[event.target.selectedIndex].getAttribute('price');
    tmSltArr["per_count"] = event.target.options[event.target.selectedIndex].getAttribute('per_count');
  tmSltArr["booking_slottype"] = this.state.bookingSlotType;

    this.setState({ startTime: startTime, slctMinitsValue: ordstdtimevl, payment_basis: tmSltArr["payment_basis"],bps_one_hour_cost: tmSltArr["bps_one_hour_cost"],bps_two_hours_cost: tmSltArr["bps_two_hours_cost"],bps_three_hours_cost: tmSltArr["bps_three_hours_cost"],
        new_price: tmSltArr["price"],
        price: tmSltArr["price"],
        per_count: tmSltArr["per_count"], hourbasiscostdata: hourbasiscostTempArr, hourbasiscostlist: timehourcostlist, hourcost_label: hourcost_label });
    cookie.save("total_booking_price", tmSltArr["price"], { path: "/" });
    this.setState({proqty_input:1,hourqty_input:0})
    this.props.setdateTimeFlg("ordSlotTime", tmSltArr);
  }

  isWeekday(date) {
    var day = getDay(date);
    var weekdaysArr = this.state.weekdays;
    return !weekdaysArr.includes(day);

    /*return day !== 0 && day !== 6;*/
  }

  updateDateTm() {
    var tstVl = cookie.load("chkOrderDateTime");
    /*var startTime = this.state.startTime;
    var orderDateTime = this.state.startDate;
      orderDateTime.setHours(startTime.getHours());
      orderDateTime.setMinutes(startTime.getMinutes());
    var deliveryDate = dateFormat(orderDateTime, "dd/mm/yyyy"); 
    var deliveryTime = dateFormat(orderDateTime, "HH:MM:ss"); 
    */
  }

  setIncludeTimes(finalTimeList) {
    var timeListArr = [];
    finalTimeList.map(function (timeList, i) {
      var timeArr = timeList.split(":");
      timeListArr.push(
        setHours(setMinutes(new Date(), timeArr[1]), timeArr[0])
      );
    });

    return timeListArr;
  }
  
  showHourBasedCharges() {
    var hourCharges = this.state.hourbasiscostdata;
    if((this.state.payment_basis == 'hour_basis') && (Object.keys(hourCharges).length > 0)) {
      const hourChargeshtml = hourCharges.map((chargesValue, index) =>
                    <div key={index} className="custom_radio">
                    <input type="radio" checked={this.CheckHourBasedCharges(chargesValue)} className="hourcharge_input_cls" name="hourcharge_input" onChange={this.changeHourBasedCharges.bind(this,chargesValue)} value={chargesValue.keytext+'~'+chargesValue.costval+'~'+chargesValue.lbltext}/>
                    <span>{chargesValue.lbltext+" ($"+chargesValue.costval+")"}</span>
                    </div>); 
      return(<div className="col-lg-12 booking_hourchanges">
          {hourChargeshtml}
            </div>);
    }
  }
  
  CheckHourBasedCharges(hourChargesVal) {
    var returnTxt = false;
    if(hourChargesVal.keytext === 'hour1' && this.state.hourcost_label === '1 Hour') {
      returnTxt = true;
    } else if(hourChargesVal.keytext === 'hour2' && this.state.hourcost_label === '2 Hour') {
      returnTxt = true;
    } else if(hourChargesVal.keytext === 'hour3' && this.state.hourcost_label === '3 Hour') {
      returnTxt = true;
    }
    return returnTxt;
  }
  
  showHourBasedCharges_old() {
    var hourCharges = this.state.hourbasiscostdata;
    if((this.state.payment_basis == 'hour_basis') && (Object.keys(hourCharges).length > 0)) {
      const hourChargeshtml = hourCharges.map((chargesValue, index) =>
                    <div key={index} className="custom_radio">
                    {(index === 0) ? <input type="radio" defaultChecked className="hourcharge_input_cls" name="hourcharge_input" onChange={this.changeHourBasedCharges.bind(this,chargesValue)} value={chargesValue.keytext+'~'+chargesValue.costval+'~'+chargesValue.lbltext}/> : <input type="radio" className="hourcharge_input_cls" name="hourcharge_input" onChange={this.changeHourBasedCharges.bind(this,chargesValue)} value={chargesValue.keytext+'~'+chargesValue.costval+'~'+chargesValue.lbltext}/>}
                    <span>{chargesValue.lbltext+" ($"+chargesValue.costval+")"}</span>
                    </div>); 
      return(<div className="col-lg-12 booking_hourchanges">
          {hourChargeshtml}
            </div>);
    }
  }
  
  changeHourBasedCharges(hourChargesVal) {
    var hourcost_label = '';
    if(hourChargesVal.keytext === 'hour1') {
      hourcost_label = '1 Hour';
    } else if(hourChargesVal.keytext === 'hour2') {
      hourcost_label = '2 Hour';
    } else if(hourChargesVal.keytext === 'hour3') {
      hourcost_label = '3 Hour';
    }
    var price = hourChargesVal.costval;
    cookie.save("total_booking_price", price, { path: "/" });
    this.setState({new_price: price, price: price, hourcost_label: hourcost_label, proqty_input:1, hourqty_input:0});
  }

  render() {
    if (this.state.showDatePk === "yes") {
      return (
        <div className="activities-advanced-timeslot-main">
        <div className="activities-advanced-timeslot-left-right">
          <div className={(this.state.bookingSlotType === '2')?"activities-advanced-timeslot-left-6":"activities-advanced-timeslot-left-5"}>
              <div className="activities-date">
                  <h3>
                      Select Your Activity Date
                  </h3>
                  <div className="resp-datepicker datepicker_main_div choose-date">
                      <div className="reg_dateList">
                          <div className="dateLsis">
                              <DatePicker
                                autocomplete="off"
                                className="form-control ordr-datetime-cls form-select"
                                selected={this.state.startDate}
                                minDate={this.state.minDate}
                                maxDate={this.state.maxDate}
                                dateFormat="dd/MM/yyyy"
                                onChange={this.handleChangeDate}
                                filterDate={this.isWeekday}
                                excludeDates={this.state.holidays}
                              />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
            <div className={(this.state.bookingSlotType === '2')?"activities-advanced-timeslot-right":"activities-advanced-timeslot-left-7 hours-minits-maindiv"}>
              <div className="activities-time">
                  <h3>
                      Select Your Activity Time
                  </h3>
                  <div className="resp-datepicker datepicker_main_div choose-date">
                      <div className="reg_dateList">
                          <div className="dateLsis">
                              {this.state.showTimePk === "yes" ? (
                              <div className={(this.state.bookingSlotType === '1')?"custom_select_12hrtimebooking":"custom_select_slotbooking"} >
                
                  <div className="form-group custom_select" id="ordstdtime_list_div">
                                <select
                                  name="ordstdtime_list_id"
                                  onChange={(this.state.bookingSlotType === '1')?this.ordHoursChange.bind(this):this.ordTimeSlotChange.bind(this)}
                                  value={this.state.slctTimeValue}
                                  className="form-control"
                                >
                                  {this.state.timeSlotHtmlDrwn}
                                </select>
                </div>
                
                {(this.state.bookingSlotType === '1') && <>
                <div className="form-group custom_select" id="ordstdtime_div">
                <select
                                  name="ordstdtime_id"
                                  onChange={this.ordMinitsChange.bind(this)}
                                  value={this.state.slctMinitsValue}
                                  className="form-control"
                                >
                                  {this.state.minitsHtmlDrwn}
                                </select>
                </div>
                <div className="form-group custom_select" id="ordstdhrstype_div">
                <select
                                  name="ordstdhrstype_id"
                                  onChange={this.ordTimeFrmtChange.bind(this)}
                                  value={this.state.selected12HrsFrm}
                                  className="form-control"
                                >
                                  <option value="AM">AM</option>
                                  <option value="PM">PM</option>
                                </select>
                </div>
                </>}
                              </div>
                            ) : (
                              <input
                                type="text"
                                readOnly
                                className="form-control"
                                placeholder={"hh:mm - hh:mm"}
                              />
                            )}
                          </div>
                          <div className="time-slots">
                              <h3>
                                  {this.state.total_slots} Slot{this.state.total_slots>1?'s are':' is'} Available
                              </h3>
                              <h2>
                                  ${parseFloat(this.state.new_price).toFixed(2)}
                              </h2>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
      </div>

            {this.state.payment_basis == 'catch_basis' && (
              <div className="col-lg-12 booking_catch">
                  <h3>
                      Catch Count
                  </h3>
                  <div className="qty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.bookQtyAction.bind(
                        this,
                        "decr"
                      )}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      className="proqty_input"
                      readOnly
                      value={this.state.proqty_input}
                    />
                    <span
                      className="qty_plus"
                      onClick={this.bookQtyAction.bind(
                        this,
                        "incr"
                      )}
                    >
                      +
                    </span>
                  </div>
            </div>
            
            )}
      
            {this.showHourBasedCharges()}
      
            {this.state.payment_basis == 'hour_basis' && (
              <div className="col-lg-12 booking_catch">
                  <h3 className="additional_hours_text">
                      Additional Hours
                  </h3>
                  <div className="qty_bx additional_hours">
                    <span
                      className="qty_minus"
                      onClick={this.hourQtyAction.bind(
                        this,
                        "decr"
                      )}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      className="hourqty_input"
                      readOnly
                      value={this.state.hourqty_input}
                    />
                    <span
                      className="qty_plus"
                      onClick={this.hourQtyAction.bind(
                        this,
                        "incr"
                      )}
                    >
                      +
                    </span>
                  </div>
            </div>
            )}


          {this.state.timeErroTxt !== "" && (
            <div className="timeslot_info">
              {Parser(this.state.timeErroTxt)}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="timeslot_info_main">
        <div className="timeslot_info_left_right">
            <div className="timeslot_info-left">
                <div className="activities-date">
                    <h3>
                        Select Your Activity Date
                    </h3>
                    <div className="resp-datepicker datepicker_main_div choose-date">
                        <div className="reg_dateList">
                            <div className="dateLsis">
                                <input
                                  type="text"
                                  autocomplete="off"
                                  readOnly
                                  className="form-control form-select"
                                  placeholder={"dd/mm/yyyy"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="timeslot_info-right">
                <div className="activities-time">
                    <h3>
                        Select Your Activity Time
                    </h3>
                    <div className="resp-datepicker datepicker_main_div choose-date">
                        <div className="reg_dateList">
                            <div className="dateLsis">
                                
                                <input
                                  type="text"
                                  autocomplete="off"
                                  readOnly
                                  className="form-control form-select"
                                  placeholder={"hh:mm"}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {this.state.dateTimeErroTxt !== "" && (
            <div className="timeslot_info">
              {Parser(this.state.dateTimeErroTxt)}
            </div>
            )}            
        </div>
      );
    }
  }
}

export default ActivitiesAdvancedDatetimeSlot;
